<template>
  <div class="donation-unit" :class="{ 'on-hold': onHold }">
    <div class="donation-unit__top_row">
      <div class="donation-unit__cell donation-unit__bulkselect">
        <b-form-checkbox
          :id="`checkbox-bulk-published-${donation.id}`"
          :checked="checked"
          :name="`checkbox-bulk-published-${donation.id}`"
          @change="onChange"
        >
        </b-form-checkbox>
      </div>

      <div class="donation-unit__cell donation-unit__id">
        {{ donation.id }}
      </div>

      <div class="donation-unit__name donation-unit__cell">
        <div class="donation-unit__label">
          ({{ $t("dashboard.donation_name") }} {{ donation.attributes.billing_name }}
          {{ donation.attributes.billing_last_name }})
        </div>
        {{ donation.attributes.billing_name }} {{ donation.attributes.billing_last_name }}
      </div>

      <div class="donation-unit__dname donation-unit__cell">
        {{ donation.attributes.display_name }}
      </div>

      <div class="donation-unit__method donation-unit__cell">
        <div class="donation-unit__label">
          {{ $t("dashboard.donation_via") }}
        </div>
        {{
          $t(
            `dashboard.donation_bank_name_${donation.attributes.bank_name}`,
            donation.attributes.bank_name
          )
        }}
      </div>

      <div class="donation-unit__charged donation-unit__cell">
        <div class="donation-unit__label">{{ $t("dashboard.donation_amount_charged") }}</div>
        {{ donation.attributes.charged_amount }}
        {{ donation.attributes.currency_code.toUpperCase() }}
        <br />
        <small
          v-if="
            donation.attributes.currency_code.toUpperCase() !==
            donation.attributes.processing_charged_currency.toUpperCase()
          "
        >
          {{ donation.attributes.processing_charged_amount }}
          {{ donation.attributes.processing_charged_currency.toUpperCase() }}
        </small>
        <small v-if="donation.attributes.fee_cover_amount > 0">
          {{ donation.attributes.fee_cover_amount }}
          {{ donation.attributes.currency_code.toUpperCase() }}
          {{ $t("dashboard.donation_fee_cover_amount", "cc fee cover") }}
        </small>
      </div>

      <div class="donation-unit__effective donation-unit__cell">
        <div class="donation-unit__label">
          {{ $t("dashboard.donation_amount_effective") }}
        </div>
        <span class="donation-unit__effective-amount">
          {{ donation.attributes.effective_amount }}
          {{ donation.attributes.currency_code.toUpperCase() }}
        </span>

        <recuring-icon
          v-if="
            donation.attributes.recurring_period !== 0 || donation.attributes.installments_n !== 0
          "
          v-b-tooltip.hover
          :title="isRecurring ? recurringMessage : installmentMessage"
          :isRecurring="isRecurring"
          :period="
            isRecurring ? donation.attributes.recurring_period : donation.attributes.installments_n
          "
        />
      </div>

      <div class="donation-unit__contact donation-unit__cell">
        <div class="donation-unit__label">{{ $t("dashboard.donation_email") }}</div>
        <span>{{ $_emailGettersMixin_getStaticEmail(donation)[0] }}</span>
        <span>/ {{ donation.attributes.phone }}</span>
      </div>

      <div class="donation-unit__cell donation-unit__date">
        {{ getDateFormat(donation.attributes.date) }}
      </div>

      <div class="donation-unit__status donation-unit__cell">
        <div class="donation-unit__label">{{ $t("dashboard.donation_status") }}</div>
        {{ donationStatus }}
      </div>
      <div class="donation-unit__phone donation-unit__cell">
        <div class="donation-unit__label">{{ $t("dashboard.danation_phone") }}</div>
        {{ donation.attributes.phone }}
      </div>

      <div class="donation-unit__action">
        <div class="donation-unit__action-inner">
          <div class="tooltip-box" v-if="donation.attributes.moderation_status !== 1">
            <tooltip
              icon="warn"
              v-if="premoderation && donation.attributes.moderation_status === 0"
            >
              <div class="help-tooltip__message">
                {{ $t("dashboard.donation_donation_not_appearing") }}
              </div>
            </tooltip>
            <tooltip
              icon="warn"
              v-if="premoderation && donation.attributes.moderation_status === 1"
            >
              <div class="help-tooltip__message">{{ $t("dashboard.donation_was_reviewed") }}</div>
            </tooltip>
            <tooltip
              icon="warn"
              v-if="premoderation && donation.attributes.moderation_status === -1"
            >
              <div class="help-tooltip__message">{{ $t("dashboard.donation_was_declined") }}</div>
            </tooltip>
            <tooltip
              icon="warn"
              v-if="donation.attributes.donation_type === 'F' && donation.attributes.failure_reason"
            >
              <div class="help-tooltip__message">{{ donation.attributes.failure_reason }}</div>
            </tooltip>
          </div>

          <button
            class="bttn-round bttn-round--gray"
            @click="requestReceipt"
            v-if="donation.attributes.donation_receipt_id === 0 && isCheck && receiptAllowed"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </button>

          <ViewByOrgSetting
            org-setting="receiptEmailGatewayList"
            v-slot="{ meta: receiptEmailGatewayList }"
          >
            <button
              class="bttn-round"
              v-if="
                donation.attributes.donation_receipt_id > 0 &&
                receiptEmailGatewayList.meta.includes(donation.attributes.bank_name)
              "
              @click="editReceipt"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"
                />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </button>
          </ViewByOrgSetting>

          <button class="bttn-round" @click="editDonate">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </button>
        </div>
        <MatchConditions :donation="donation">
          <template #match-donation>
            <button class="bttn-round w-100 p-2" @click="matchDonation">
              <span>{{ $t("dashboard.edit_donate_peer_match", "Match") }}</span>
            </button>
          </template>
        </MatchConditions>
      </div>
    </div>
    <div class="donation-comment" v-if="donation.attributes.created_by">
      <span>{{ $t("dashboard.offline_donation_added_by", "offline donation added by") }}</span>
      {{ donation.attributes.created_by }}
    </div>
    <div
      class="donation-comment"
      :id="`donated-to-teams-${donation.id}`"
      v-if="campaignTeamDataNames"
    >
      <span>{{ $t("dashboard.campaign_team_donation_names", "Donated to team:") }}</span>
      {{ campaignTeamDataNames.short }}
    </div>
    <b-tooltip
      v-if="campaignTeamDataNames && campaignTeamDataNames.long"
      :target="`donated-to-teams-${donation.id}`"
      triggers="hover"
    >
      {{ campaignTeamDataNames.long }}
    </b-tooltip>
    <div class="donation-comment" v-if="donation.attributes.dedication">
      <span>{{ $t("dashboard.comment") }}</span> {{ donation.attributes.dedication }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import tooltip from "@/components/tooltip.vue";
import { emailGettersMixin } from "@/mixins";
import RecuringIcon from "./donation/recuring-icon.vue";
import ViewByOrgSetting from "@/views/campaigns/campaign-wizards/viewByOrgSetting.vue";
import MatchConditions from "@/components/conditional-donations/MatchConditions.vue";

export default {
  props: ["donation", "premoderation"],
  mixins: [emailGettersMixin],
  components: {
    tooltip: tooltip,
    RecuringIcon,
    ViewByOrgSetting,
    MatchConditions,
  },
  computed: {
    ...mapState({
      selectedCampaignId: (state) => state.dashboard.selectedCampaign,

      unpublishedActivated: (state) => state.donation.unpublishedActivated,

      receiptAllowed(state) {
        const { campaigns, includedMedia } = state.campaigns;
        const campaign = campaigns.filter((el) => el.id === this.selectedCampaignId)[0];
        const { data } = campaign?.relationships?.campaign_meta || { data: [] };
        const metas = {};

        data.forEach((el) => {
          const meta = includedMedia.find(
            (metaEl) => el.id === metaEl.id && el.type === metaEl.type
          );

          if (meta) {
            try {
              metas[meta.attributes.name] = JSON.parse(meta.attributes.data);
            } catch {
              metas[meta.attributes.name] = { value: false };
            }
          }
        });

        if (metas.send_receipt_to_check_donation) return metas.send_receipt_to_check_donation.value;

        return false;
      },

      bulkPublishedDonations: (state) => state.donation.bulkPublishedDonations,
    }),

    onHold() {
      const { status } = this.donation.attributes;
      return this.unpublishedActivated && status === "OnHold";
    },

    donationStatus() {
      const { status, donation_type, captured } = this.donation.attributes;

      if (donation_type === "F") {
        return this.$t("dashboard.donation_status_f", "Failed");
      }

      const statusLC = status.toLowerCase();

      switch (statusLC) {
        case "onhold":
          if (captured) {
            return this.$t(
              `dashboard.donation_status_${statusLC}_captured`,
              status + " - Processed"
            );
          }

          return this.$t(`dashboard.donation_status_${statusLC}`, status + " - Authorized");
      }

      return this.$t(`dashboard.donation_status_${statusLC}`, status);
    },

    isCheck() {
      const bankName = this.donation.attributes.bank_name;
      return bankName === "check";
    },

    isRecurring() {
      return this.donation.attributes.recurring_period > 0;
    },

    installmentMessage() {
      if (this.donation.attributes.installments_n !== 0) {
        let currency = this.donation.attributes.currency_sign;
        let monthlyPayment =
          Math.round(
            (this.donation.attributes.charged_amount / this.donation.attributes.installments_n) * 10
          ) / 10;

        return `${currency}${monthlyPayment}/month`;
      }
      return "";
    },

    recurringMessage() {
      if (this.donation.attributes.recurring_period === 0) {
        return "";
      }

      const recurringCharges = this.donation.relationships?.recurring_charges?.data || [];
      if (recurringCharges.length > 1) {
        return this.$t(
          "dashboard.donation_recurring_period_with_recurring_charges",
          "{charges}/{period}",
          {
            period: this.donation.attributes.recurring_period,
            charges: recurringCharges.length,
          },
          recurringCharges.length
        );
      }

      return this.$t("dashboard.donation_recurring_period");
    },

    checked() {
      return Boolean(this.bulkPublishedDonations.find((v) => v === this.donation.id));
    },

    campaignTeamDataNames() {
      const ctd = this.donation.relationships?.campaign_team_data?.data;

      if (!ctd && !Array.isArray(ctd)) {
        return null;
      }

      if (ctd.length === 0) {
        return null;
      }

      const shortTeamLength = 3;
      const formatter = new Intl.ListFormat("en", { style: "long", type: "conjunction" });
      const names = ctd.map((v) => v.attributes.name);
      const parts = formatter.formatToParts(names);

      if (parts.filter((v) => v.type === "element").length > shortTeamLength) {
        const short = parts
          .slice(0, shortTeamLength + shortTeamLength - 1)
          .map((v) => v.value)
          .join("");
        const shortDots = `${short}...`;
        const long = parts.map((v) => v.value).join("");

        return { short: shortDots, long };
      }

      return { short: formatter.format(names) };
    },
  },

  methods: {
    ...mapMutations({
      checkBulkPublishedDonations: "check_bulkPublishedDonations",
      uncheckBulkPublishedDonations: "uncheck_bulkPublishedDonations",
    }),

    getDateFormat: function (date) {
      let donateDate = moment.unix(date).format("MMMM D, YYYY");

      let thisDate = moment(new Date()).format("MMMM D, YYYY");

      if (donateDate == thisDate) {
        return moment.unix(date).format("h:mm a");
      } else {
        return moment.unix(date).format("MMMM D, YYYY h:mm a");
      }
    },

    editDonate: function () {
      this.$emit("edit-donate", this.donation, this.premoderation);
      this.$store.dispatch("getTeamsByCampaignId", [this.selectedCampaignId]);
    },

    matchDonation() {
      this.$emit("match-donation", {
        id: Number(this.donation.id),
        amount: this.donation.attributes.charged_amount,
        currency: this.donation.attributes.currency_code,
      });
    },

    requestReceipt() {
      this.$emit("request-receipt", this.donation);
    },

    editReceipt() {
      this.$emit("edit-receipt", this.donation);

      this.$store.dispatch("getTeamsByCampaignId", [this.selectedCampaignId]);
    },

    onChange(checked) {
      if (checked) {
        this.checkBulkPublishedDonations(this.donation.id);
      } else {
        this.uncheckBulkPublishedDonations(this.donation.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip-box {
  display: inherit;
  margin-right: 10px;
}

.donation-unit__bulkselect {
  width: 5%;
}

.donation-comment {
  font-weight: bold;
  color: #414359;
  line-height: 1;
  font-size: 14px;
  padding-left: calc(10% + 40px);
  span {
    color: #bbbcc4;
    @media (max-width: 768px) {
      display: block;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
    padding-top: 20px;
  }
}
.bttn-round--gray {
  &:hover {
    background-color: darken(#bbbcc4, 15%);
  }
}
.on-hold {
  background-color: rgba(255, 235, 59, 0.8);
}
</style>
