<script setup>
import IconVerifiedBadge from "@/views/donor-dashboard/sharedComponents/iconVerifiedBadge.vue";
import AuctionButton from "@/views/donor-dashboard/sharedComponents/AuctionButton.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { useProctectedListUserCampaigns } from "@/views/donor-dashboard/compositions/campaigns";
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch } from "vue";
import IconAuctionTotalRaised from "./sharedComponents/iconAuctionTotalRaised.vue";
import IconAuctionTotalPoints from "./sharedComponents/iconAuctionTotalPoints.vue";
import { usePublicTeamDonationList } from "./compositions/team-donations";
import { useProctectedMyTeamsList, usePublicMyTeamsList } from "./compositions/my-teams";
import {
  useProtectedCampaignGiftClaim,
  postProtectedValidateTeamPoints,
} from "./compositions/campaign-gift-claim";
import { useProtectedCampaignGift } from "./compositions/campaign-gift";
import DonorAuctionCampaignsGiftClaimModal from "./donor-auction-campaigns-gift-claim-modal.vue";
import { notificationsMixin } from "@/mixins";
import {
  CharidyCampaignGiftsClaimStatusCanceled,
  CharidyCampaignGiftsClaimStatusCurrentlyShipping,
  CharidyCampaignGiftsClaimStatusDelivered,
  CharidyCampaignGiftsClaimStatusOther,
  CharidyCampaignGiftsClaimStatusProcessed,
  CharidyCampaignGiftsClaimStatusSentToCarrier,
} from "@/composables/campaign-gift-claim";
import DonorAuctionCampaignsPopover from "./donor-auction-campaigns-popover.vue";
import DonationFormIframeModal from "@/components/donation-form-iframe-modal.vue";

const vm = getCurrentInstance();
const META_NAME = "chinese_auction";

const intlCurrencyFormatter = (number, currency) =>
  currency
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
      }).format(number || 0)
    : (number || 0).toLocaleString();

const intlDecimalFormatter = (number) =>
  new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number || 0);

const intlListFormatter = (list, fallbackText) =>
  (list || []).length > 0
    ? new Intl.ListFormat("en", {
        style: "long",
        type: "conjunction",
      }).format(list)
    : fallbackText;

const notificationsMixinHandleCatch =
  notificationsMixin.methods.$_notificationsMixin_handleCatch.bind(vm.proxy);
const notificationsMixinMakeToast = notificationsMixin.methods.$_notificationsMixin_makeToast.bind(
  vm.proxy
);

const preselectedOption = ref(null);
const preselectedOptionTitle = ref(null);
const preselectedTeamOption = ref(null);
const preselectedOptionCurrency = ref(null);
const availableGiftsSwiperOptions = ref({
  slidesPerView: 4,
  spaceBetween: 37,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
const currentTab = ref("my-donors");
const { campaigns, getCampaigns } = useProctectedListUserCampaigns();
const {
  donations,
  donationsTotal,
  donationsTotals,
  loading: loadingDonations,
  fetchTeamDonations,
} = usePublicTeamDonationList();
const { teams, loading: loadingMyTeams, fetchProtectedMyTeams } = useProctectedMyTeamsList();
const {
  items: teamGiftClaims,
  fetchProtectedCampaignGiftClaimListByTeam,
  createProtectedCampaignGiftClaim,
} = useProtectedCampaignGiftClaim();
const {
  items: campaignGifts,
  teamTotal,
  subTeamsTotal,
  calculatedPointsTotal,
  fetchProtectedCampaignGiftList,
  fetchProtectedTeamUserStatList,
} = useProtectedCampaignGift();
const {
  teams: publicTeams,
  loading: loadingPublicMyTeams,
  fetchPublicMyTeams,
  total: publicTeamsTotal,
} = usePublicMyTeamsList();

const loading = ref(false);
const chineseAuctionMeta = ref(null);
const currentDonationsPage = ref(1);
const currentPublicTeamsPage = ref(1);

async function actionLoading(fn, ...args) {
  loading.value = true;

  try {
    const data = await fn(...args);

    return data;
  } finally {
    loading.value = false;
  }
}

async function onCampaignSelected(id) {
  const campaign = campaigns.value.find((el) => el.id === id);
  if (!campaign) {
    return;
  }

  preselectedOption.value = Number(campaign.id);
  preselectedOptionTitle.value = campaign.attributes.campaigntitle;
  preselectedOptionCurrency.value = campaign.attributes.currency;

  try {
    await fetchProtectedCampaignGiftList(campaign.id, {});

    const data = await fetchProtectedMyTeams({
      extend: ["stats", "campaign", "organization", "permissions"],
    });

    if (data.length > 0) {
      const teamsByCampaign = filterTeamsByCampaignId(data, preselectedOption.value);
      await onTeamSelected(teamsByCampaign[0]?.id || 0);
    }
  } catch (e) {
    notificationsMixinHandleCatch(e);
  }

  const metaData = campaign.relationships?.campaign_meta?.data?.attributes?.meta_data;
  if (metaData) {
    try {
      chineseAuctionMeta.value = JSON.parse(metaData);
    } catch (e) {
      console.error(`Error parsing meta data for campaign ${campaign.id}`);
    }
  }
}

async function onTeamSelected(id) {
  preselectedTeamOption.value = Number(id);

  try {
    if (!id) return;

    await fetchProtectedTeamUserStatList(id, {});
    await fetchProtectedCampaignGiftClaimListByTeam(preselectedOption.value, id, {});
    await fetchTeamDonations(preselectedOption.value, id, {
      sortBy: "-time",
      extend: ["team"],
      page: currentDonationsPage.value,
      limit: 10,
    });
    await fetchPublicMyTeams(preselectedOption.value, id, {
      limit: 10,
      page: currentPublicTeamsPage.value,
    });
  } catch (e) {
    notificationsMixinHandleCatch(e);
  }
}

watch(currentDonationsPage, (val) => {
  fetchTeamDonations(preselectedOption.value, preselectedTeamOption.value, {
    sortBy: "-time",
    extend: ["team"],
    page: val,
    limit: 10,
  }).catch((e) => notificationsMixinHandleCatch(e));
});

watch(currentPublicTeamsPage, (val) => {
  fetchPublicMyTeams(preselectedOption.value, preselectedTeamOption.value, {
    page: val,
    limit: 10,
  }).catch((e) => notificationsMixinHandleCatch(e));
});

const somePopoverOpen = ref(false);

onUnmounted(() => {
  vm.proxy.$root.$off("bv::popover::show");
});

onMounted(() => {
  vm.proxy.$root.$on("bv::popover::show", () => {
    somePopoverOpen.value = true;
  });

  loading.value = true;

  getCampaigns({
    meta_name: META_NAME,
    page: 0,
    per_page: 0,
  })
    .then((data) => {
      if (data.length === 0) {
        return;
      }

      return onCampaignSelected(campaigns.value[0]?.id || 0);
    })
    .then(() =>
      fetchTeamDonations(preselectedOption.value, preselectedTeamOption.value, {
        sortBy: "-time",
        extend: ["team"],
        page: 1,
        limit: 10,
      })
    )
    .catch((e) => notificationsMixinHandleCatch(e))
    .finally(() => {
      loading.value = false;
    });
});

function calculateGiftPointsForDonation(donation) {
  if (!chineseAuctionMeta.value) {
    return 0;
  }

  return (donation.attributes.total * chineseAuctionMeta.value.points_reward_percent) / 100;
}

const convertedToPoints = computed(() => {
  return chineseAuctionMeta.value?.points_reward_percent || 0;
});
const subConvertedToPoints = computed(() => {
  return chineseAuctionMeta.value?.points_reward_percent_children || 0;
});

const donationTotalsGiftPoints = computed(() => {
  let giftPoints = 0;

  for (var donation of donations.value) {
    giftPoints += calculateGiftPointsForDonation(donation);
  }

  return giftPoints;
});

const donateLink = ref("");
const claimModalRestore = ref(null);
const claimModalNextStep = ref(null);
const claimModalCheckoutSuccess = ref(false);

function onDonorAuctionCampaignsGiftClaimModalCheckout({ show, goToConfirm }, payload) {
  donateLink.value = payload;
  claimModalRestore.value = show;
  claimModalNextStep.value = goToConfirm;
}

function onDonorAuctionCampaignsGiftClaimModalHide() {
  onTeamSelected(preselectedTeamOption.value);
}

async function onGatewayResponseSuccess(data) {
  const donationId = data.donationId;
  claimModalCheckoutSuccess.value = true;

  try {
    await postProtectedValidateTeamPoints(
      preselectedOption.value,
      preselectedTeamOption.value,
      donationId
    );
    claimModalNextStep.value?.();
  } catch (e) {
    console.error(e);
    notificationsMixinHandleCatch(e);
    claimModalRestore.value?.();
  } finally {
    claimModalCheckoutSuccess.value = false;
  }
}

function onDonorAuctionCampaignsGiftClaimModalSubmit({ hide, formLoading }, payload) {
  createProtectedCampaignGiftClaim(
    preselectedOption.value,
    preselectedTeamOption.value,
    payload,
    {}
  )
    .then(() => onTeamSelected(preselectedTeamOption.value))
    .then(() => {
      notificationsMixinMakeToast(
        "Success",
        vm.proxy.$t("donor_dashboard.success_edit"),
        "success"
      );
      hide();
    })
    .catch((e) => notificationsMixinHandleCatch(e))
    .finally(() => {
      formLoading(false);
    });
}

function filterTeamsByCampaignId(teams, campaignId) {
  return teams.filter((team) => team.campaign.campaign_id === campaignId).map((team) => team.team);
}

const teamsBySelectedCampaign = computed(() => {
  return filterTeamsByCampaignId(teams.value, preselectedOption.value);
});

function hidePopover() {
  if (!somePopoverOpen.value) {
    return;
  }

  somePopoverOpen.value = false;
  vm.proxy.$root.$emit("bv::hide::popover");
}

function teamNameLink(team) {
  const urlParams = new URLSearchParams();
  urlParams.set("cid", preselectedOption.value);
  urlParams.set("tid", team.id);

  return `/dashboard?${urlParams.toString()}`;
}
</script>

<template>
  <div class="md:tw-col-span-2 lg:tw-col-span-3 tw-space-y-[37px] tw-overflow-hidden">
    <DonationFormIframeModal
      v-model="donateLink"
      @gateway-response-success="onGatewayResponseSuccess"
    />

    <div>
      <div
        class="tw-py-4 lg:tw-p-4 lg:tw-grid lg:tw-grid-cols-4 tw-block tw-w-full tw-justify-between tw-items-center"
        v-if="campaigns.length > 0"
      >
        <select
          :value="preselectedOption"
          class="tw-text-lg tw-font-lato tw-w-full lg:tw-col-span-3 tw-font-semibold tw-bg-[#EAF1FB] tw-rounded-full tw-px-[22px] tw-py-[13.93px] lg:tw-px-[45px] lg:tw-py-[19px] tw-border-none tw-focus:ring-0"
          :disabled="loading"
          @change="actionLoading(onCampaignSelected, $event.target.value)"
        >
          <option :value="null" selected disabled hidden>
            {{ $t("donor_dashboard.select_campaign", "Select campaign") }}
          </option>
          <option v-for="campaign in campaigns" :value="campaign.id" :key="campaign.id">
            {{ `(${campaign.id}) ${campaign.attributes.campaigntitle}` }}
          </option>
        </select>
        <div class="tw-hidden tw-items-center tw-justify-end lg:tw-flex tw-col-span-1">
          <span class="tw-font-lato tw-font-bold tw-text-[26px] tw-mx-1">
            {{ $t("donor_dashboard.donor_auction_campaigns_verified", "Verified") }}
          </span>
          <IconVerifiedBadge class="tw-w-[22px] tw-h-[22px]" />
        </div>
      </div>
      <div
        class="lg:tw-px-4 lg:tw-grid lg:tw-grid-cols-4 tw-block tw-w-full tw-justify-between tw-items-center"
        :disabled="loadingMyTeams"
        v-if="campaigns.length > 0 && teamsBySelectedCampaign.length > 0"
      >
        <select
          :value="preselectedTeamOption"
          class="tw-text-lg tw-font-lato tw-w-full lg:tw-col-span-3 tw-font-semibold tw-bg-[#EAF1FB] tw-rounded-full tw-px-[22px] tw-py-[13.93px] lg:tw-px-[45px] lg:tw-py-[19px] tw-border-none tw-focus:ring-0"
          :disabled="loading"
          @change="actionLoading(onTeamSelected, $event.target.value)"
        >
          <option :value="null" selected disabled hidden>
            {{ $t("donor_dashboard.select_team", "Select team") }}
          </option>
          <option v-for="team in teamsBySelectedCampaign" :value="team.id" :key="team.id">
            {{ `(${team.id}) ${team.name}` }}
          </option>
        </select>
      </div>
    </div>
    <template v-if="loading">
      <b-skeleton height="424px" />
      <b-skeleton height="689px" />
      <div class="tw-flex tw-gap-[38px]">
        <div class="tw-flex-1">
          <b-skeleton height="178px" />
        </div>
        <div class="tw-flex-1">
          <b-skeleton height="178px" />
        </div>
      </div>
      <b-skeleton height="464px" />
    </template>
    <template v-else>
      <div
        v-if="!preselectedOption"
        class="tw-px-[46px] tw-py-[34px] tw-border-2 tw-border-[#6BBBFF] tw-rounded-[10px] tw-bg-white"
      >
        <div class="tw-flex tw-justify-between tw-items-center">
          <h2 class="tw-font-black tw-font-lato tw-text-[25px] tw-text-[#0A8EFF]">
            {{
              $t(
                "donor_dashboard.donor_auction_campaigns_no_campaigns_available",
                "No Chinese auction campaign found"
              )
            }}
          </h2>
        </div>
      </div>
      <div
        v-if="preselectedOption && !preselectedTeamOption"
        class="tw-px-[46px] tw-py-[34px] tw-border-2 tw-border-[#6BBBFF] tw-rounded-[10px] tw-bg-white"
      >
        <div class="tw-flex tw-justify-between tw-items-center">
          <h2 class="tw-font-black tw-font-lato tw-text-[25px] tw-text-[#0A8EFF]">
            {{
              $t(
                "donor_dashboard.donor_auction_campaigns_no_teams_available",
                "No teams found in this campaign"
              )
            }}
          </h2>
        </div>
      </div>
      <template v-else>
        <div
          class="tw-px-[27.1px] tw-pt-[34.12px] tw-pb-[35.04px] lg:tw-px-[46px] lg:tw-pt-[28px] lg:tw-pb-[44.86px] tw-border-2 tw-border-[#6BBBFF] tw-rounded-[10px] tw-bg-white"
        >
          <div class="tw-flex tw-justify-between tw-mb-[49.8px]">
            <h2 class="tw-font-black tw-font-lato tw-text-[25px] tw-text-[#0A8EFF]">
              {{ $t("donor_dashboard.donor_auction_campaigns_available_gifts", "Available Gifts") }}
            </h2>
            <DonorAuctionCampaignsGiftClaimModal
              v-slot="{ show, loading }"
              :campaignId="preselectedOption"
              :campaignCurrency="preselectedOptionCurrency"
              :teamId="preselectedTeamOption"
              :meta="chineseAuctionMeta"
              @submit="onDonorAuctionCampaignsGiftClaimModalSubmit"
              @checkout="onDonorAuctionCampaignsGiftClaimModalCheckout"
              @hide="onDonorAuctionCampaignsGiftClaimModalHide"
              class="tw-hidden lg:tw-block"
            >
              <AuctionButton
                @click="() => show()"
                type="button"
                :disabled="loading || campaignGifts.length === 0"
              >
                <b-spinner v-if="loading" small class="tw-mr-[10px]" />
                {{
                  $t("donor_dashboard.donor_auction_campaigns_makeanewclaim", "Make A New Claim")
                }}
              </AuctionButton>
            </DonorAuctionCampaignsGiftClaimModal>
          </div>
          <template v-if="campaignGifts.length > 0">
            <swiper
              class="tw-mt-[50px] tw-hidden lg:tw-block"
              ref="availableGiftsSwiper"
              :options="availableGiftsSwiperOptions"
            >
              <swiper-slide
                v-for="campaignGift in campaignGifts"
                :key="`available-gift-${campaignGift.ID}`"
                :id="`available-gift-popover-${campaignGift.ID}`"
                class="!tw-h-auto"
              >
                <div class="tw-h-full tw-flex tw-flex-col" @click="hidePopover">
                  <img
                    v-if="campaignGift.image"
                    :src="campaignGift.image"
                    :alt="campaignGift.title"
                    width="140"
                    height="140"
                    class="tw-w-[122px] tw-h-[122px] lg:tw-w-[140px] lg:tw-h-[140px] tw-rounded-full tw-object-cover tw-mb-[22.52px] lg:tw-mb-[25.87px]"
                  />
                  <div
                    v-else
                    aria-roledescription="image placeholder"
                    class="tw-w-[122px] tw-h-[122px] lg:tw-w-[140px] lg:tw-h-[140px] tw-rounded-full tw-bg-gray-300 tw-mb-[22.52px] lg:tw-mb-[25.87px]"
                  />
                  <span
                    class="tw-w-full tw-text-[16px] tw-font-normal tw-font-lato tw-text-[#313131] tw-mb-[15.52px] lg:tw-mb-[17.83px]"
                  >
                    {{ campaignGift.title }}
                  </span>
                  <div class="tw-w-full tw-self-end tw-mt-auto">
                    <div class="tw-bg-[#B6B6B6] tw-h-[1px] tw-mb-[13.6px] lg:tw-mb-[19.17px]" />
                    <span class="tw-text-[13px] tw-font-black tw-font-lato tw-text-[#313131]">
                      {{
                        $t("donor_dashboard.donor_auction_campaigns_gift_points", "{points} Pts", {
                          points: campaignGift.points || 0,
                        })
                      }}
                    </span>
                  </div>
                </div>
                <DonorAuctionCampaignsPopover
                  :target="`available-gift-popover-${campaignGift.ID}`"
                  :campaignGift="campaignGift"
                  @hide-popover="hidePopover"
                />
              </swiper-slide>
              <template #button-next>
                <div class="swiper-button-next" />
              </template>
              <template #button-prev>
                <div class="swiper-button-prev" />
              </template>
            </swiper>
            <div
              class="tw-grid lg:tw-hidden tw-grid-cols-2 tw-gap-x-[9.26px] tw-gap-y-[41.33px] tw-mb-[42px]"
            >
              <div v-for="campaignGift in campaignGifts" :key="`available-gift-${campaignGift.ID}`">
                <div
                  class="tw-h-full tw-flex tw-flex-col"
                  :id="`available-gift-popover-mobile-${campaignGift.ID}`"
                  @click="hidePopover"
                >
                  <img
                    v-if="campaignGift.image"
                    :src="campaignGift.image"
                    :alt="campaignGift.title"
                    width="140"
                    height="140"
                    class="tw-w-[122px] tw-h-[122px] tw-rounded-full tw-object-cover tw-mb-[22.52px]"
                  />
                  <div
                    v-else
                    aria-roledescription="image placeholder"
                    class="tw-w-[122px] tw-h-[122px] tw-rounded-full tw-bg-gray-300 tw-mb-[22.52px]"
                  />
                  <span
                    class="tw-w-full tw-text-[15px] tw-font-normal tw-font-lato tw-text-[#313131] tw-mb-[15.52px]"
                  >
                    {{ campaignGift.title }}
                  </span>
                  <div class="tw-w-full tw-self-end tw-mt-auto">
                    <div class="tw-bg-[#B6B6B6] tw-w-11/12 tw-h-[1px] tw-mb-[13.6px]" />
                    <span class="tw-text-[15x] tw-font-black tw-font-lato tw-text-[#313131]">
                      {{
                        $t("donor_dashboard.donor_auction_campaigns_gift_points", "{points} Pts", {
                          points: campaignGift.points || 0,
                        })
                      }}
                    </span>
                  </div>
                </div>
                <DonorAuctionCampaignsPopover
                  :target="`available-gift-popover-mobile-${campaignGift.ID}`"
                  :campaignGift="campaignGift"
                  @hide-popover="hidePopover"
                />
              </div>
            </div>
          </template>
          <div v-else class="tw-my-[60px] tw-flex tw-justify-center tw-items-center">
            <div class="tw-text-[18px] tw-font-lato tw-font-regular tw-text-[#313131]">
              {{
                $t(
                  "donor_dashboard.donor_auction_campaigns_no_available_gifts",
                  "No available gifts"
                )
              }}
            </div>
          </div>
          <DonorAuctionCampaignsGiftClaimModal
            v-slot="{ show, loading }"
            :campaignId="preselectedOption"
            :campaignCurrency="preselectedOptionCurrency"
            :teamId="preselectedTeamOption"
            :meta="chineseAuctionMeta"
            @submit="onDonorAuctionCampaignsGiftClaimModalSubmit"
            @checkout="onDonorAuctionCampaignsGiftClaimModalCheckout"
            @hide="onDonorAuctionCampaignsGiftClaimModalHide"
            class="tw-flex tw-items-center tw-justify-center lg:tw-hidden"
          >
            <AuctionButton
              @click="() => show()"
              type="button"
              :disabled="loading || campaignGifts.length === 0"
            >
              <b-spinner v-if="loading" small class="tw-mr-[10px]" />
              {{ $t("donor_dashboard.donor_auction_campaigns_makeanewclaim", "Make A New Claim") }}
            </AuctionButton>
          </DonorAuctionCampaignsGiftClaimModal>
        </div>
        <div class="tw-border-2 tw-border-[#6BBBFF] tw-rounded-[10px] tw-bg-white">
          <div class="tw-px-[46px] tw-pt-[28px] tw-pb-[44.86px]">
            <div class="tw-flex tw-justify-between tw-items-center tw-mb-[35px]">
              <div class="tw-flex tw-space-x-[20px]">
                <button
                  :aria-checked="currentTab === 'my-donors'"
                  @click="currentTab = 'my-donors'"
                  type="button"
                  class="tw-font-lato tw-font-black tw-text-[25px] tw-text-[#B6B6B6] tw-pb-[5px] aria-checked:tw-text-[#0A8EFF] aria-checked:tw-border-b-[3px] aria-checked:tw-border-[#0A8EFF]"
                >
                  {{ $t("donor_dashboard.donor_auction_campaigns_my_donors", "My Donors") }}
                </button>
                <button
                  :aria-checked="currentTab === 'my-teams'"
                  @click="currentTab = 'my-teams'"
                  type="button"
                  class="tw-font-lato tw-font-black tw-text-[25px] tw-text-[#B6B6B6] tw-pb-[5px] aria-checked:tw-text-[#0A8EFF] aria-checked:tw-border-b-[3px] aria-checked:tw-border-[#0A8EFF]"
                >
                  {{ $t("donor_dashboard.donor_auction_campaigns_my_teams", "My Teams") }}
                </button>
              </div>
            </div>
            <template v-if="currentTab === 'my-donors'">
              <template v-if="donations.length > 0">
                <div class="lg:tw-hidden tw-w-full tw-grid tw-gap-[31px]">
                  <template v-if="loadingDonations">
                    <div
                      class="tw-grid tw-grid-cols-2 tw-gap-x-[9.01px] tw-gap-y-[23.34px]"
                      v-for="i in 10"
                      :key="`loading-donation-row-${i}`"
                    >
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="tw-grid tw-grid-cols-2 tw-gap-x-[9.01px] tw-gap-y-[23.34px]"
                      :class="{
                        'tw-pb-[41.34px] tw-border-b-[0.35px] tw-border-[#B6B6B6]':
                          donation.id !== donations.at(-1).id,
                      }"
                      v-for="donation in donations"
                      :key="`my-donors-row-${donation.id}`"
                    >
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_name",
                              "Name"
                            )
                          }}
                        </p>
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]"
                        >
                          {{ donation.attributes.name }}
                        </p>
                      </div>
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_teamid",
                              "Team ID"
                            )
                          }}
                        </p>
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            intlListFormatter(
                              donation.attributes.team_id_list.map((t) => String(t)),
                              "No team data"
                            )
                          }}
                        </p>
                      </div>
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_totalraised",
                              "Total Raised"
                            )
                          }}
                        </p>
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            intlCurrencyFormatter(
                              donation.attributes.total,
                              donation.attributes.currency_code
                            )
                          }}
                        </p>
                      </div>
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_gift_points",
                              "Gift Points"
                            )
                          }}
                        </p>
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]"
                        >
                          {{ calculateGiftPointsForDonation(donation) }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
                <table
                  class="tw-hidden lg:tw-table tw-w-full tw-h-[153px] tw-overflow-auto tw-table-fixed tw-border-separate tw-border-spacing-y-[18px]"
                >
                  <thead class="tw-mb-[19px]">
                    <tr class="tw-text-left">
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_name",
                              "Name"
                            )
                          }}
                        </span>
                      </th>
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_teamid",
                              "Team ID"
                            )
                          }}
                        </span>
                      </th>
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_totalraised",
                              "Total Raised"
                            )
                          }}
                        </span>
                      </th>
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_gift_points",
                              "Gift Points"
                            )
                          }}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="loadingDonations">
                    <tr v-for="(_, i) in 10" :key="`loading-donation-row-${i}`">
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="donation in donations" :key="`my-donors-row-${donation.id}`">
                      <td>
                        <span class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#313131]">
                          {{ donation.attributes.name }}
                        </span>
                      </td>
                      <td>
                        <span class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#313131]">
                          {{
                            intlListFormatter(
                              donation.attributes.team_id_list.map((t) => String(t)),
                              "No team data"
                            )
                          }}
                        </span>
                      </td>
                      <td>
                        <span class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#313131]">
                          {{
                            intlCurrencyFormatter(
                              donation.attributes.total,
                              donation.attributes.currency_code
                            )
                          }}
                        </span>
                      </td>
                      <td>
                        <span class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#313131]">
                          {{ calculateGiftPointsForDonation(donation) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <div v-else class="tw-flex tw-flex-col tw-justify-between tw-items-center tw-w-full">
                <h2 class="tw-font-regular tw-font-lato tw-text-[25px] tw-text-[#313131]">
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_no_donations_available",
                      "No donations found in this campaign"
                    )
                  }}
                </h2>
              </div>
            </template>
            <template v-else-if="currentTab === 'my-teams'">
              <template v-if="publicTeams.length > 0">
                <div class="lg:tw-hidden tw-w-full tw-grid tw-gap-[31px]">
                  <template v-if="loadingPublicMyTeams">
                    <div
                      class="tw-grid tw-grid-cols-2 tw-gap-x-[9.01px] tw-gap-y-[23.34px]"
                      v-for="i in 10"
                      :key="`loading-teams-row-${i}`"
                    >
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                      <div>
                        <b-skeleton width="100%" />
                        <b-skeleton width="100%" />
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="tw-grid tw-grid-cols-2 tw-gap-x-[9.01px] tw-gap-y-[23.34px]"
                      :class="{
                        'tw-pb-[41.34px] tw-border-b-[0.35px] tw-border-[#B6B6B6]':
                          team.id !== teams.at(-1).team.id,
                      }"
                      v-for="team in publicTeams"
                      :key="`my-teams-row-${team.id}`"
                    >
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_name",
                              "Team Name"
                            )
                          }}
                        </p>
                        <a
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#0A8EFF]"
                          :href="teamNameLink(team)"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {{ team.attributes.name }}
                        </a>
                      </div>
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_teamid",
                              "Campaign"
                            )
                          }}
                        </p>
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]"
                        >
                          {{ preselectedOptionTitle }}
                        </p>
                      </div>
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_totalraised",
                              "Amount"
                            )
                          }}
                        </p>
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.team_raised",
                              "{amount} of {goal} raised",
                              {
                                amount: intlCurrencyFormatter(
                                  team.attributes.donated + team.attributes.donated_children,
                                  preselectedOptionCurrency
                                ),
                                goal: intlCurrencyFormatter(
                                  team.attributes.goal,
                                  preselectedOptionCurrency
                                ),
                              },
                              team.attributes.goal
                            )
                          }}
                        </p>
                      </div>
                      <div class="tw-space-y-[19.3px]">
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]"
                        >
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_gift_points",
                              "Donors"
                            )
                          }}
                        </p>
                        <p
                          class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]"
                        >
                          {{ team.attributes.donation_n + team.attributes.donation_n_children }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
                <table
                  class="tw-hidden lg:tw-table tw-w-full tw-h-[153px] tw-overflow-auto tw-table-fixed tw-border-separate tw-border-spacing-y-[18px]"
                >
                  <thead class="tw-mb-[19px]">
                    <tr class="tw-text-left">
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_name",
                              "Team Name"
                            )
                          }}
                        </span>
                      </th>
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_teamid",
                              "Campaign"
                            )
                          }}
                        </span>
                      </th>
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_totalraised",
                              "Amount"
                            )
                          }}
                        </span>
                      </th>
                      <th class="tw-sticky tw-top-0">
                        <span class="tw-font-lato tw-font-black tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.donor_auction_campaigns_table_my_page_header_gift_points",
                              "Donors"
                            )
                          }}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="loadingPublicMyTeams">
                    <tr v-for="(_, i) in 10" :key="`loading-teams-row-${i}`">
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                      <td>
                        <b-skeleton width="50%" />
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr v-for="team in publicTeams" :key="`my-teams-row-${team.id}`">
                      <td>
                        <a
                          class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#0A8EFF]"
                          :href="teamNameLink(team)"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {{ team.attributes.name }}
                        </a>
                      </td>
                      <td>
                        <span class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#313131]">
                          {{ preselectedOptionTitle }}
                        </span>
                      </td>
                      <td>
                        <span class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#313131]">
                          {{
                            $t(
                              "donor_dashboard.team_raised",
                              "{amount} of {goal} raised",
                              {
                                amount: intlCurrencyFormatter(
                                  team.attributes.donated + team.attributes.donated_children,
                                  preselectedOptionCurrency
                                ),
                                goal: intlCurrencyFormatter(
                                  team.attributes.goal,
                                  preselectedOptionCurrency
                                ),
                              },
                              team.attributes.goal
                            )
                          }}
                        </span>
                      </td>
                      <td>
                        <span class="tw-font-lato tw-font-regular tw-text-[16px] tw-text-[#313131]">
                          {{ team.attributes.donation_n + team.attributes.donation_n_children }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <div v-else class="tw-flex tw-flex-col tw-justify-between tw-items-center tw-w-full">
                <h2 class="tw-font-regular tw-font-lato tw-text-[25px] tw-text-[#313131]">
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_no_teams_available",
                      "No teams found in this campaign"
                    )
                  }}
                </h2>
              </div>
            </template>

            <b-pagination
              v-if="currentTab === 'my-donors' && donationsTotal > 10"
              v-model="currentDonationsPage"
              :total-rows="donationsTotal"
              :per-page="10"
              class="!tw-mt-[35px] !tw-mb-0 donor-auction-campaigns-pagination"
            ></b-pagination>
            <b-pagination
              v-if="currentTab === 'my-teams' && publicTeamsTotal > 10"
              v-model="currentPublicTeamsPage"
              :total-rows="publicTeamsTotal"
              :per-page="10"
              class="!tw-mt-[35px] !tw-mb-0 donor-auction-campaigns-pagination"
            ></b-pagination>
          </div>
          <div
            v-if="currentTab === 'my-donors'"
            class="tw-bg-[#F4F7FC] tw-px-[46px] tw-py-[30px] tw-border-t-2 tw-border-[#6BBBFF] tw-rounded-[10px]"
          >
            <div class="lg:tw-hidden tw-w-full tw-flex-col tw-flex tw-space-y-[23px]">
              <p
                class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#0A8EFF]"
                v-html="
                  $t(
                    'donor_dashboard.donor_auction_campaigns_converted_to_points',
                    'Converted to points:<br> (at reward set to {percent})',
                    {
                      percent: `${convertedToPoints}%`,
                    }
                  )
                "
              />
              <div class="tw-w-full tw-grid tw-grid-cols-3">
                <div class="tw-col-span-2 tw-w-full tw-block tw-self-start">
                  <p class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]">
                    {{ $t("donor_dashboard.teams_stats_raised", "Total Raised") }}
                  </p>
                  <span class="tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]">
                    {{
                      intlCurrencyFormatter(
                        donationsTotals.effectiveAmount,
                        preselectedOptionCurrency
                      )
                    }}
                  </span>
                </div>
                <div class="tw-col-span-1 tw-w-full tw-block tw-self-end">
                  <p class="tw-mb-0 tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]">
                    {{ $t("donor_dashboard.teams_stats_points", "Total Points") }}
                  </p>
                  <span class="tw-font-lato tw-font-normal tw-text-[16px] tw-text-[#313131]">
                    {{ intlDecimalFormatter(donationTotalsGiftPoints) }}
                  </span>
                </div>
              </div>
            </div>
            <table class="tw-hidden lg:tw-table tw-w-full tw-table-fixed">
              <tbody>
                <tr>
                  <td>
                    <span
                      class="tw-font-lato tw-font-black tw-text-[15px] tw-text-[#0A8EFF]"
                      v-html="
                        $t(
                          'donor_dashboard.donor_auction_campaigns_converted_to_points',
                          'Converted to points:<br> (at reward set to {percent})',
                          {
                            percent: `${convertedToPoints}%`,
                          }
                        )
                      "
                    />
                  </td>
                  <td />
                  <td>
                    <span class="tw-font-lato tw-font-black tw-text-[15px] tw-text-[#313131]">
                      {{
                        intlCurrencyFormatter(
                          donationsTotals.effectiveAmount,
                          preselectedOptionCurrency
                        )
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="tw-font-lato tw-font-black tw-text-[15px] tw-text-[#313131]">
                      {{
                        $t("donor_dashboard.donor_auction_campaigns_gift_points", "{points} Pts", {
                          points: intlDecimalFormatter(donationTotalsGiftPoints),
                        })
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="currentTab === 'my-teams'"
            class="tw-bg-[#F4F7FC] tw-px-[46px] tw-py-[30px] tw-border-t-2 tw-border-[#6BBBFF] tw-rounded-[10px]"
          >
            <div class="lg:tw-hidden tw-w-full tw-flex-col tw-flex tw-space-y-[23px]">
              <p
                class="tw-mb-0 tw-font-lato tw-font-black tw-text-[16px] tw-text-[#0A8EFF]"
                v-html="
                  $t(
                    'donor_dashboard.donor_auction_campaigns_converted_to_points',
                    'Converted to points<br> (at reward set to {percent})',
                    {
                      percent: `${subConvertedToPoints}%`,
                    }
                  )
                "
              ></p>
            </div>
            <table class="tw-hidden lg:tw-table tw-w-full tw-table-fixed">
              <tbody>
                <tr>
                  <td>
                    <span
                      class="tw-font-lato tw-font-black tw-text-[15px] tw-text-[#0A8EFF]"
                      v-html="
                        $t(
                          'donor_dashboard.donor_auction_campaigns_converted_to_points',
                          'Converted to points<br> (at reward set to {percent})',
                          {
                            percent: `${subConvertedToPoints}%`,
                          }
                        )
                      "
                    >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-[22px] lg:tw-gap-[38px]">
          <div
            class="tw-bg-blue-500 tw-text-white tw-rounded-[10px] tw-grid tw-grid-cols-3 tw-items-center tw-justify-center lg:tw-gap-[40px] tw-ps-6 lg:tw-ps-[60px] tw-py-[35px]"
          >
            <div class="tw-col-span-1 tw-w-[31px] tw-h-[31px] lg:tw-w-[66px] lg:tw-h-[66px]">
              <IconAuctionTotalRaised class="tw-w-full tw-h-full" />
            </div>
            <div class="tw-col-span-2">
              <div class="tw-text-[24px] lg:tw-text-[50px] tw-font-lato tw-font-bold">
                {{ intlCurrencyFormatter(teamTotal + subTeamsTotal, preselectedOptionCurrency) }}
              </div>
              <div class="tw-text-[10px] lg:tw-text-[22px] tw-font-light tw-font-lato">
                {{ $t("donor_dashboard.teams_stats_raised", "Total Raised") }}
              </div>
            </div>
          </div>
          <div
            class="tw-bg-blue-500 tw-text-white tw-rounded-[10px] tw-grid tw-grid-cols-3 tw-items-center tw-justify-center lg:tw-gap-[40px] tw-ps-6 lg:tw-ps-[60px] tw-py-[35px]"
          >
            <div class="tw-col-span-1 tw-w-[31px] tw-h-[31px] lg:tw-w-[66px] lg:tw-h-[66px]">
              <IconAuctionTotalPoints class="tw-w-full tw-h-full" />
            </div>
            <div class="tw-col-span-2">
              <div class="tw-text-[24px] lg:tw-text-[50px] tw-font-lato tw-font-bold">
                {{ intlDecimalFormatter(calculatedPointsTotal) }}
              </div>
              <div class="tw-text-[10px] lg:tw-text-[22px] tw-font-light tw-font-lato">
                {{ $t("donor_dashboard.teams_stats_points", "Total Points") }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-px-[27.1px] tw-pt-[34.12px] tw-pb-[35.04px] lg:tw-px-[46px] lg:tw-pt-[28px] lg:tw-pb-[44.86px] tw-border-2 tw-border-[#6BBBFF] tw-rounded-[10px] tw-bg-white"
        >
          <div
            class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-[28px] lg:tw-gap-0 tw-justify-between tw-mb-[42px] lg:tw-mb-[49.8px]"
          >
            <h2
              class="tw-font-black tw-font-lato tw-mb-0 tw-leading-none lg:tw-mb-2 lg:tw-leading-[1.2] tw-text-[25px] tw-text-[#0A8EFF]"
            >
              {{ $t("donor_dashboard.donor_auction_campaigns_claimed_gifts", "My Claimed Gifts") }}
            </h2>
            <DonorAuctionCampaignsGiftClaimModal
              v-slot="{ show, loading }"
              :campaignId="preselectedOption"
              :campaignCurrency="preselectedOptionCurrency"
              :teamId="preselectedTeamOption"
              :meta="chineseAuctionMeta"
              @submit="onDonorAuctionCampaignsGiftClaimModalSubmit"
              @checkout="onDonorAuctionCampaignsGiftClaimModalCheckout"
              @hide="onDonorAuctionCampaignsGiftClaimModalHide"
              class="tw-w-full lg:tw-w-auto"
            >
              <AuctionButton
                @click="() => show()"
                type="button"
                class="tw-w-full lg:tw-w-auto"
                :disabled="loading || campaignGifts.length === 0"
              >
                <b-spinner v-if="loading" small class="tw-mr-[10px]" />
                {{
                  $t("donor_dashboard.donor_auction_campaigns_makeanewclaim", "Make A New Claim")
                }}
              </AuctionButton>
            </DonorAuctionCampaignsGiftClaimModal>
          </div>
          <div
            class="tw-mb-[51px] lg:tw-my-[60px] tw-space-y-[40px] lg:tw-space-y-[29px]"
            v-if="teamGiftClaims.length > 0"
          >
            <div
              class="tw-grid tw-grid-cols-2 tw-gap-y-[33px] lg:tw-gap-0 lg:tw-grid-cols-4 tw-items-center"
              :class="{
                'lg:tw-p-0 lg:tw-border-0 tw-pb-[45px] tw-border-b-[0.31px] tw-border-[#B6B6B6]':
                  teamGiftClaim.id !== teamGiftClaims.at(-1).id,
              }"
              v-for="teamGiftClaim in teamGiftClaims"
              :key="`my-claimed-gift-${teamGiftClaim.id}`"
            >
              <img
                v-if="teamGiftClaim.relationships.gift.data.attributes.image"
                :src="teamGiftClaim.relationships.gift.data.attributes.image"
                :alt="teamGiftClaim.relationships.gift.data.attributes.title"
                width="94"
                height="94"
                class="tw-w-[94px] tw-h-[94px] tw-rounded-full tw-object-cover tw-col-span-1"
              />
              <div
                v-else
                aria-roledescription="image placeholder"
                class="tw-w-[94px] tw-h-[94px] tw-rounded-full tw-bg-gray-300 tw-col-span-1"
              />
              <div class="tw-col-span-1">
                <div class="tw-font-normal tw-font-lato tw-text-[21px] tw-text-[#313131]">
                  {{ teamGiftClaim.relationships.gift.data.attributes.title }}
                </div>
                <div
                  class="lg:tw-hidden tw-block tw-font-normal tw-font-lato tw-text-[21px] tw-text-[#313131]"
                >
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_claimed_gift_points",
                      "{points} pts",
                      {
                        points: teamGiftClaim.relationships.gift.data.attributes.points || 0,
                      }
                    )
                  }}
                </div>
                <div class="tw-text-[11px] tw-text-[#313131] tw-font-lato tw-italic">
                  {{
                    $t("donor_dashboard.donor_auction_campaigns_gift_id", "ID Number: {id}", {
                      id: teamGiftClaim.relationships.gift.data.id,
                    })
                  }}
                </div>
              </div>
              <div
                class="tw-hidden lg:tw-block tw-font-normal tw-font-lato tw-text-[21px] tw-text-[#313131] tw-col-span-1"
              >
                {{
                  $t(
                    "donor_dashboard.donor_auction_campaigns_claimed_gift_points",
                    "{points} pts",
                    {
                      points: teamGiftClaim.relationships.gift.data.attributes.points || 0,
                    }
                  )
                }}
              </div>
              <div class="tw-col-span-2 lg:tw-col-span-1">
                <span class="tw-font-light tw-font-lato tw-text-[21px] tw-text-[#313131]">
                  {{ $t("donor_dashboard.donor_auction_campaigns_gift_status", "Status:") }}
                </span>
                <span
                  class="tw-font-regular tw-font-lato tw-text-[21px] tw-text-[#6BBBFF]"
                  v-if="teamGiftClaim.attributes.status === CharidyCampaignGiftsClaimStatusCanceled"
                >
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_gift_status_canceled",
                      CharidyCampaignGiftsClaimStatusCanceled
                    )
                  }}
                </span>
                <span
                  class="tw-font-regular tw-font-lato tw-text-[21px] tw-text-[#6BBBFF]"
                  v-else-if="
                    teamGiftClaim.attributes.status ===
                    CharidyCampaignGiftsClaimStatusCurrentlyShipping
                  "
                >
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_gift_status_currently_shipping",
                      CharidyCampaignGiftsClaimStatusCurrentlyShipping
                    )
                  }}
                </span>
                <span
                  class="tw-font-regular tw-font-lato tw-text-[21px] tw-text-[#6BBBFF]"
                  v-else-if="
                    teamGiftClaim.attributes.status === CharidyCampaignGiftsClaimStatusDelivered
                  "
                >
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_gift_status_delivered",
                      CharidyCampaignGiftsClaimStatusDelivered
                    )
                  }}
                </span>
                <span
                  class="tw-font-regular tw-font-lato tw-text-[21px] tw-text-[#6BBBFF]"
                  v-else-if="
                    teamGiftClaim.attributes.status === CharidyCampaignGiftsClaimStatusOther
                  "
                >
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_gift_status_other",
                      CharidyCampaignGiftsClaimStatusOther
                    )
                  }}
                </span>
                <span
                  class="tw-font-regular tw-font-lato tw-text-[21px] tw-text-[#6BBBFF]"
                  v-else-if="
                    teamGiftClaim.attributes.status === CharidyCampaignGiftsClaimStatusProcessed
                  "
                >
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_gift_status_processed",
                      CharidyCampaignGiftsClaimStatusProcessed
                    )
                  }}
                </span>
                <span
                  class="tw-font-regular tw-font-lato tw-text-[21px] tw-text-[#6BBBFF]"
                  v-else-if="
                    teamGiftClaim.attributes.status === CharidyCampaignGiftsClaimStatusSentToCarrier
                  "
                >
                  {{
                    $t(
                      "donor_dashboard.donor_auction_campaigns_gift_status_sent_to_carrier",
                      CharidyCampaignGiftsClaimStatusSentToCarrier
                    )
                  }}
                </span>
                <span class="tw-font-regular tw-font-lato tw-text-[21px] tw-text-[#6BBBFF]" v-else>
                  {{ $t("donor_dashboard.donor_auction_campaigns_gift_status_undefined", "None") }}
                </span>
              </div>
            </div>
          </div>
          <div v-else class="tw-my-[60px] tw-flex tw-justify-center tw-items-center">
            <div class="tw-text-[18px] tw-font-lato tw-font-regular tw-text-[#313131]">
              {{
                $t(
                  "donor_dashboard.donor_auction_campaigns_no_available_gifts",
                  "No available gifts"
                )
              }}
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.swiper-button-next {
  background-image: url('data:image/svg+xml,<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.00004 15.2109L8.10547 8.1055L1.00004 1.00007" stroke="%23313131" stroke-width="2"/></svg>');
  width: 10px;
  height: 16px;
  background-size: 10px 16px;
}

.swiper-button-prev {
  background-image: url('data:image/svg+xml,<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.00004 1.00007L1.89461 8.1055L9.00004 15.2109" stroke="%23313131" stroke-width="2"/></svg>');
  width: 10px;
  height: 16px;
  background-size: 10px 16px;
}
.donor-auction-campaigns-pagination {
  font-family: "Lato", sans-serif;

  ::v-deep {
    .page-link {
      background-color: white;
      border: none;
    }
    .page-item {
      &.active {
        .page-link {
          color: #0a8eff;
          background-color: white;
          border: none;
        }
      }
    }
  }
}
</style>
