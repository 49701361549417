import { render, staticRenderFns } from "./campaign_promo_code.schema.vue?vue&type=template&id=406dc229&scoped=true&"
import script from "./campaign_promo_code.schema.vue?vue&type=script&lang=js&"
export * from "./campaign_promo_code.schema.vue?vue&type=script&lang=js&"
import style0 from "./campaign_promo_code.schema.vue?vue&type=style&index=0&id=406dc229&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "406dc229",
  null
  
)

export default component.exports