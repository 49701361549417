<template>
  <div>

    <b-modal id="editDonation" @hidden='clearData' size="lg" no-close-on-backdrop>

      <div slot="modal-header">
        <h2 class="h1-like">{{ $t('dashboard.edit_edit_donation') }} #{{ donationId }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">{{ $t('dashboard.donation_edit_cancel') }}
        </button>
        <button form="donation-edit" class="bttn bttn--lg bttn--orange" :disabled='loading' @click.prevent="editDonate">
          {{ $t('dashboard.donation_edit_save') }}
        </button>
      </div>

      <form id="donation-edit" @submit.prevent="editDonate" class="entities-data">

        <error v-if='errors' :errors='errors'></error>

        <div class="row">

          <div class="col-12 col-md-6">

            <div class="form-group">
              <label class="entities-data__label entities-data__label--required">
                {{ $t('dashboard.edit_donate_display_name') }}
              </label>
              <b-form-input
                required
                v-model="editDonation.data.attributes.display_name"></b-form-input>
            </div>

          </div>

          <div class="col-12 col-md-6">

            <div class="form-group">
              <label
                class="entities-data__label entities-data__label--required">
                {{ $t('dashboard.edit_donate_email') }}
              </label>
              <b-form-input
                v-model="editDonation.data.attributes.email"></b-form-input>
            </div>

          </div>

        </div>

        <div class="row">

          <div class="col-12 col-md-6">

            <div class="form-group">
              <label
                class="entities-data__label entities-data">
                {{ $t('donors.offline_donation_source') }}
              </label>
              <b-form-select
                v-model="editDonation.data.attributes.offline_donation_source">
                <option value="cash">{{ $t('donors.cash') }}</option>
                <option value="check">{{ $t('donors.check') }}</option>
                <option value="bank_transfer">{{ $t('donors.bank_transfer') }}</option>
                <option value="recurring_credit_card">{{ $t('donors.recurring_credit_card') }}</option>
                <option value="recurring_bank_transfer">{{ $t('donors.recurring_bank_transfer') }}</option>
              </b-form-select>
            </div>

          </div>
          <div class="col-12 col-md-6">

            <div class="form-group">
              <label
                class="entities-data__label entities-data">
                {{ $t('donors.offline_donation_note') }}
              </label>
              <b-form-input
                v-model="editDonation.data.attributes.offline_donation_note">
              </b-form-input>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="col-12 col-md-6" v-if='teamList.length > 0'>

            <div class="form-group form-unit form-unit--multiselect">
              <label
                class="entities-data__label entities-data">
                {{ $t('dashboard.edit_donate_team') }}
              </label>
              <multiselect
                v-model="multiselectTeam"
                :options="teamList"
                label="text"
                track-by="text"
                :multiple="false">
              </multiselect>
            </div>

          </div>
        </div>
        <div class="row">

          <div class="col-12">

            <div class="form-group">
              <label class="entities-data__label">{{ $t('dashboard.edit_donate_dedication') }}</label>
              <b-form-input
                v-model="editDonation.data.attributes.dedication"
                :rows="2"></b-form-input>
            </div>

          </div>

        </div>
        <div class="row"
             v-if="editDonation.data.attributes.bank_name === 'pledge' && (this.mode === 1 ||this.mode === 2)">
          <div class="col-12 col-md-6">

            <div class="form-group">
              <label class="entities-data__label entities-data">{{ $t('dashboard.edit_donate_status') }}</label>
              <b-form-select v-model="editDonation.data.attributes.status">
                <option v-for="(status, index) in ['Pending', 'Processed']"
                        :value="status"
                        :key="index">{{ status }}
                </option>
              </b-form-select>
            </div>

          </div>
          <div class="col-12 col-md-6">

            <p class="status-description"> {{ getStatusMessage }}</p>

          </div>

        </div>

        <div class="row" v-if="premoderation">
          <div class="col-12 col-lg-6">

            <div class="radio-bttn-group">

              <div class="form-group form-unit form-group-bttn">
                <input
                        id="on-review"
                        type="radio"
                        value="0"
                        v-model="editDonation.data.attributes.moderation_status" :disabled="isCheck">
                <label for="on-review">{{ $t('donors.donation_on_review') }}</label>
              </div>

              <div class="form-group form-unit form-group-bttn">
                <input
                        id="approved"
                        type="radio"
                        value="1"
                        v-model="editDonation.data.attributes.moderation_status" :disabled="isCheck">
                <label for="approved">{{ $t('donors.donation_approved') }}</label>
              </div>

            </div>

          </div>
          <div class="col-12 col-lg-6">
            <div class="radio-bttn-group-text">
              <span v-if="isCheck">
                {{ $t('donors.donation_moderation_check_note') }}
              </span>
              <span v-else>
                {{ $t('donors.donation_status_change_describe') }}
              </span>
            </div>
          </div>

        </div>


        <div class="wrapper-options">
          <button type="button"
                  @click='showOptionaly = !showOptionaly'
                  class="show-hide-bttn"
                  name="button">
            {{ !showOptionaly ? $t('dashboard.edit_donation_show_optionaly') :
            $t('dashboard.edit_donation_hide_optionaly') }}
            <i :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'" aria-hidden="true"></i>
          </button>
        </div>

        <div class="optionally-params" v-if='showOptionaly'>

          <div class="row">
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label class="entities-data__label entities-data">{{ $t('dashboard.edit_donate_billing_name') }}</label>
                <b-form-input v-model="editDonation.data.attributes.billing_name"></b-form-input>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label class="entities-data__label entities-data">{{ $t('dashboard.edit_donate_billing_last_name')
                  }}</label>
                <b-form-input v-model="editDonation.data.attributes.billing_last_name"></b-form-input>
              </div>

            </div>

          </div>
          <div class="row">
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.edit_donate_phone') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.phone"></b-form-input>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.edit_donate_country') }}
                </label>
                <b-form-select
                  v-model="editDonation.data.attributes.payment_country">
                  <option v-for='country in countryList' :value="country.attributes.name">{{ country.attributes.name
                    }}
                  </option>
                </b-form-select>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.edit_donate_city') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.payment_city"></b-form-input>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.edit_donate_address') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.payment_address"></b-form-input>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.add_donate_payment_address_2') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.payment_address_2"></b-form-input>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.add_donate_referrer') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.referrer"></b-form-input>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.add_donate_payment_state') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.payment_state"></b-form-input>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.edit_donate_category') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.category"></b-form-input>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">

              <div class="form-group">
                <label
                  class="entities-data__label entities-data">
                  {{ $t('dashboard.edit_donate_postcode') }}
                </label>
                <b-form-input
                  v-model="editDonation.data.attributes.payment_postcode"></b-form-input>
              </div>

            </div>
          </div>

          <div class="row" v-if='editDonation.data.attributes.donation_type.toLowerCase() === "d"'>
            <div class="cancel-block">
              <div class="col-12 col-md-12" v-for='cancel in chargeRequestType'>
                <div class="cancel-block__row" v-if='cancel.status === true'>
                  <button
                    type="button"
                    class="bttn bttn--sm bttn--orange"
                    :disabled='cancelDonate.data.attributes.type === cancel.value'
                    @click='changeCancel(cancel.value)'
                  >
                    {{ cancel.text }}
                  </button>
                  <p
                    v-if='cancelDonate.data.attributes.type !== cancel.value'
                  >
                    {{ cancel.description }}
                    <p v-if='editDonation.data.attributes.bank_name.toLowerCase() === "yaadpay"'>
                      {{ $t('dashboard.description_cancel_donate_yaadpay') }}
                    </p>
                    <p v-if='editDonation.data.attributes.bank_name.toLowerCase() === "payme"'>
                      {{ $t('dashboard.description_cancel_donate_payme') }}
                    </p>
                  </p>
                  <div class="confirm-block" v-if='cancelDonate.data.attributes.type === cancel.value'>
                    <b-form-input :placeholder="$t('dashboard.placeholder_cancel_donate')" v-model="cancelDonate.data.attributes.note"/>
                    <p v-if='cancelDonateError'>
                      {{ cancelDonateError }}
                    </p>
                    <button
                      type="button"
                      class="bttn bttn--sm bttn--orange"
                      :disabled='cancelDonateSending'
                      @click='submitCancelDonation()'
                    >
                      {{ $t('dashboard.confirm') }}
                    </button>
                  </div>
                </div>
                <div v-else class="cancel-block__row">
                  <p v-if='cancel.status === 0'>
                    {{ $t('dashboard.status_review_before_delete_'+cancel.value) }}
                    <span @click='deleteCancel(cancel)'>
                      {{ $t('dashboard.status_review_delete_'+cancel.value) }}
                    </span>
                    {{ $t('dashboard.status_review_after_delete_'+cancel.value) }}
                  </p>
                  <p v-else-if='cancel.status === 1'>
                    {{ $t('dashboard.status_accepted_'+cancel.value) }}
                  </p>
                  <p v-else-if='cancel.status === 2'>
                    {{ $t('dashboard.status_declined_'+cancel.value) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <error v-if='errors' :errors='errors'></error>

      </form>

    </b-modal>

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

import { mapState } from "vuex";

import axios from "axios";

import urls from "@/constants/urls.js";

import error from "@/components/errors.vue";

export default {
  props: ["campaignid", "currentpage"],

  data() {
    return {
      editDonation: {
        data: {
          attributes: {
            display_name: "",
            billing_name: "",
            dedication: "",
            category: "",
            team_id: 0,
            offline_donation_source: "",
            offline_donation_note: "",
            moderation_status: 0
          }
        }
      },

      cancelDonate: {
        data: {
          attributes: {
            type: "",
            note: ""
          }
        }
      },

      cancelDonateError: false,

      cancelDonateSending: false,

      donationId: 0,

      showOptionaly: false,

      premoderation: false,

      donationApproved: false,

      isCheck: false,

      errors: [],

      multiselectTeam: null,

      loading: false,

      chargeRequestType: [
        {
          text: this.$t("dashboard.cancel_donation"),
          value: "cancel",
          description: this.$t("dashboard.cancel_donation_description"),
          status: true
        }
      ]
    };
  },

  components: {
    error: error,
    Multiselect
  },

  watch: {
    multiselectTeam: function() {
      if (this.multiselectTeam) {
        this.editDonation.data.attributes.team_id = parseInt(
          this.multiselectTeam.value
        );
      } else {
        this.editDonation.data.attributes.team_id = 0;
      }
    }
  },

  computed: mapState({
    teams: state => {
      return state.teams.allTeams;
    },

    includedDonations: state => {
      return state.donation.included;
    },

    teamList: state => {
      let arrTeams = state.teams.allTeams;

      let resultArr = [];

      for (var i = 0; i < arrTeams.length; i++) {
        let objTeam = {
          value: arrTeams[i].id,
          text: `[${arrTeams[i].id}] ${arrTeams[i].attributes.name}`
        };

        resultArr.push(objTeam);
      }

      return resultArr;
    },

    countryList: state => {
      return state.account.countries;
    },

    orgId: function() {
      return localStorage.getItem("orgId");
    },

    getStatusMessage() {
      return this.editDonation.data.attributes.status === "Pending"
        ? this.$t("dashboard.edit_donate_pending_description")
        : this.$t("dashboard.edit_donate_processed_description");
    },

    mode: state => {
      return state.dashboard.campaignDashboard.data.attributes.mode;
    }
  }),

  methods: {
    changeCancel: function(value) {
      this.cancelDonate.data.attributes.type = value;
    },

    submitCancelDonation: function() {
      this.cancelDonateError = false;
      this.cancelDonateSending = true;
      this.cancelDonate.data.attributes.donation_id = parseInt(this.donationId);
      axios
        .post(
          urls.cancelDonate
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignid)
            .replace(":donationId", this.donationId),
          this.cancelDonate
        )
        .then(response => {
          this.setNewIncluded(
            response.data.data.relationships.donation_change_requests.data,
            response.data.included
          );
          this.cancelDonateSending = false;
          this.changeCancel("");
        })
        .catch(e => {
          this.cancelDonateSending = false;
        });
    },

    deleteCancel(val) {
      let message = this.$t("dashboard.confirm_delete_cancel_donate");
      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel")
      };

      this.$dialog.confirm(message, options).then(() => {
        axios
          .delete(
            urls.cancelDonateDelete
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignid)
              .replace(":donationId", this.donationId)
              .replace(":id", val.id)
          )
          .then(response => {
            this.setNewIncluded(
              response.data.data.relationships.donation_change_requests.data,
              response.data.included
            );
          });
      });
    },

    setNewIncluded: function(rel, included = []) {
      let status = [];
      rel.forEach(el => {
        const arr = included.filter(includ => {
          return includ.type === el.type && includ.id === el.id;
        });
        if (arr.length > 0) {
          status.push(arr[0]);
        }
      });
      this.chargeRequestType.forEach(cancel => {
        cancel.status = true;
      });
      status.forEach(el => {
        this.chargeRequestType.forEach(cancel => {
          if (cancel.value === el.attributes.type) {
            cancel.status = el.attributes.status;
            cancel.id = el.id;
          }
        });
      });
    },

    setIncluded: function(rel) {
      let status = [];
      rel.forEach(el => {
        const arr = this.includedDonations.filter(includ => {
          return includ.type === el.type && includ.id === el.id;
        });
        if (arr.length > 0) {
          status.push(arr[0]);
        }
      });
      status.forEach(el => {
        this.chargeRequestType.forEach(cancel => {
          if (cancel.value === el.attributes.type) {
            cancel.status = el.attributes.status;
            cancel.id = el.id;
          }
        });
      });
    },

    openEditModal: function(donate, premoderation) {
      this.errors = [];
      this.premoderation = premoderation;
      this.donationId = donate.id;
      this.isCheck = donate.attributes.bank_name === "check";
      this.editDonation.data.attributes = donate.attributes;

      if (this.editDonation.data.attributes.recurring_period > 0) {
        this.chargeRequestType.push({
          text: this.$t("dashboard.cancel_subscription"),
          value: "cancel_subscription",
          description: this.$t("dashboard.cancel_subscription_description"),
          status: true
        });
      }

      if (
        this.editDonation.data.attributes.status.toLowerCase() === "processed"
      ) {
        this.chargeRequestType.push({
          text: this.$t("dashboard.cancel_refund"),
          value: "cancel_refund",
          description: this.$t("dashboard.cancel_refund_description"),
          status: true
        });
      }

      this.setIncluded(donate.relationships.donation_change_requests.data);

      this.$root.$emit("bv::show::modal", "editDonation");

      this.showOptionaly = false;

      this.setActiveTeam();
    },

    setActiveTeam: function() {
      let selectedTeam = this.teamList.filter(el => {
        return el.value == this.editDonation.data.attributes.team_id;
      });

      if (selectedTeam.length > 0) {
        this.multiselectTeam = selectedTeam[0];
      } else {
        this.multiselectTeam = null;
      }
    },

    clearData() {
      this.chargeRequestType = [
        {
          text: this.$t("dashboard.cancel_donation"),
          value: "cancel",
          description: this.$t("dashboard.cancel_donation_description"),
          status: true
        }
      ];
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "editDonation");
    },

    editDonate() {
      this.sending = true;
      this.errors = [];
      this.editDonation.data.attributes.moderation_status = parseInt(
        this.editDonation.data.attributes.moderation_status
      );

      axios
        .patch(
          urls.putDonation
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignid) +
            "/" +
            this.donationId,
          this.editDonation
        )
        .then(response => {
          this.sending = false;

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("dashboard.edit_success_edit")
          });

          let params = {
            id: this.campaignid,
            setting: `?page=${
              this.currentpage
            }&limit=50&extend=donation_receipt_id`
          };

          this.$store.dispatch("getCampaignDonation", params);

          let params2 = {
            id: this.campaignid,
            setting: `?moderation_status=0&page=${
              this.currentpage
            }&limit=50&extend=donation_receipt_id`
          };

          this.$store.dispatch("getPendingCampaignDonation", params2);

          this.hideModal();
        })
        .catch(e => {
          this.sending = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-block {
  display: block;
  border-radius: 5px;
  border: 1px solid red;
  padding: 10px 0px;
  width: 100%;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    > button {
      margin-right: 15px;
    }
    p {
      margin: 0;
    }
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .confirm-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    input {
      margin-right: 15px;
    }
  }
}

.entities-data {
  &__label {
    text-transform: uppercase;
    font-size: 14px;
    color: #414359;
    line-height: 1;
    &--required {
      padding-right: 10px;
      position: relative;
      &:after {
        content: "*";
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: red;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.status-description {
  margin-top: 25px;
}

.entities-add {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  color: #20434e;
  background-color: #ffffff;
}

.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eaf4ff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .show-hide-bttn {
    width: auto;
    position: relative;
    &:focus {
      outline: 0;
    }
  }
}

.radio-bttn-group {
  display: flex;
  padding: 10px 0;
  &-text {
    padding: 10px 0;
  }
  .form-group-bttn {
    margin: 0;
    display: flex;
    width: 50%;
    position: relative;
    height: 50px;
    input {
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    label {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-family: "VitoExtended-Bold";
      color: #107598;
      cursor: pointer;
    }
    input:checked + label {
      background-color: #107598;
      color: #ffffff;
    }
    input:disabled + label {
      opacity: 0.3;
      pointer-events: none;
    }
    &:first-of-type {
      label {
        border-top: 1px solid #107598;
        border-bottom: 1px solid #107598;
        border-left: 1px solid #107598;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &:last-of-type {
      label {
        border-top: 1px solid #107598;
        border-bottom: 1px solid #107598;
        border-right: 1px solid #107598;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
</style>
