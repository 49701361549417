<script>
import CampaignDescription from "../../campaign-edit-vue/subcomponents/campaignDescription.vue";
import SettingModal from "@/views/campaigns/campaign-settings/settingModal.vue";

export default {
  components: {
    CampaignDescription,
    SettingModal,
  },

  props: {
    baseSettingsItem: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<template>
  <SettingModal :baseSettingsItem="baseSettingsItem">
    <template #content="{ hide }">
      <CampaignDescription :description-tags="['past_achievements']" :closeModal="hide" />
      <button
        type="button"
        class="bttn bttn--sm bttn--blue tw-me-auto tw-mt-7"
        @click="hide"
        name="button"
      >
        {{ $t("edit_campaign.setting_close", "Close") }}
      </button>
    </template>
  </SettingModal>
</template>
