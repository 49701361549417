export const getOfflineDonationSourceOptions = (vm) => {
  return [
    { value: "", text: "Please select an option" },
    { value: "cash", text: vm.$t("donors.cash") },
    { value: "credit_card", text: vm.$t("donors.credit_card") },
    { value: "check", text: vm.$t("donors.check") },
    { value: "bank_transfer", text: vm.$t("donors.bank_transfer") },
    { value: "recurring_credit_card", text: vm.$t("donors.recurring_credit_card") },
    { value: "recurring_bank_transfer", text: vm.$t("donors.recurring_bank_transfer") },
    { value: "charity_voucher", text: vm.$t("donors.charity_voucher") },
    { value: "pledge", text: vm.$t("donors.pledge") },
    { value: "fund", text: vm.$t("donors.fund") },
    { value: "crm_donations", text: vm.$t("donors.crm_donations") },
    { value: "loan", text: vm.$t("donors.loan") },
    { value: "sms", text: vm.$t("donors.sms") },
    { value: "paypal", text: vm.$t("donors.paypal", "PayPal") },
    { value: "fidelity", text: vm.$t("donors.fidelity", "Fidelity") },
    { value: "zelle", text: vm.$t("donors.zeelle", "Zelle") },
    { value: "other", text: vm.$t("donors.other", "Other") },
  ];
};
