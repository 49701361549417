<template>
  <div>
    <button
      v-if="!dashboard"
      :disabled="!hasAccessAdd"
      class="bttn bttn--sm bttn--orange"
      @click="openModal"
    >
      {{ $t("teams.add_new_team", "Add new team") }}
    </button>
    <b-modal id="team-form" size="xl" @hidden="clearData">
      <div slot="modal-header">
        <h2>
          {{
            edit ? $t("teams.edit_team", "Edit team") : $t("teams.add_team_modal", "Add new team")
          }}
        </h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
          {{ $t("teams.close") }}
        </button>
        <button
          form="team-add"
          type="submit"
          :disabled="sending"
          :class="sending ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
        >
          <b-spinner v-if="sending" small class="mx-1" />
          {{ $t("teams.save") }}
        </button>
      </div>

      <form
        id="team-add"
        ref="TeamAdd"
        action
        class
        @submit.prevent="saveForm"
        @click="hidePickerColor"
      >
        <error v-if="errors" :errors="errors"></error>

        <div class="form-block">
          <b-tabs content-class="mt-3" @activate-tab="onActivateTab">
            <b-tab :title="$t('teams.details_tab', 'Team details')">
              <div class="row">
                <div class="col-12 col-lg-3">
                  <div class="matcher-card__img">
                    <div class="matcher-img">
                      <label v-if="!teamData.data.attributes.image" @click="changeImage()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                          />
                        </svg>
                        {{ $t("teams.upload_image") }}
                      </label>

                      <div v-if="teamData.data.attributes.image" class="matcher-img__loaded">
                        <div class="image-wrapper">
                          <img :src="teamData.data.attributes.image" alt />
                        </div>
                        <div class="matcher-img__action matcher-img__delete" @click="deleteImage()">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                            />
                            <path d="M0 0h24v24H0z" fill="none" />
                          </svg>
                        </div>
                        <label
                          class="matcher-img__action matcher-img__change"
                          @click="changeImage()"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                            />
                          </svg>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-9">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group form-unit">
                        <label class="form-unit__label form-unit__label--required" for>{{
                          $t("teams.name", "Team Name")
                        }}</label>
                        <input
                          type="text"
                          class="form-control form-unit__input"
                          v-model="teamData.data.attributes.name"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="form-group form-unit form-unit--addon-right">
                        <label class="form-unit__label">{{ $t("teams.goal") }}</label>
                        <div class="form-unit__addon-wrap">
                          <input
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            class="form-control form-unit__input"
                            :placeholder="$t('teams.goal')"
                            v-model.number="displayGoalCurrencyAmountModel"
                            required
                          />
                          <div class="form-unit__addon-icon form-unit__addon-icon--right px-1">
                            <b-dropdown class="dropdown">
                              <template #button-content>
                                <span class="text-uppercase">
                                  {{ displayGoalCurrencyModel }}
                                </span>
                              </template>
                              <b-dropdown-item
                                v-for="c in currencies"
                                :key="c"
                                @click="updateCurrency(c)"
                              >
                                {{ $t(`dashboard.currency_code_${c}`, c) }}
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div v-if="currencyRate !== 1" class="convert-amount">
                            <label> {{ ccy }} {{ goalInCampaignCurrency }} </label>
                          </div>
                        </div>
                        <b-form-text class="font-weight-bold" id="internal-goal-help">
                          <b-icon-question-circle-fill></b-icon-question-circle-fill>
                          {{
                            $t("dashboard.goal_help", "this amount should be your goal AFTER MATCH")
                          }}
                        </b-form-text>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="form-group form-unit form-unit--addon">
                        <label class="form-unit__label">
                          {{ $t("teams.bonus_goal", "Bonus goal") }}
                        </label>
                        <div class="form-unit__addon-wrap">
                          <div class="form-unit__addon-icon no-pointer">
                            {{ displayGoalCurrencyModel }}
                          </div>
                          <input
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            class="form-control form-unit__input"
                            :placeholder="$t('teams.bonus_goal', 'Bonus Goal')"
                            v-model.number="displayBonusGoalCurrencyAmountModel"
                          />
                          <div v-if="currencyRate !== 1" class="convert-amount">
                            <label> {{ ccy }} {{ bonusGoalInCampaignCurrency }} </label>
                          </div>
                        </div>
                        <b-form-text class="font-weight-bold" id="internal-goal-help">
                          <b-icon-question-circle-fill></b-icon-question-circle-fill>
                          {{
                            $t(
                              "dashboard.bonus_goal_help",
                              "bonus goal should be more then original one"
                            )
                          }}
                        </b-form-text>
                      </div>
                    </div>
                    <team-goal-categories
                      v-model="teamData.data.attributes.goal_categories"
                      :goal.sync="displayGoalCurrencyAmountModel"
                    />
                    <div class="wrapper-options">
                      <button
                        type="button"
                        @click="showOptionaly = !showOptionaly"
                        class="show-hide-bttn"
                        name="button"
                      >
                        {{
                          !showOptionaly
                            ? $t(
                                "dashboard.add_team_show_optionaly",
                                "Show additional goal options"
                              )
                            : $t(
                                "dashboard.add_team_hide_optionaly",
                                "Hide additional goal options"
                              )
                        }}
                        <i
                          :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                    <div class="col-12 col-md-6" v-if="showOptionaly">
                      <div class="form-group form-unit form-unit--addon">
                        <label class="form-unit__label">{{
                          $t("teams.internal_goal_text", "Goal")
                        }}</label>
                        <div class="form-unit__addon-wrap">
                          <div class="form-unit__addon-icon no-pointer">
                            {{ displayGoalCurrencyModel }}
                          </div>
                          <input
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            class="form-control form-unit__input"
                            :placeholder="$t('teams.goal')"
                            v-model.number="displayInternalGoalCurrencyAmountModel"
                            aria-describedby="internal-goal-help"
                          />
                          <div v-if="currencyRate !== 1" class="convert-amount">
                            <label> {{ ccy }} {{ internalGoalInCampaignCurrency }} </label>
                          </div>
                        </div>
                        <b-form-text class="font-weight-bold" id="internal-goal-help">
                          <b-icon-question-circle-fill></b-icon-question-circle-fill>
                          {{
                            $t(
                              "dashboard.internal_goal_help",
                              "Internal expectations of the team performance, not visible to the public"
                            )
                          }}
                        </b-form-text>
                      </div>
                    </div>
                    <div class="col-12 col-md-6" v-if="showOptionaly">
                      <div class="form-group form-unit form-unit--addon">
                        <label class="form-unit__label">{{
                          $t("teams.gonor_goal_text", "Donor Count Goal")
                        }}</label>
                        <div class="form-unit__addon-wrap">
                          <input
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            class="form-control form-unit__input"
                            :placeholder="$t('teams.goal', 'Donor goal')"
                            v-model.number="teamData.data.attributes.donor_goal"
                            aria-describedby="internal-goal-help"
                          />
                        </div>
                        <b-form-text class="font-weight-bold" id="internal-goal-help">
                          <b-icon-question-circle-fill></b-icon-question-circle-fill>
                          {{
                            $t(
                              "dashboard.gonor_goal_help",
                              "shown on the campaign page by meta. please set a goal for the number of donors you would like to reach."
                            )
                          }}
                        </b-form-text>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group form-unit form-unit--addon">
                        <label class="form-unit__label form-unit__label--required">{{
                          $t("teams.slug")
                        }}</label>
                        <div class="form-unit__addon-wrap">
                          <div class="form-unit__addon-icon form-unit__addon-wide no-pointer">
                            .../{{ shortlink }}/
                          </div>
                          <input
                            type="text"
                            class="form-control form-unit__input form-unit__input--slug"
                            v-model="teamData.data.attributes.slug"
                            required
                          />
                        </div>
                        <a class="form-unit__copy-link" @click="copySlug">
                          {{ `https://${domain}/${shortlink}/${teamData.data.attributes.slug}` }}
                        </a>
                      </div>
                    </div>
                    <div v-if="hasAccessAddCustomLinkToTeam" class="col-12">
                      <div class="form-group form-unit">
                        <label class="form-unit__label form-unit__label">{{
                          $t("teams.custom_link", "Custom link")
                        }}</label>
                        <div class="form-unit__input">
                          <input
                            type="text"
                            class="form-control form-unit__input"
                            v-model="teamData.data.attributes.custom_link"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group form-unit form-unit--multiselect">
                            <label class="form-unit__label">
                              {{ $t("teams.parent_team") }}
                              <span
                                v-b-tooltip.hover
                                :title="
                                  $t('teams.parent_team_tooltip_title', 'Community will be created')
                                "
                              >
                                <b-icon-question-circle-fill variant="info" />
                              </span>
                            </label>
                            <multiselect
                              v-model="parentTeamId"
                              :options="teamList"
                              label="text"
                              track-by="text"
                              :multiple="false"
                              :placeholder="$t('dashboard.select_parent_team')"
                              :searchable="true"
                              :internal-search="false"
                              @search-change="teamFind"
                              aria-describedby="parent-team-help"
                            ></multiselect>
                            <b-form-text class="font-weight-bold" id="parent-team-help">
                              <b-icon-question-circle-fill></b-icon-question-circle-fill>
                              {{ $t("dashboard.parent_team.help") }}
                            </b-form-text>
                          </div>
                        </div>
                        <div class="col-12" v-show="multiple_team_parent">
                          <div class="form-group form-unit form-unit--multiselect">
                            <label class="form-unit__label">{{
                              $t("dashboard.parent_team_2")
                            }}</label>
                            <multiselect
                              v-model="parentTeamId2"
                              :options="teamList"
                              label="text"
                              track-by="text"
                              :multiple="false"
                              :placeholder="$t('dashboard.select_parent_team_2')"
                              :searchable="true"
                              :internal-search="false"
                              @search-change="teamFind"
                              aria-describedby="parent-team-help"
                            ></multiselect>
                            <b-form-text class="font-weight-bold" id="parent-team-help">
                              <b-icon-question-circle-fill></b-icon-question-circle-fill>
                              {{ $t("dashboard.parent_team.help") }}
                            </b-form-text>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                    <div class="col-12">
                      <div class="form-group form-unit">
                        <label class="form-unit__label">{{ $t("teams.leader_name") }}</label>
                        <div
                          class="float-label"
                          :class="{ 'is-active': teamData.data.attributes.leader_name.length }"
                        >
                          <input
                            type="text"
                            class="form-control form-unit__input"
                            v-model="teamData.data.attributes.leader_name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="form-group form-unit">
                        <label class="form-unit__label">{{ $t("teams.leader_email") }}</label>
                        <input
                          type="text"
                          class="form-control form-unit__input"
                          v-model="teamData.data.attributes.leader_email"
                          aria-describedby="leader-email-help"
                        />
                      </div>
                      <b-form-text class="font-weight-bold" id="leader-email-help">
                        <b-icon-question-circle-fill></b-icon-question-circle-fill>
                        {{
                          $t(
                            "dashboard.group.help",
                            "This is for an optional feature to put teams in groups (Unidy template only). To use this, please contact your Campaign Manager or Tech Support"
                          )
                        }}
                      </b-form-text>
                      <br />
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="form-group form-unit">
                        <label class="form-unit__label">
                          <span>
                            {{ $t("teams.phone") }}
                          </span>
                          <b-icon
                            icon="info-circle"
                            v-b-popover.hover.top="$t('teams.phone_popover', '?')"
                          />
                        </label>
                        <VueTelInput
                          name="phone"
                          v-model="teamData.data.attributes.phone"
                          class="tw-h-[50px] tw-m-0 tw-flex tw-text-[#414359] tw-font-['VitoExtended-Light',sans-serif] tw-text-[15px] tw-shadow-none !tw-rounded tw-transition-all tw-duration-200 tw-border !tw-border-[#e6eaed] focus-within:tw-border-2 focus-within:!tw-border-[#0084af] focus-within:!tw-shadow-none hover:!tw-border-[#0084af]"
                          v-validate="{
                            twilio_lookup: twilioLookup,
                          }"
                          :inputOptions="{
                            styleClasses: `tw-m-0 tw-px-[20px] tw-h-full tw-w-full tw-text-[#414359] tw-font-['VitoExtended-Light',sans-serif] tw-text-[15px]`,
                          }"
                          mode="international"
                          data-vv-validate-on="blur"
                          valid-characters-only
                          @validate="validate"
                          :defaultCountry="defaultCountry"
                          :autoDefaultCountry="autoDefaultCountry"
                          aria-describedby="phone-help"
                        />
                        <small
                          v-if="twilioLookupErrorMsg"
                          class="tw-mt-1 tw-text-red-600 tw-font-[700] tw-text-[80%]"
                        >
                          <b-icon-exclamation-circle-fill />
                          {{ twilioLookupErrorMsg }}
                        </small>
                        <b-form-text class="font-weight-bold" id="phone-help">
                          <b-icon-question-circle-fill />
                          {{ $t("dashboard.phone.help", "used for SMS and WhatsApp") }}
                        </b-form-text>
                      </div>
                    </div>
                    <div class="wrapper-options">
                      <button
                        type="button"
                        @click="showOptionalyContact = !showOptionalyContact"
                        class="show-hide-bttn"
                        name="button"
                      >
                        {{
                          !showOptionaly
                            ? $t(
                                "dashboard.add_team_show_optionaly2",
                                "Show additional team contact details"
                              )
                            : $t(
                                "dashboard.add_team_hide_optionaly2",
                                "Hide additional team contact details"
                              )
                        }}
                        <i
                          :class="!showOptionalyContact ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                    <div class="col-12" v-show="showOptionalyContact">
                      <br />
                      <div class="row">
                        <div class="col-12">
                          <b-row>
                            <b-col cols="12" lg="6">
                              <team-extra-data
                                type="email"
                                v-model="teamData.data.attributes.extra_emails"
                              />
                            </b-col>
                            <b-col cols="12" lg="6">
                              <team-extra-data
                                type="phone"
                                v-model="teamData.data.attributes.extra_phones"
                              />
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <br />
                    </div>
                    <div class="col-12">
                      <p>
                        <strong>
                          {{ $t("dashboard.team_toggle_settings", "Settings: ") }}
                        </strong>
                      </p>
                    </div>
                    <div class="col-12">
                      <AppSwitchToggle
                        :label="$t('teams.show_switch_label')"
                        v-model="teamData.data.attributes.hidden"
                        :true-value="false"
                        :false-value="true"
                      />
                    </div>
                    <div class="col-12">
                      <AppSwitchToggle
                        :label="
                          $t(
                            'teams.featured_switch_label',
                            'Show Team as featured on the campaign page'
                          )
                        "
                        v-model="teamData.data.attributes.featured"
                      />
                    </div>
                    <div class="col-12">
                      <AppSwitchToggle
                        :label="$t('teams.percentage_view_switch_label')"
                        v-model="teamData.data.attributes.percentage_view"
                      />
                    </div>
                    <div class="wrapper-options">
                      <button
                        type="button"
                        @click="showOptionalySetting = !showOptionalySetting"
                        class="show-hide-bttn"
                        name="button"
                      >
                        {{
                          !showOptionalySetting
                            ? $t(
                                "dashboard.add_team_show_optionaly3",
                                "Show additional team settings"
                              )
                            : $t(
                                "dashboard.add_team_hide_optionaly3",
                                "Hide additional team settings"
                              )
                        }}
                        <i
                          :class="!showOptionalySetting ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                    <div class="col-6" v-show="showOptionalySetting">
                      <div class="form-group form-unit">
                        <label class="form-unit__label">{{ $t("teams.order") }}</label>
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control form-unit__input"
                          v-model.number="teamData.data.attributes.sort"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-lg-3" v-show="showOptionalySetting">
                      <div class="color-section form-group form-unit">
                        <h2 class="form-unit__label">{{ $t("edit_campaign.color_title") }}</h2>
                        <div class="color-section__inner">
                          <div class="color-section__unit">
                            <div
                              class="picker-block"
                              @click="pickerToggle"
                              :style="{ backgroundColor: teamData.data.attributes.color }"
                            >
                              <chrome-picker
                                v-show="opened"
                                v-model="teamData.data.attributes.color"
                                @input="updateTeamColor"
                                class="picker"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12" v-show="showOptionalySetting">
                      <div class="form-group form-unit">
                        <label class="form-unit__label" for>{{ $t("teams.group", "Group") }}</label>
                        <input
                          type="text"
                          class="form-control form-unit__input"
                          v-model="teamData.data.attributes.group"
                        />
                        <b-form-text class="font-weight-bold" id="parent-team-help">
                          <b-icon-question-circle-fill></b-icon-question-circle-fill>
                          {{
                            $t(
                              "dashboard.group.help",
                              "this is for an optional feature to put teams in groups (Unidy template only). To use this, please contact your Campaign Manager or Tech Support"
                            )
                          }}
                        </b-form-text>
                      </div>
                    </div>
                    <div class="col-12" v-show="showOptionalySetting">
                      <div class="form-group form-unit">
                        <label class="form-unit__label" for>{{
                          $t("teams.external_id", "External ID")
                        }}</label>
                        <input
                          type="text"
                          class="form-control form-unit__input"
                          v-model="teamData.data.attributes.external_id"
                        />
                        <b-form-text class="font-weight-bold" id="parent-team-help">
                          <b-icon-question-circle-fill></b-icon-question-circle-fill>
                          {{
                            $t(
                              "dashboard.external_id.help",
                              "This is for an optional feature to add an external ID to the team."
                            )
                          }}
                        </b-form-text>
                      </div>
                    </div>
                    <div class="col-12" v-show="showOptionalySetting">
                      <div
                        class="form-group form-unit form-unit--addon form-unit--addon-right date-time-select"
                      >
                        <label class="form-unit__label form-unit__label--upper-sm">{{
                          $t("teams.optional_goal_due_date")
                        }}</label>
                        <div class="form-unit__addon-wrap">
                          <div class="form-unit__addon-icon">
                            <IconDatetime />
                          </div>
                          <datetime
                            input-id="goal-due-date"
                            type="datetime"
                            v-model="goalDueDate"
                            :zone="timeZone"
                            value-zone="UTC"
                            input-class="form-control form-unit__input"
                          />
                          <div
                            v-if="teamData.data.attributes.goal_due_date"
                            class="form-unit__addon-icon form-unit__addon-icon--right form-unit__addon-icon--button"
                          >
                            <b-button
                              @click="teamData.data.attributes.goal_due_date = null"
                              variant="danger"
                            >
                              Clear
                            </b-button>
                          </div>
                        </div>
                        <small
                          v-if="!!goalDueDateError"
                          class="font-weight-bold text-danger form-text"
                        >
                          <b-icon-exclamation-circle />
                          {{ goalDueDateError }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab v-if="edit" :title="$t('teams.images_tab', 'Team Images')" lazy>
              <team-images :teamId="Number(teamId)" :edit="edit" />
            </b-tab>
            <b-tab :title="$t('teams.description_tab', 'Team description')">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <div class="form-unit__label">{{ $t("teams.description") }}</div>

                    <quill-editor
                      v-model="teamData.data.attributes.description"
                      ref="myQuillEditor"
                      :options="editorOption"
                    ></quill-editor>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('teams.email_tab', 'Email To Donor')">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <label class="form-unit__label">{{
                      $t("teams.paragraph_email", "Custom Paragraph In Email to Donors")
                    }}</label>
                    <textarea
                      class="form-unit__textarea"
                      v-model="teamData.data.attributes.paragraph_email"
                    ></textarea>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('teams.sub_accounts_tab', 'Sub-accounts')">
              <sub-accounts-view ref="SubAccountsViewRef" :teamId="teamId" />
            </b-tab>
            <b-tab v-if="showPagesTab && edit" :title="$t('teams.sefer_torah_pages', 'Pages')" lazy>
              <div class="row">
                <div class="col-12">
                  <team-page-list :team="teamPageList" orgarea />
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="showPinnedDonations"
              :title="$t('teams.pinned_donations', 'Pinned Donations')"
              lazy
            >
              <div class="row">
                <div class="col-12">
                  <TeamPinnedDonations
                    ref="TeamPinnedDonationsRef"
                    :team="teamPageList"
                    :campaignId="campaignId"
                    :orgId="orgId"
                  />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </form>
    </b-modal>
    <MediaStorageCropperModal :campaignTemplate="campaignTemplate" ref="MediaStorageCropperModal" />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Chrome from "vue-color/src/components/Chrome.vue";
import ClickOutside from "vue-click-outside";
import Multiselect from "vue-multiselect";
import debounce from "lodash/debounce";
import { BIconQuestionCircleFill } from "bootstrap-vue";
import subAccountsView from "@/components/subAccountsView.vue";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import { notificationsMixin } from "@/mixins";
import teamExtraData from "./team-extra-data.vue";
import teamImages from "./team-images.vue";
import MediaStorageCropperModal from "@/views/media-storage/MediaStorageCropperModal.vue";
import TeamGoalCategories from "./team-goal-categories.vue";
import TeamPageList from "@/views/donor-dashboard/sefer-torah/team-page-list.vue";
import AppSwitchToggle from "@/common-components/ui-elements/app-switch-toggle.vue";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";
import TeamPinnedDonations from "@/views/campaigns/campaign-edit-vue/subcomponents/team-pinned-donations.vue";
import CustomVideo from "@/components/vue-quill-editor/formats/custom-video";
import IconDatetime from "@/components/icon-datetime.vue";
import { VueTelInput } from "vue-tel-input";
import { defineComponent } from "vue";

const useGoalModel = (key) => ({
  get() {
    if (this.currencyRate === 1) {
      return this.teamData.data.attributes[key];
    }
    return this.teamData.data.attributes[`display_${key}_currency_amount`];
  },
  set(val) {
    if (!Number.isNaN(Number(val))) {
      if (this.currencyRate === 1) {
        this.teamData.data.attributes[key] = Number(val);
        this.teamData.data.attributes[`display_${key}_currency_amount`] = 0;
      } else {
        this.teamData.data.attributes[key] = Math.ceil(Number(val) * this.currencyRate);
        this.teamData.data.attributes[`display_${key}_currency_amount`] = Number(val);
      }
    } else {
      this.teamData.data.attributes[key] = val;
    }
  },
});

export default defineComponent({
  components: {
    error: error,
    chromePicker: Chrome,
    Multiselect,
    BIconQuestionCircleFill,
    subAccountsView,
    teamImages,
    teamExtraData,
    MediaStorageCropperModal,
    TeamGoalCategories,
    TeamPageList,
    AppSwitchToggle,
    TeamPinnedDonations,
    IconDatetime,
    VueTelInput,
  },
  props: {
    dashboard: {
      default: false,
    },
    currentpage: {
      default: 1,
    },
    searchvalue: {
      default: "",
    },
    shortlink: {
      default: "",
    },
    ccy: {
      default: "",
    },
  },
  mixins: [notificationsMixin],
  data() {
    return {
      opened: false,
      sending: false,
      errors: false,
      edit: false,
      teamId: 0,
      parentTeamId: null,
      parentTeamId2: null,
      teamData: {
        data: {
          attributes: {
            campaign_id: 0,
            parent_team_id: 0,
            parent_team_id2: 0,
            name: "",
            slug: "",
            description: "",
            goal: 0,
            bonus_goal: 0,
            internal_goal: 0,
            display_goal_currency: "",
            display_goal_currency_amount: 0,
            display_bonus_goal_currency_amount: 0,
            display_internal_goal_currency_amount: 0,
            donor_goal: 0,
            color: "",
            image: "",
            sort: 1,
            paragraph_email: "",
            leader_name: "",
            leader_email: "",
            phone: "",
            custom_link: "",
            extra_emails: [],
            extra_phones: [],
            goal_categories: [],
            goal_due_date: 0,
            external_id: "",
          },
        },
      },
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["image"],
              [CustomVideo.blotName],
              ["link"],
              ["clean"],
            ],
          },
        },
      },
      currencyRate: 1,
      showOptionaly: false,
      showOptionalyContact: false,
      showOptionalySetting: false,
      goalDueDateError: "",
      phoneObject: null,
    };
  },

  directives: {
    ClickOutside,
  },

  computed: {
    ...mapState({
      timeZone: (state) => {
        return state.account.account.data.attributes.timezone;
      },

      campaignId(state) {
        return this.dashboard ? state.dashboard.selectedCampaign : state.campaign.campaign.data.id;
      },

      dashboardCmp(state) {
        if (this.dashboard) {
          return state.campaigns.campaigns.find((el) => el.id === this.campaignId);
        }
        return false;
      },

      campaignTemplate: (s) => s.campaign.campaign.data.attributes.template,

      shortUrl(state) {
        if (this.dashboard) {
          return this.dashboardCmp.attributes.short_url;
        }
        if (state.campaign?.campaign?.data) {
          return state.campaign.campaign.data.attributes.short_url;
        }
      },

      campaignEndDate(state) {
        if (this.dashboard) {
          return this.dashboardCmp.attributes.end_date;
        }
        if (state.campaign?.campaign?.data) {
          return state.campaign.campaign.data.attributes.end_date;
        }
      },

      orgId() {
        return localStorage.getItem("orgId");
      },

      hasAccessEdit: (s) => s.account.access.CanEditCampaignTeams,
      hasAccessAdd: (s) => s.account.access.CanAddCampaignTeams,
      hasAccessAddCustomLinkToTeam: (s) => s.account.addCustomLinkToTeam,

      teamList(state) {
        let arrTeams = state.teams.allTeams;

        let resultArr = [];

        for (var i = 0; i < arrTeams.length; i++) {
          let objTeam = {
            value: arrTeams[i].id,
            text: `[${arrTeams[i].id}] ${arrTeams[i].attributes.name}`,
          };

          resultArr.push(objTeam);
        }

        return resultArr;
      },
      metas: (state) => state.campaign.metas,
      multiple_team_parent() {
        const meta = this.metas["multiple_team_parent"] || {};
        return meta.value || false;
      },
      featured_teams() {
        const meta = this.metas["featured_teams"] || {};
        return meta.value || false;
      },
      showPagesTab: (s) => s.seferTorahPages.active,
      currencies: (s) => s.account.currencyList?.map((el) => el.attributes.code) || [],
      showPinnedDonations: (s) => s.featureHide.showPinnedDonations,
      defaultCountry: (s) => s.account.userCountry,
    }),

    goalDueDate: {
      get() {
        if (!this.teamData.data.attributes.goal_due_date) {
          return "";
        }
        return new Date(this.teamData.data.attributes.goal_due_date * 1000).toISOString();
      },
      set(val) {
        if (val === "") {
          this.goalDueDateError = "";
          this.teamData.data.attributes.goal_due_date = null;
          return;
        }

        const date = new Date(val).getTime() / 1000;
        if (date > this.campaignEndDate) {
          this.teamData.data.attributes.goal_due_date = null;
          this.goalDueDateError = this.$t(
            "edit_campaign.generalinfo_goal_due_date_error",
            "goal due date cannot be after campaign expiration date"
          );
          return;
        }

        this.goalDueDateError = "";
        this.teamData.data.attributes.goal_due_date = date;
      },
    },

    teamPageList() {
      return {
        id: Number(this.teamId),
        ...this.teamData.data.attributes,
      };
    },

    domain() {
      return this.shortUrl || "www.charidy.com";
    },

    displayGoalCurrencyModel: {
      get() {
        return this.teamData.data.attributes.display_goal_currency;
      },
      set(val) {
        this.teamData.data.attributes.display_goal_currency = val.toLowerCase();
      },
    },

    displayGoalCurrencyAmountModel: useGoalModel("goal"),
    displayBonusGoalCurrencyAmountModel: useGoalModel("bonus_goal"),
    displayInternalGoalCurrencyAmountModel: useGoalModel("internal_goal"),

    goalInCampaignCurrency() {
      return this.teamData.data.attributes.goal;
    },
    bonusGoalInCampaignCurrency() {
      return this.teamData.data.attributes.bonus_goal;
    },
    internalGoalInCampaignCurrency() {
      return this.teamData.data.attributes.internal_goal;
    },

    autoDefaultCountry() {
      return this.defaultCountry === "N/A" || this.defaultCountry.length === 0;
    },
    twilioLookupErrorMsg() {
      if (!this.vErrorsHas("phone", "twilio_lookup")) {
        return "";
      }

      return this.$t("template.field_error_twilio_lookup", "Invalid or incorrect phone number");
    },
    twilioLookup() {
      return {
        valid: this.phoneObject?.valid || false,
      };
    },
  },

  watch: {
    parentTeamId() {
      if (this.parentTeamId) {
        this.teamData.data.attributes.parent_team_id = parseInt(this.parentTeamId.value);
      } else {
        this.teamData.data.attributes.parent_team_id = 0;
      }
    },
    parentTeamId2() {
      if (this.parentTeamId2) {
        this.teamData.data.attributes.parent_team_id2 = parseInt(this.parentTeamId2.value);
      } else {
        this.teamData.data.attributes.parent_team_id2 = 0;
      }
    },
    currencyRate() {
      const attrs = this.teamData.data.attributes;

      this.displayGoalCurrencyAmountModel =
        attrs.display_goal_currency_amount || this.goalInCampaignCurrency;
      this.displayBonusGoalCurrencyAmountModel =
        attrs.display_bonus_goal_currency_amount || this.bonusGoalInCampaignCurrency;
      this.displayInternalGoalCurrencyAmountModel =
        attrs.display_internal_goal_currency_amount || this.internalGoalInCampaignCurrency;
    },
  },

  methods: {
    updateCurrency(val) {
      this.displayGoalCurrencyModel = val;
      this.xchange();
    },

    copySlug() {
      const link = `https://${this.domain}/${this.shortlink}/${this.teamData.data.attributes.slug}`;
      navigator.clipboard.writeText(link).then(() => {
        this.$bvToast.toast(this.$t("teams.copy_slug_content"), {
          title: this.$t("teams.copy_slug_title"),
          variant: "success",
        });
      });
    },

    pickerToggle() {
      this.opened = true;
    },

    hidePickerColor(e) {
      if (!e.target.closest(".picker-block")) {
        this.opened = false;
      }
    },

    updateTeamColor(color) {
      this.teamData.data.attributes.color = color.hex8;
    },

    openModal() {
      this.updateCurrency(this.ccy);
      this.$root.$emit("bv::show::modal", "team-form");
    },

    teamFind: debounce(function (q) {
      this.getTeams(q);
    }, 500),

    getTeams(query) {
      const params = {
        q: query ? query : undefined,
      };
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignId, params, true]);
    },

    openEditModal(team) {
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignId]);

      this.edit = true;
      this.teamId = team.id;
      this.teamData.data.attributes = JSON.parse(JSON.stringify(team.attributes));

      this.$root.$emit("bv::show::modal", "team-form");

      this.setActiveTeam();
      this.updateCurrency(team.attributes.display_goal_currency || this.ccy);
    },

    setActiveTeam() {
      let selectedTeam = this.teamList.filter((el) => {
        return el.value == this.teamData.data.attributes.parent_team_id;
      });
      let selectedTeam2 = this.teamList.filter((el) => {
        return el.value == this.teamData.data.attributes.parent_team_id2;
      });

      if (selectedTeam.length > 0) {
        this.parentTeamId = selectedTeam[0];
      } else {
        this.parentTeamId = null;
      }

      if (selectedTeam2.length > 0) {
        this.parentTeamId2 = selectedTeam2[0];
      } else {
        this.parentTeamId2 = null;
      }
    },

    clearData() {
      this.edit = false;
      this.sending = false;

      this.teamData.data.attributes = {
        campaign_id: 0,
        parent_team_id: 0,
        parent_team_id2: 0,
        name: "",
        slug: "",
        description: "",
        goal: 0,
        internal_goal: 0,
        donor_goal: 0,
        bonus_goal: 0,
        display_goal_currency: "",
        display_goal_currency_amount: 0,
        display_bonus_goal_currency_amount: 0,
        display_internal_goal_currency_amount: 0,
        color: "",
        image: "",
        sort: 0,
        paragraph_email: "",
        leader_name: "",
        leader_email: "",
        phone: "",
        custom_link: "",
        extra_emails: [],
        extra_phones: [],
        goal_categories: [],
      };

      this.teamId = 0;
      this.parentTeamId = null;
      this.parentTeamId2 = null;
      this.currencyRate = 1;
    },

    modalClose() {
      this.sending = false;
      this.$root.$emit("bv::hide::modal", "team-form");
    },

    saveForm() {
      this.sending = false;

      this.$validator.validate().then((valid) => {
        if (!valid) {
          return;
        }

        if (this.edit) {
          this.editTeam()
            .then(() => {
              this.getCampaignById();
            })
            .then(this.$refs?.TeamPinnedDonationsRef?.$saveSelectedPinnedDonations)
            .then(this.$refs?.SubAccountsViewRef?.$createAndEdit)
            .then(() => {
              this.$_notificationsMixin_makeToast(
                "Success!",
                this.$t("edit_campaign.success_edit"),
                "success"
              );
              this.modalClose();
            });

          return;
        }

        this.addNewTeam();
      });
    },

    getCampaignById() {
      const params = {
        page: this.currentpage,
        limit: 50,
        q: this.searchvalue,
        extend: ["stats"],
      };

      return this.$store.dispatch("getTeamsByCampaignId", [this.campaignId, params]);
    },

    formatData() {
      const attrs = this.teamData.data.attributes;

      attrs.campaign_id = Number(this.campaignId);
      attrs.description = unwrapContentValue(attrs.description);
      attrs.sort = Number(attrs.sort) || 0;
      attrs.bonus_goal = Number(attrs.bonus_goal) || 0;
      attrs.extra_emails = attrs.extra_emails?.filter((e) => e) || [];
      attrs.extra_phones = attrs.extra_phones?.filter((e) => e) || [];

      this.teamData.data.attributes = attrs;
    },

    addNewTeam() {
      this.sending = true;
      this.errors = [];

      this.formatData();

      if (Number(this.campaignId) === 0) {
        throw new Error("addNewTeam campaign id is 0");
      }

      return axios
        .post(
          urls.apiTeams.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId),
          this.teamData
        )
        .then(() => {
          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("edit_campaign.success_edit"),
            "success"
          );

          const params = {
            page: this.currentpage,
            limit: 50,
            q: this.searchvalue,
            extend: ["stats"],
          };
          this.$store.dispatch("getTeamsByCampaignId", [this.campaignId, params]);

          this.modalClose();
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
          throw e;
        })
        .finally(() => (this.sending = false));
    },

    editTeam() {
      this.sending = true;
      this.errors = [];

      this.formatData();

      return axios
        .put(
          urls.editTeams.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId) +
            "/" +
            this.teamId,
          this.teamData
        )
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
          throw e;
        })
        .finally(() => (this.sending = false));
    },

    deleteImage() {
      this.teamData.data.attributes.image = "";
    },

    changeImage() {
      this.load = true;

      this.errors = [];

      let fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
      fileInput.classList.add("load-image-input");

      fileInput.addEventListener("change", () => {
        if (fileInput.files != null && fileInput.files[0] != null) {
          this.$refs.MediaStorageCropperModal.open({
            file: fileInput.files[0],
            tag: "team_default_avatar",
          });
          this.$refs.MediaStorageCropperModal.onOK((blob) => {
            let form_data = new FormData();
            form_data.append("file", blob);

            axios
              .post(urls.loadImageQuill.replace(":OrgId", this.orgId), form_data)
              .then((response) => {
                this.load = false;

                this.teamData.data.attributes.image = response.data.src;

                fileInput.remove();
              })
              .catch((e) => {
                this.load = false;
                if (e.response.data) {
                  if (e.response.data.error) {
                    this.errors.push(e.response.data.error);
                  } else {
                    this.errors.push(this.$t("template.error_default"));
                  }
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              });
          });
        }
      });

      document.querySelector("body").appendChild(fileInput);

      fileInput.click();
    },

    onActivateTab(/* newTabIndex, prevTabIndex, bvEvent */) {
      // TODO: try catch not working when field is not visible
      // const formValid = this.$refs.TeamAdd.reportValidity();
      // if (!formValid) {
      //   bvEvent.preventDefault();
      // }
    },

    xchange() {
      if (this.displayGoalCurrencyModel === this.ccy) {
        this.currencyRate = 1;
        return;
      }

      return axios
        .get(
          urls.getConversionAmount
            .replace(":amount", 1)
            .replace(":from", this.displayGoalCurrencyModel)
            .replace(":to", this.ccy)
        )
        .then(({ data }) => {
          this.currencyRate = data.data.attributes.amount_out;
        });
    },

    validate(phoneObject) {
      this.phoneObject = phoneObject;
    },
    vErrorsHas(field, rule) {
      return this.vErrors.items.find((el) => el.field === field && el.rule === rule);
    },
  },
});
</script>

<style lang="scss">
.quill-editor .ql-container {
  height: 200px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  background: #fff;
}

.ql-toolbar.ql-snow {
  background: #fff;
}

.form-unit__copy-link:not([href]) {
  cursor: pointer;
  color: $bb-70;
  overflow-wrap: break-word;
  &:hover {
    text-decoration: underline;
    color: $bb-70;
  }
}

#team-form {
  .form-unit__addon-wide {
    width: 35% !important;
    @media only screen and (min-width: 768px) {
      width: 35% !important;
    }
    max-width: 35% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    display: inline-block;
    padding-top: 2.5%;
  }
  .form-unit__input--slug {
    width: 65%;
    margin-bottom: 10px;
  }
  .ql-editor {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    strong {
      font-family: "ProximaNova-Bold";
    }
  }
  .form-unit__textarea {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
  }
  .no-pointer {
    cursor: default !important;
  }
}

.form-unit__label {
  display: inline-block;
}

.color-section {
  margin-bottom: 40px;
  &__inner {
    display: flex;
  }
  &__unit {
    width: 300px;
  }
}
.picker-block {
  height: 50px;
  width: 100%;
  margin: 0;
  padding: 0 20px;
  display: block;
  color: #20434e;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid rgba(32, 67, 78, 0.15);
  box-shadow: none;
  border-radius: 4px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  .picker {
    display: block;
  }
}

.picker {
  display: none;
  position: absolute;
  top: 102%;
  left: 0;
  z-index: 2;
}

.convert-amount {
  padding: 0 15px;
  display: flex;
  align-items: center;
  label {
    white-space: nowrap;
    margin: 0;
    text-transform: uppercase;
  }
}
</style>
