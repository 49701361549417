<!-- eslint-disable no-unused-vars -->
<script setup>
import { nanoid } from "nanoid";
import { computed, getCurrentInstance, ref } from "vue";
import IconAuctionModalClose from "./sharedComponents/iconAuctionModalClose.vue";
import { useProtectedCampaignGift } from "./compositions/campaign-gift";
import IconAuctionModalMinus from "./sharedComponents/iconAuctionModalMinus.vue";
import IconAuctionModalPlus from "./sharedComponents/iconAuctionModalPlus.vue";
import { notificationsMixin } from "@/mixins";
import { donateHost } from "@/constants/urls.js";

const vm = getCurrentInstance();
const id = nanoid();
const MODAL_ID = `claim-gift-modal-${id}`;
const MODAL_FORM_ID = `claim-gift-modal-form-${id}`;
const MODAL_CONFIRM_ID = `claim-gift-modal-confirm-${id}`;

const intlDecimalFormatter = (number) =>
  new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number || 0);

const intlCurrencyFormatter = (number, currency) =>
  currency
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
      }).format(number || 0)
    : (number || 0).toLocaleString();

const notificationsMixinHandleCatch =
  notificationsMixin.methods.$_notificationsMixin_handleCatch.bind(vm.proxy);

const props = defineProps({
  campaignId: {
    type: [Number, Object],
    default: null,
  },
  campaignCurrency: {
    type: [String, Object],
    default: null,
  },
  teamId: {
    type: [Number, Object],
    default: null,
  },
  meta: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits(["submit", "checkout", "hide"]);

const addMorePoints = ref(false);

const loading = ref(false);
function formLoading(value) {
  loading.value = value;
}

const {
  items: campaignGifts,
  calculatedPointsTotal,
  teamGiftPointsPurchased,
  loading: loadingCampaignGifts,
  fetchProtectedCampaignGiftList,
  fetchProtectedTeamUserStatList,
} = useProtectedCampaignGift();

const selectedGifts = ref([]);
const selectedGiftsKeys = computed(() => selectedGifts.value.map((el) => el.ID));

function getSelectedGift(campaignGift) {
  return selectedGifts.value.find((el) => el.ID === campaignGift.ID);
}

function onClickSelectedGifts(campaignGift) {
  const selectedGift = getSelectedGift(campaignGift);
  const selectedGiftsIndex = selectedGifts.value.indexOf(selectedGift);
  if (selectedGift) {
    const withoutSelectedGift = [...selectedGifts.value];
    withoutSelectedGift.splice(selectedGiftsIndex, 1);
    selectedGifts.value = withoutSelectedGift;
  } else {
    selectedGifts.value = [...selectedGifts.value, campaignGift];
  }
}

function takenSelectedGiftsCount(campaignGift) {
  return selectedGifts.value.filter((el) => el.ID === campaignGift.ID).length;
}

function onClickRemoveTakenSelectedGift(campaignGift) {
  const selectedGift = getSelectedGift(campaignGift);
  const selectedGiftsIndex = selectedGifts.value.indexOf(selectedGift);
  if (!selectedGift) {
    return;
  }

  const withoutSelectedGift = [...selectedGifts.value];
  withoutSelectedGift.splice(selectedGiftsIndex, 1);
  selectedGifts.value = withoutSelectedGift;
}

function onClickTakenSelectedGift(campaignGift) {
  selectedGifts.value = [...selectedGifts.value, campaignGift];
}

const walletBalance = computed(() => {
  const initialPoints = calculatedPointsTotal.value;
  const selectedGiftsPoints = Object.values(selectedGifts.value).reduce((acc, curr) => {
    return acc + curr.points;
  }, 0);

  return initialPoints - selectedGiftsPoints;
});

const maxPurchasePoints = computed(() => {
  return props.meta?.max_purchasable_points || 0;
});

const walletDifference = computed(() => {
  if (walletBalance.value > 0) {
    return 0;
  }

  const balance = Math.ceil(Math.abs(walletBalance.value));
  if (balance + teamGiftPointsPurchased.value > maxPurchasePoints.value) {
    return -1;
  }

  return balance;
});

const amountToPay = computed(() => {
  const costPerPoint = props.meta?.cost_per_point || 1;
  return Math.ceil(walletDifference.value * costPerPoint);
});

const iframeLink = () => {
  const campaignId = props.campaignId;
  const params = new URLSearchParams("");
  params.set("team_id", props.teamId);
  params.set("amount", amountToPay.value);
  params.set("currency", props.campaignCurrency);
  params.set("readonly", "1");
  params.set("team_readonly", "1");
  params.set("campaign_auction_points", walletDifference.value);

  return `${donateHost}/${campaignId}?${params.toString()}`;
};

async function show() {
  if (!props.campaignId) return;
  if (!props.teamId) return;

  try {
    await fetchProtectedCampaignGiftList(props.campaignId, {});
    await fetchProtectedTeamUserStatList(props.teamId, {});
    vm.proxy.$bvModal.show(MODAL_ID);
  } catch (e) {
    notificationsMixinHandleCatch(e);
  }
}

function hide() {
  vm.proxy.$bvModal.hide(MODAL_ID);
  vm.proxy.$bvModal.hide(MODAL_CONFIRM_ID);
  clearData();
  emits("hide");
}

function goToConfirm() {
  vm.proxy.$bvModal.show(MODAL_CONFIRM_ID);
  vm.proxy.$bvModal.hide(MODAL_ID);
}

function goBack() {
  vm.proxy.$bvModal.hide(MODAL_CONFIRM_ID);
  vm.proxy.$bvModal.show(MODAL_ID);
}

function checkout() {
  if (selectedGifts.value.length === 0) {
    return;
  }

  if (addMorePoints.value) {
    const url = iframeLink();

    vm.proxy.$bvModal.hide(MODAL_ID);
    emits(
      "checkout",
      {
        show: () => {
          vm.proxy.$bvModal.show(MODAL_ID);
        },
        goToConfirm,
      },
      url
    );

    return;
  }

  goToConfirm();
}

function completeGiftClaim() {
  formLoading(true);
  emits("submit", { hide, formLoading }, selectedGifts.value);
}

function clearData() {
  formLoading(false);
  selectedGifts.value = [];
  addMorePoints.value = false;
}
</script>

<template>
  <div>
    <slot :show="show" :loading="loadingCampaignGifts" />
    <b-modal
      :id="MODAL_ID"
      size="xl"
      scrollable
      centered
      dialog-class="max-[576px]:!tw-m-0 max-[576px]:!tw-max-h-full max-[576px]:!tw-min-h-full"
      content-class="tw-px-[29.1px] tw-py-[39.58px] lg:tw-px-[82px] lg:tw-py-[52px] !tw-rounded-t-[8px] !tw-rounded-b-none lg:!tw-rounded-[8px]"
      header-class="!tw-bg-none !tw-bg-white !tw-p-0"
      footer-class="!tw-pb-0 !tw-px-0 !tw-pt-[25.61px] lg:!tw-pt-[17px] !tw-border-t-[0.7px] !tw-border-[#B6B6B6]"
      body-class="!tw-pt-0 !tw-pb-0 !tw-px-0"
    >
      <template #modal-header>
        <h2 class="tw-font-black tw-font-lato tw-text-[25px] tw-text-[#0A8EFF]">
          {{ $t("donor_dashboard.donor_auction_campaigns_choose_your_gift", "Choose Your Gift") }}
        </h2>
        <button @click="hide()" class="tw-w-[11px] tw-h-[11px] tw-bg-transparent">
          <IconAuctionModalClose />
        </button>
      </template>
      <template #modal-footer>
        <div
          class="!tw-grid !tw-m-0 !tw-items-[unset] !tw-justify-[unset] tw-w-full tw-grid-cols-1 tw-grid-rows-2 tw-grid-flow-row lg:tw-grid-cols-4 lg:tw-grid-rows-1 lg:tw-grid-flow-col lg:tw-space-x-[7px]"
        >
          <div
            class="tw-pb-[34.92px] lg:tw-mb-0 tw-col-span-1 tw-space-y-[10px] tw-border-b-[0.7px] tw-border-[#B6B6B6] lg:tw-border-b-0"
          >
            <p class="tw-mb-0 tw-grid tw-grid-cols-4 lg:tw-grid-cols-2">
              <span
                class="tw-col-span-3 lg:tw-col-span-1 tw-font-lato tw-font-light tw-text-[15px] tw-text-[#313131]"
              >
                {{
                  $t(
                    "donor_dashboard.donor_auction_campaigns_modal_footer_total_points",
                    "Total Points:"
                  )
                }}
              </span>
              <span
                class="tw-col-span-1 tw-font-lato tw-font-light tw-text-[15px] tw-text-[#313131]"
              >
                {{
                  $t(
                    "donor_dashboard.donor_auction_campaigns_claimed_gift_points",
                    "{points} pts",
                    {
                      points: intlDecimalFormatter(calculatedPointsTotal),
                    }
                  )
                }}
              </span>
            </p>
            <p class="tw-mb-0 tw-grid tw-grid-cols-4 lg:tw-grid-cols-2">
              <span
                class="tw-col-span-3 lg:tw-col-span-1 tw-font-lato tw-font-light tw-text-[15px] tw-text-[#313131]"
              >
                {{
                  $t(
                    "donor_dashboard.donor_auction_campaigns_modal_footer_waller_balance",
                    "Wallet Balance:"
                  )
                }}
              </span>
              <span
                class="tw-col-span-1 tw-font-lato tw-font-light tw-text-[15px] tw-text-[#313131]"
              >
                {{
                  $t(
                    "donor_dashboard.donor_auction_campaigns_claimed_gift_points",
                    "{points} pts",
                    {
                      points: intlDecimalFormatter(walletBalance),
                    }
                  )
                }}
              </span>
            </p>
          </div>
          <div class="tw-px-[10.74px] lg:tw-col-span-2 lg:tw-justify-self-end lg:tw-max-w-[195px]">
            <label
              v-if="walletDifference > 0"
              class="tw-font-lato tw-font-black tw-text-[13px] tw-text-[#313131]"
            >
              <input type="checkbox" v-model="addMorePoints" class="tw-mr-[10px]" />
              {{
                $t(
                  "donor_dashboard.donor_auction_campaigns_modal_add_points_to_complete_purchase",
                  "Add {points} points for {amount} to complete purchase.",
                  {
                    points: intlDecimalFormatter(walletDifference),
                    amount: intlCurrencyFormatter(amountToPay, campaignCurrency),
                  }
                )
              }}
            </label>
            <label
              v-else-if="walletDifference === -1"
              class="tw-font-lato tw-font-black tw-text-[13px] tw-text-red-500"
            >
              {{
                $t(
                  "donor_dashboard.donor_auction_campaigns_modal_max_purchasable_points",
                  "You exceeded the maximum purchasable points ({max}).",
                  {
                    max: intlDecimalFormatter(maxPurchasePoints),
                  }
                )
              }}
            </label>
          </div>
          <div
            class="lg:tw-col-span-1 tw-flex tw-items-center lg:tw-justify-center tw-space-x-[7px]"
          >
            <button
              @click="hide()"
              class="tw-bg-white tw-w-full lg:tw-w-auto tw-text-[#667085] tw-font-lato tw-font-regular tw-border tw-border-[#EAECF0] tw-text-[14px] tw-px-[27px] tw-py-[14px] tw-rounded-full"
            >
              {{ $t("donor_dashboard.donor_auction_campaigns_modal_cancel", "Cancel") }}
            </button>
            <button
              type="submit"
              :form="MODAL_FORM_ID"
              :disabled="
                !addMorePoints && (walletDifference > 0 || selectedGifts.length === 0 || loading)
              "
              class="tw-bg-[#0A8EFF] tw-w-full lg:tw-w-auto tw-text-white tw-font-lato tw-font-regular tw-text-[14px] tw-px-[27px] tw-py-[14px] tw-rounded-full disabled:tw-grayscale"
            >
              {{ $t("donor_dashboard.donor_auction_campaigns_modal_checout", "Checkout") }}
            </button>
          </div>
        </div>
      </template>
      <form :id="MODAL_FORM_ID" @submit.prevent="checkout" class="tw-w-full tw-h-full">
        <div
          class="tw-grid tw-grid-cols-2 tw-gap-[25.56px] lg:tw-grid-cols-3 xl:tw-grid-cols-4 lg:tw-gap-[29px] tw-pt-[33.76px] tw-pb-[34.91px] lg:tw-pt-[52px] lg:tw-pb-[39px]"
          v-if="campaignGifts.length > 0"
        >
          <div
            v-for="campaignGift in campaignGifts"
            :key="`claim-gift-modal-gift-${campaignGift.ID}`"
            @click="onClickSelectedGifts(campaignGift)"
            class="tw-relative tw-w-full tw-cursor-pointer tw-flex tw-flex-col tw-border-[1.42px] tw-rounded-[7px] tw-px-[22px] tw-py-[16.84px] lg:tw-px-[34px] lg:tw-py-[19px]"
            :class="{
              'tw-border-[#0A8EFF]': !!getSelectedGift(campaignGift),
              'tw-border-[#D9D9D9]': !!!getSelectedGift(campaignGift),
            }"
          >
            <img
              v-if="campaignGift.image"
              :src="campaignGift.image"
              :alt="campaignGift.title"
              width="140"
              height="140"
              class="tw-w-[87px] tw-h-[87px] xl:tw-w-[140px] xl:tw-h-[140px] tw-rounded-full tw-object-cover tw-mb-[16.13px] lg:tw-mb-[25.87px]"
            />
            <div
              v-else
              aria-roledescription="image placeholder"
              class="tw-w-[87px] tw-h-[87px] xl:tw-w-[140px] xl:tw-h-[140px] tw-rounded-full tw-bg-gray-300 tw-mb-[16.13px] lg:tw-mb-[25.87px]"
            />
            <span
              class="tw-w-full tw-text-[10px] lg:tw-text-[16px] tw-font-normal tw-font-lato tw-text-[#313131] tw-mb-[11.12px] lg:tw-mb-[17.83px]"
            >
              {{ campaignGift.title }}
            </span>
            <div class="tw-w-full tw-self-end tw-mt-auto">
              <div class="tw-bg-[#B6B6B6] tw-h-[1px] tw-mb-[11.12px] lg:tw-mb-[19.17px]" />
              <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
                <span
                  class="tw-text-[8px] lg:tw-text-[13px] tw-font-black tw-font-lato tw-text-[#313131]"
                >
                  {{
                    $t("donor_dashboard.donor_auction_campaigns_gift_points", "{points} Pts", {
                      points: campaignGift.points,
                    })
                  }}
                </span>
                <div class="tw-flex tw-items-center tw-space-x-[5.78px] lg:tw-space-x-[7px]">
                  <button
                    type="button"
                    @click.stop="onClickRemoveTakenSelectedGift(campaignGift)"
                    :disabled="!selectedGiftsKeys.includes(campaignGift.ID)"
                    class="tw-w-[13px] tw-h-[13px] lg:tw-w-[23px] lg:tw-h-[23px] tw-flex tw-items-center tw-justify-center tw-bg-[#E1E1E1] tw-rounded-[1px]"
                  >
                    <IconAuctionModalMinus
                      class="tw-w-[11px] tw-h-[11px] lg:tw-w-[13px] lg:tw-h-[13px]"
                    />
                  </button>
                  <span class="tw-text-[7px] tw-font-medium tw-font-lato tw-text-[#37474F]">
                    {{ takenSelectedGiftsCount(campaignGift) }}
                  </span>
                  <button
                    type="button"
                    @click.stop="onClickTakenSelectedGift(campaignGift)"
                    :disabled="selectedGifts.length === campaignGift.quantity"
                    class="tw-w-[13px] tw-h-[13px] lg:tw-w-[23px] lg:tw-h-[23px] tw-flex tw-items-center tw-justify-center tw-bg-[#E1E1E1] tw-rounded-[1px]"
                  >
                    <IconAuctionModalPlus
                      class="tw-w-[11px] tw-h-[11px] lg:tw-w-[13px] lg:tw-h-[13px]"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="tw-flex tw-justify-center tw-items-center">
          <div class="tw-text-[18px] tw-font-lato tw-font-regular tw-text-[#313131]">
            {{
              $t("donor_dashboard.donor_auction_campaigns_no_available_gifts", "No available gifts")
            }}
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal
      :id="MODAL_CONFIRM_ID"
      size="md"
      scrollable
      centered
      dialog-class="max-[576px]:!tw-m-0 max-[576px]:!tw-max-h-full max-[576px]:!tw-min-h-full"
      content-class="tw-px-[29.47px] tw-pt-[28px] tw-pb-[47.7px] !tw-rounded-t-[8px] !tw-rounded-b-none lg:!tw-rounded-[8px]"
      header-class="!tw-bg-none !tw-bg-white !tw-p-0"
      footer-class="!tw-pb-0 !tw-px-0 !tw-pt-[25.61px] lg:!tw-pt-[17px] !tw-border-t-0"
      body-class="!tw-pt-[36px] !tw-pb-[41.4px] !tw-px-[36px]"
    >
      <template #modal-header>
        <button @click="hide()" class="tw-ms-auto tw-w-[11px] tw-h-[11px] tw-bg-transparent">
          <IconAuctionModalClose />
        </button>
      </template>
      <template #modal-footer>
        <div class="lg:tw-col-span-1 tw-flex tw-items-center !tw-justify-center tw-space-x-[7px]">
          <button
            @click="goBack()"
            class="tw-bg-white tw-w-full tw-text-[#667085] tw-font-lato tw-font-regular tw-border tw-border-[#EAECF0] tw-text-[14px] tw-px-[27px] tw-py-[14px] tw-rounded-full"
          >
            {{ $t("donor_dashboard.donor_auction_campaigns_modal_cancel", "Cancel") }}
          </button>
          <button
            type="button"
            @click="completeGiftClaim"
            :disabled="loading"
            class="tw-bg-[#0A8EFF] tw-w-full tw-text-white tw-font-lato tw-font-regular tw-text-[14px] tw-px-[27px] tw-py-[14px] tw-rounded-full disabled:tw-grayscale"
          >
            {{ $t("donor_dashboard.donor_auction_campaigns_modal_confirm", "Confirm") }}
          </button>
        </div>
      </template>
      <div class="tw-flex tw-justify-center tw-items-center">
        <div class="tw-text-[13px] tw-font-lato tw-font-regular text-center tw-text-[#313131]">
          {{
            $t(
              "donor_dashboard.donor_auction_campaigns_confirm_tip",
              "By clicking confirm, your selected gift claim is complete and cannot be canceled."
            )
          }}
        </div>
      </div>
    </b-modal>
  </div>
</template>
