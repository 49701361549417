export default {
  title: "NotificationDonationVue",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    hide_projector: {
      title: "Hide projector",
      type: "boolean",
      default: false,
    },
    givingday_donations: {
      title: "Givingday donations",
      type: "boolean",
      default: false,
    },
    background_color: {
      title: "Background color",
      type: "color",
      default: "#000000",
    },
    text_color: {
      title: "Text color",
      type: "color",
      default: "#ffffff",
    },
    duration: {
      title: "Duration",
      type: "number",
      default: 60,
    },
  },
};
