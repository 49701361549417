<template>
  <SettingModal :baseSettingsItem="baseSettingsItem">
    <template #content="{ hide }">
      <div>
        <b-overlay class="tw-flex tw-flex-col" :show="loading">
          <div class="tw-ml-auto tw-inline-flex tw-gap-1">
            <button
              :disabled="loading"
              class="bttn bttn--blue tw-w-auto !tw-px-3"
              @click="fetchPromoCodes()"
            >
              <b-icon-arrow-clockwise />
              <span class="tw-sr-only" aria-hidden="true">
                {{ $t("account.reload_promo_codes", "Reload Promo Codes") }}
              </span>
            </button>
            <button class="bttn bttn--blue" @click="showModal">
              {{ $t("account.generate_promo_codes", "Generate Promo Codes") }}
            </button>
          </div>

          <div class="promocode-wrap" v-if="promoCodeList.length">
            <div class="promocode-row" v-for="(code, index) in promoCodeList" :key="index">
              <div class="promocode-row__code">
                <span>
                  {{ code.attributes.code }}
                </span>
                <button
                  type="button"
                  class="bttn-round btn-copy-promocode"
                  name="button"
                  @click="copy(code)"
                >
                  <b-icon-clipboard-data color="currentColor" />
                </button>
              </div>

              <div class="promocode-row__discount">{{ code.attributes.discount }}%</div>

              <div class="promocode-row__status">
                <span
                  :class="{
                    'text-success': !code.attributes.used,
                    'text-danger': code.attributes.used,
                  }"
                >
                  {{
                    code.attributes.used
                      ? $t("account.used", "Used")
                      : $t("account.available", "Available")
                  }}
                </span>
              </div>

              <div class="promocode-row__controls">
                <button
                  :disabled="loading"
                  type="button"
                  class="bttn-round btn-remove-promocode"
                  name="button"
                  @click="deletePromoCode(code)"
                >
                  <b-icon-trash color="currentColor" />
                </button>
              </div>
            </div>
          </div>
          <div v-else class="promocode-wrap promocode-empty">
            {{ $t("account.no_promo_codes", "No promo codes") }}
          </div>
        </b-overlay>

        <!-- Generate Promo Codes Modal -->
        <b-modal id="generatePromoCodesModal" @hidden="resetForm">
          <template #modal-header>
            <h2>
              {{ $t("account.generate_promo_codes", "Generate promo codes") }}
            </h2>
          </template>
          <template #modal-footer>
            <div>
              <button type="button" class="bttn bttn--lg bttn--blue" @click="hideModal()">
                {{ $t("account.cancel") }}
              </button>
              <button
                :disabled="loading"
                type="submit"
                class="bttn bttn--lg bttn--orange"
                form="generatePromoCodesModalForm"
              >
                {{ $t("account.save") }}
              </button>
            </div>
          </template>

          <b-form id="generatePromoCodesModalForm" @submit.prevent="onSubmit">
            <b-form-group
              :label="$t('account.number_of_codes', 'Number of codes')"
              label-for="numberOfCodes"
            >
              <b-form-input
                id="numberOfCodes"
                v-model="form.numberOfCodes"
                type="number"
                min="1"
                max="10"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('account.discount_percentage', 'Discount percentage')"
              label-for="discountPercentage"
            >
              <b-form-input
                id="discountPercentage"
                v-model="form.discountPercentage"
                type="number"
                min="0"
                max="100"
                required
              ></b-form-input>
            </b-form-group>
          </b-form>
        </b-modal>
        <button
          type="button"
          class="bttn bttn--sm bttn--blue tw-me-auto tw-mt-7"
          @click="hide"
          name="button"
        >
          {{ $t("edit_campaign.setting_close", "Close") }}
        </button>
      </div>
    </template>
  </SettingModal>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";
import SettingModal from "@/views/campaigns/campaign-settings/settingModal.vue";

export default {
  mixins: [notificationsMixin],

  components: {
    SettingModal,
  },

  props: {
    baseSettingsItem: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      loading: false,
      promoCodeList: [],
      form: {
        numberOfCodes: 1,
        discountPercentage: 100,
      },
    };
  },

  created() {
    this.fetchPromoCodes();
  },

  computed: {
    ...mapState({
      orgId: () => localStorage.getItem("orgId"),
      campaignId: (state) => state.campaign.campaign.data.id,
    }),
  },

  methods: {
    fetchPromoCodes() {
      this.loading = true;
      axios
        .get(urls.promoCodeList(this.orgId, this.campaignId))
        .then((response) => {
          this.promoCodeList = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    showModal() {
      this.$bvModal.show("generatePromoCodesModal");
    },

    hideModal() {
      this.$bvModal.hide("generatePromoCodesModal");
    },

    onSubmit() {
      const payload = {
        data: {
          id: "0",
          type: "generate_requests",
          attributes: {
            count: parseFloat(this.form.numberOfCodes),
            discount: parseFloat(this.form.discountPercentage),
          },
        },
      };

      this.loading = true;
      axios
        .post(urls.promoCode(this.orgId, this.campaignId), payload)
        .then(() => {
          this.$bvModal.hide("generatePromoCodesModal");
          this.fetchPromoCodes();
          this.$_notificationsMixin_makeToast("Success", this.$t("account.promo_codes_generated"));
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    deletePromoCode(code) {
      this.$dialog
        .confirm(this.$t("account.confirm_delete_promo_code"), {
          okText: this.$t("account.confirm_delete"),
          cancelText: this.$t("account.confirm_cancel"),
        })
        .then(() => {
          this.loading = true;
          axios
            .delete(urls.promoCodeById(this.orgId, this.campaignId, code.id))
            .then(() => {
              this.fetchPromoCodes();
              this.$_notificationsMixin_makeToast("Success", this.$t("account.promo_code_deleted"));
            })
            .finally(() => {
              this.loading = false;
            })
            .catch(this.$_notificationsMixin_handleCatch);
        });
    },

    resetForm() {
      this.form.numberOfCodes = 1;
      this.form.discountPercentage = 100;
    },

    copy(code) {
      navigator.clipboard.writeText(code.attributes.code);
      this.$bvToast.toast(this.$t("account.promo_code_copied", "Promo code copied"), {
        title: this.$t("account.promo_code_copied", "Promo code copied"),
        variant: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.promocode-wrap {
  margin-top: 20px;
}

.promocode-row {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid rgba(32, 67, 78, 0.15);

  &__code {
    flex: 2;
    font-weight: bold;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__discount {
    flex: 1;
  }

  &__status {
    flex: 1;
  }

  &__controls {
    flex: 0 0 auto;
    display: flex;
    gap: 10px;
  }
}

.promocode-empty {
  text-align: center;
  padding: 20px;
  color: #666;
}
</style>
