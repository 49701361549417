export const mediaTagAspectRatio = () => {
  return {
    countdown_hero: { text: "64/15", value: 64 / 15 },
    countdown_hero_mobile: { text: "16/9", value: 16 / 9 },
    slider: { text: "16/9", value: 16 / 9 },
    charidy_homepage: { text: "16/9", value: 16 / 9 },
    team_default: { text: "16/9", value: 16 / 9 },
    team_default_avatar: { text: "1/1", value: 1 / 1 },
    campaign_hero: { text: "64/15", value: 64 / 15 },
    campaign_hero_mobile: { text: "16/9", value: 16 / 9 },
    shared_image: { text: "1.91/1", value: 1.91 / 1 },
    projector_banner: {
      text: "5/2",
      value: 5 / 2,
    },
    missionsiyum_left: { text: "16/9", value: 16 / 9 },
    missionsiyum_left_mobile: { text: "16/9", value: 16 / 9 },
    missionsiyum_right: { text: "16/9", value: 16 / 9 },
    missionsiyum_right_mobile: { text: "16/9", value: 16 / 9 },
    brand_slider: { text: "1/1", value: 1 / 1 },
    footer_image: { text: "9/2", value: 9 / 2 },
    footer_image_mobile: { text: "13/8", value: 13 / 8 },

    past_achievements: { text: "16/9", value: 16 / 9 },
    storyfest_section: { text: "16/9", value: 16 / 9 },
    live_feed_section: { text: "16/9", value: 16 / 9 },

    hero_countdown_desktop: { text: "72/25", value: 72 / 25 },
    hero_countdown_mobile: { text: "213/250", value: 213 / 250 },
    hero_live_desktop: { text: "72/25", value: 72 / 25 },
    hero_live_mobile: { text: "213/250", value: 213 / 250 },
    hero_complete_desktop: { text: "72/25", value: 72 / 25 },
    hero_complete_mobile: { text: "213/250", value: 213 / 250 },

    slider_countdown_desktop: { text: "472/266", value: 472 / 266 },
    slider_countdown_mobile: { text: "472/266", value: 472 / 266 },
    slider_live_desktop: { text: "472/266", value: 472 / 266 },
    slider_live_mobile: { text: "472/266", value: 472 / 266 },
    slider_complete_desktop: { text: "472/266", value: 472 / 266 },
    slider_complete_mobile: { text: "472/266", value: 472 / 266 },
  };
};

export const mediaTags = () => {
  return [
    {
      text: "Countdown page image",
      value: "countdown_hero",
    },
    {
      text: "Countdown page video",
      value: "countdown_video",
    },
    {
      text: "Camapign image in the live list",
      value: "charidy_homepage",
    },
    {
      text: "Countdown page mobile image",
      value: "countdown_hero_mobile",
    },
    {
      text: "Regular campaign slider",
      value: "slider",
    },
    {
      text: "Regular campaign video",
      value: "video",
    },
    {
      text: "Unidy/Select campaign slider",
      value: "campaign_hero",
    },
    {
      text: "Campaign page slider mobile",
      value: "campaign_hero_mobile",
    },
    {
      text: "Campaign page share image (fb, whatsapp)",
      value: "shared_image",
    },
    {
      text: "Projector Mode Banner",
      value: "projector_banner",
    },
    {
      text: "Team page slider",
      value: "team_default",
    },
    {
      text: "Team page default avatar",
      value: "team_default_avatar",
    },
    {
      text: "Missionsiyum Left",
      value: "missionsiyum_left",
    },
    {
      text: "Missionsiyum left mobile",
      value: "missionsiyum_left_mobile",
    },
    {
      text: "Missionsiyum right",
      value: "missionsiyum_right",
    },
    {
      text: "Missionsiyum right mobile",
      value: "missionsiyum_right_mobile",
    },
    {
      text: "Brand slider",
      value: "brand_slider",
    },
    {
      text: "Use image as campaign footer",
      value: "footer_image",
    },
    {
      text: "Use image as campaign footer (mobile)",
      value: "footer_image_mobile",
    },
    {
      label: "Campaign Page V2 Hero",
      options: [
        {
          text: "Hero countdown (desktop)",
          value: "hero_countdown_desktop",
        },
        {
          text: "Hero countdown (mobile)",
          value: "hero_countdown_mobile",
        },
        {
          text: "Hero live (desktop)",
          value: "hero_live_desktop",
        },
        {
          text: "Hero live (mobile)",
          value: "hero_live_mobile",
        },
        {
          text: "Hero complete (desktop)",
          value: "hero_complete_desktop",
        },
        {
          text: "Hero complete (mobile)",
          value: "hero_complete_mobile",
        },
      ],
    },
    {
      label: "Campaign Page V2 Slider",
      options: [
        {
          text: "Slider countdown (desktop)",
          value: "slider_countdown_desktop",
        },
        {
          text: "Slider countdown (mobile)",
          value: "slider_countdown_mobile",
        },
        {
          text: "Slider live (desktop)",
          value: "slider_live_desktop",
        },
        {
          text: "Slider live (mobile)",
          value: "slider_live_mobile",
        },
        {
          text: "Slider complete (desktop)",
          value: "slider_complete_desktop",
        },
        {
          text: "Slider complete (mobile)",
          value: "slider_complete_mobile",
        },
      ],
    },
    {
      label: "Campaign Page V2 Other",
      options: [
        {
          text: "Past Achievements",
          value: "past_achievements",
        },
        {
          text: "Storyfest section",
          value: "storyfest_section",
        },
        {
          text: "Live feed section",
          value: "live_feed_section",
        },
      ],
    },
  ];
};
