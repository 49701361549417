<script>
import urls from "@/constants/urls";
import axios from "axios";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  props: {
    campaignMode: {
      type: Number,
      default: 0,
    },
    externalLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      componentLoading: false,
      campaignModeOptions: [
        {
          text: this.$t("edit_campaign.campaign_modes_live", "Campaign is LIVE"),
          value: 2,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_countdown", "Campaign is in COUNTDOWN mode"),
          value: 1,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_demo", "Campaign is in DEMO mode"),
          value: 5,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_pending", "Campaign is in PENDING mode"),
          value: 0,
          disabled: true,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_success", "Campaign is in SUCCESS mode"),
          value: 3,
          disabled: true,
        },
        {
          text: this.$t("edit_campaign.campaign_modes_failed", "Campaign is in FAILED mode"),
          value: 4,
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      oID: () => localStorage.getItem("orgId"),
      cID: (state) => state.campaign.campaign.data.id,
      addSetCampaignModeSetting: (state) => state.account.addSetCampaignModeSetting,
    }),

    loading() {
      return this.componentLoading || this.externalLoading;
    },
  },
  methods: {
    onChange(value) {
      this.setCampaignMode(value).then(() => {
        this.$emit("update:campaignMode", value);
      });
    },
    async setCampaignMode(mode) {
      this.componentLoading = true;
      try {
        await axios.post(urls.campaignSetCampaignMode(this.oID, this.cID), {
          id: 0,
          type: "set_campaign_mode",
          data: { attributes: { mode: mode } },
        });
        return;
      } catch (e) {
        console.error(e);
        this.$_notificationsMixin_handleCatch(e);
        return;
      } finally {
        this.componentLoading = false;
      }
    },
  },
});
</script>

<template>
  <b-input-group
    v-if="addSetCampaignModeSetting"
    id="set-campaign-mode"
    :prepend="$t('edit_campaign.add_set_campaign_mode_setting_setting_name', 'Mode')"
    class="!tw-w-auto"
  >
    <b-form-select
      id="set-campaign-mode-input"
      :value="campaignMode"
      @change="onChange"
      :options="campaignModeOptions"
      :disabled="loading"
    ></b-form-select>
  </b-input-group>
</template>
