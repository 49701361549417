<template>
  <div>
    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
      <button
        v-if="!success"
        class="bttn bttn--lg bttn--orange"
        @click="getPayOffData"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small></b-spinner>
        {{ btnTitle }}
      </button>

      <div v-if="!success" class="my-2">
        {{ $t("dashboard.sub_pay_off_description", "Pay off") }}
      </div>

      <b-alert v-else variant="success" show>
        {{
          $t(
            "dashboard.sub_pay_off_success",
            "Subscription Paid Off ({charge_currency}{remaining_amount})",
            result
          )
        }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";

export default {
  props: {
    donationID: {
      type: [Number, String],
      default: 0,
    },
    donationAttributes: {
      type: Object,
      default: () => {},
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      success: false,
      loading: false,
      result: {
        remaining_amount: 0,
        currency: "",
      },
      payOffData: {
        charges_count: 0,
        remaining_count: 0,
        remaining_amount: 0,
        charge_currency: "",
      },
    };
  },

  computed: {
    orgId() {
      return localStorage.getItem("orgId");
    },
    btnTitle() {
      return this.$t("dashboard.sub_pay_off", "Pay off");
    },
  },

  methods: {
    getPayOffData() {
      this.loading = true;
      axios
        .get(urls.recurringPayOff(this.orgId, this.donationAttributes.campaign_id, this.donationID))
        .then(({ data }) => {
          this.payOffData = data.data;
          this.confirm();
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    confirm() {
      let message = this.$t(
        "dashboard.sub_pay_off_confirm_msg",
        "Are you sure that you want to pay off {remaining_amount} {charge_currency}? Subscription will be cancelled.",
        this.payOffData
      );

      let options = {
        okText: this.$t("dashboard.confirm_ok_btn", "Ok"),
        cancelText: this.$t("edit_campaign.confirm_cancel", "Cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.loading = true;
        axios
          .post(
            urls.recurringPayOff(this.orgId, this.donationAttributes.campaign_id, this.donationID)
          )
          .then(({ data }) => {
            this.$_notificationsMixin_makeToast("Success", "", "success");
            this.success = true;
            this.result = data.data;
          })
          .catch((e) => {
            this.$_notificationsMixin_handleCatch(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style></style>
