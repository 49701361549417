import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  gateways: [],
  loadingGateways: true,
};

const mutations = {
  setGateways(state, data) {
    state.gateways = data;
  },

  setLoadingGateways(state, value) {
    state.loadingGateways = value;
  },
};

const actions = {
  getGateways({ commit }) {
    commit("setLoadingGateways", true);
    let orgId = localStorage.getItem("orgId");
    axios.get(urls.gateways.replace(":orgId", orgId)).then((response) => {
      let gateways = response.data.data;
      let included = response.data.included;

      let filteredItem = (searched) =>
        included?.filter((item) => item.type === searched.type && searched.id === item.id)?.[0] ||
        [];

      gateways = gateways.map((gateway) => {
        if (gateway.relationships?.cids?.data?.length) {
          gateway.relationships.cids.data = gateway.relationships.cids?.data?.map((cid) => {
            return filteredItem(cid);
          });
        }

        if (gateway.relationships?.charge_currency?.data?.length) {
          gateway.relationships.charge_currency.data =
            gateway.relationships.charge_currency?.data?.map((currency) => {
              return filteredItem(currency);
            });
        }

        return gateway;
      });

      commit("setGateways", gateways);
      commit("setLoadingGateways", false);
    });
  },

  getGatewaysAndReturn() {
    let orgId = localStorage.getItem("orgId");
    return axios.get(urls.gateways.replace(":orgId", orgId)).then((response) => {
      return response.data.data;
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
