<script setup>
import { useCampaignGift } from "@/composables/campaign-gift";
import { useCampaignGiftClaim } from "@/composables/campaign-gift-claim";
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";
import CampaignGiftModal from "./campaignGiftModal.vue";
import { notificationsMixin } from "@/mixins";
import CampaignGiftsClaimModal from "./campaignGiftsClaimModal.vue";
import MsgBoxConfirm from "@/components/msg-box-confirm.vue";
import Setting from "@/views/campaigns/campaign-settings/setting.vue";
import ChineseAuctionSchema from "@/views/campaigns/campaign-settings/settings-schemas/chinese_auction.schema";
import {
  CharidyCampaignGiftsClaimStatusCanceled,
  CharidyCampaignGiftsClaimStatusCurrentlyShipping,
  CharidyCampaignGiftsClaimStatusDelivered,
  CharidyCampaignGiftsClaimStatusOther,
  CharidyCampaignGiftsClaimStatusProcessed,
  CharidyCampaignGiftsClaimStatusSentToCarrier,
} from "@/composables/campaign-gift-claim";
import { appHost } from "@/constants/urls.js";

const {
  list: campaignGiftList,
  total: campaignGiftListTotal,
  loading: loadingCampaignGift,
  fetchOrgCampaignGiftList,
  deleteOrgCampaignGift,
} = useCampaignGift();
const {
  items: campaignGiftsClaimList,
  total: campaignGiftsClaimListTotal,
  loading: loadingCampaignGiftsClaim,
  fetchOrgCampaignGiftClaimListByOrg,
  deleteOrgCampaignGiftClaim,
} = useCampaignGiftClaim();

const vm = getCurrentInstance();
const $t = vm.proxy.$t.bind(vm.proxy);
const cId = vm.proxy.$store.state.campaign.campaign.data.id;
const notificationsMixinHandleCatch =
  notificationsMixin.methods.$_notificationsMixin_handleCatch.bind(vm.proxy);
const notificationsMixinMakeToastCompat =
  notificationsMixin.methods.$_notificationsMixin_makeToastCompat.bind(vm.proxy);

const campaignGiftListPage = ref(1);
const campaignGiftsClaimListPage = ref(1);

onMounted(() => {
  fetchOrgCampaignGiftList(cId, {
    page: campaignGiftListPage.value,
    per_page: 10,
  });
  fetchOrgCampaignGiftClaimListByOrg(cId, {
    page: campaignGiftsClaimListPage.value,
    per_page: 10,
  });
});

watch(campaignGiftListPage, (v) => {
  fetchOrgCampaignGiftList(cId, {
    page: v,
    per_page: 10,
  });
});

watch(campaignGiftsClaimListPage, (v) => {
  fetchOrgCampaignGiftClaimListByOrg(cId, {
    page: v,
    per_page: 10,
  });
});

function onSubmitCampaignGiftModal(hide) {
  hide();
  fetchOrgCampaignGiftList(cId, {
    page: campaignGiftListPage.value,
    per_page: 10,
  });
}

function onSubmitCampaignGiftClaimsModal(hide) {
  hide();
  fetchOrgCampaignGiftClaimListByOrg(cId, {
    page: campaignGiftsClaimListPage.value,
    per_page: 10,
  });
}

function onDeleteGift(id) {
  deleteOrgCampaignGift(cId, id)
    .then(() => {
      notificationsMixinMakeToastCompat({
        group: "Notification",
        title: $t("edit_campaign.success_delete_campaign_gift", "Deleted"),
      });
    })
    .catch((e) => notificationsMixinHandleCatch(e))
    .finally(() => {
      fetchOrgCampaignGiftList(cId, {
        page: campaignGiftListPage.value,
        per_page: 10,
      });
    });
}

function onDeleteGiftClaim(id) {
  deleteOrgCampaignGiftClaim(cId, id)
    .then(() => {
      notificationsMixinMakeToastCompat({
        group: "Notification",
        title: $t("edit_campaign.success_delete_campaign_gifts_claim", "Deleted"),
      });
    })
    .catch((e) => notificationsMixinHandleCatch(e))
    .finally(() => {
      fetchOrgCampaignGiftClaimListByOrg(cId, {
        page: campaignGiftsClaimListPage.value,
        per_page: 10,
      });
    });
}

const campaignGiftListFields = computed(() => {
  return [
    {
      key: "ID",
      label: $t("edit_campaign.campaign_gifts_internal_table_gift_id", "ID"),
    },
    {
      key: "campaign_id",
      label: $t("edit_campaign.campaign_gifts_internal_table_campaign_id", "Campaign ID"),
    },
    {
      key: "title",
      label: $t("edit_campaign.campaign_gifts_internal_table_gift_title", "Title"),
    },
    {
      key: "image",
      label: $t("edit_campaign.campaign_gifts_internal_table_gift_image", "Image"),
    },
    {
      key: "quantity",
      label: $t("edit_campaign.campaign_gifts_internal_table_gift_quantity", "Quantity"),
    },
    {
      key: "points",
      label: $t("edit_campaign.campaign_gifts_internal_table_gift_points", "Points"),
    },
    {
      key: "order",
      label: $t("edit_campaign.campaign_gifts_internal_table_gift_order", "Order"),
    },
    {
      key: "actions",
      label: $t("edit_campaign.campaign_gifts_internal_table_gift_actions", "Actions"),
    },
  ];
});

const campaignGiftsClaimListFields = computed(() => {
  return [
    {
      key: "ID",
      label: $t("edit_campaign.campaign_gifts_internal_table_claim_id", "ID"),
    },
    {
      key: "gift",
      label: $t("edit_campaign.campaign_gifts_internal_table_claim_gift", "Gift"),
    },
    {
      key: "team",
      label: $t("edit_campaign.campaign_gifts_internal_table_claim_team", "Team"),
    },
    {
      key: "points",
      label: $t("edit_campaign.campaign_gifts_internal_table_claim_points", "Points"),
    },
    {
      key: "quantity",
      label: $t("edit_campaign.campaign_gifts_internal_table_claim_quantity", "Quantity"),
    },
    {
      key: "status",
      label: $t("edit_campaign.campaign_gifts_internal_table_claim_status", "Status"),
    },
    {
      key: "actions",
      label: $t("edit_campaign.campaign_gifts_internal_table_claim_actions", "Actions"),
    },
  ];
});

const statusVariant = (status) => {
  switch (status) {
    case CharidyCampaignGiftsClaimStatusSentToCarrier:
      return "warning";
    case CharidyCampaignGiftsClaimStatusCurrentlyShipping:
      return "info";
    case CharidyCampaignGiftsClaimStatusDelivered:
      return "success";
    case CharidyCampaignGiftsClaimStatusOther:
      return "secondary";
    case CharidyCampaignGiftsClaimStatusProcessed:
      return "info";
    case CharidyCampaignGiftsClaimStatusCanceled:
      return "danger";
    default:
      return "secondary";
  }
};

const teamName = computed(() => (item) => {
  if (!item.team) {
    return item.team_id;
  }

  return `${item.team?.name} - ${item.team_id}`;
});

const getTeamLink = computed(() => (item) => {
  if (!item.team || !item.campaign) {
    return "";
  }

  return `${appHost}/${item.campaign.short_link}/${item.team.slug}`;
});

const giftDetail = computed(() => (item) => {
  // add line break to gift title every 4 words to avoid overflow
  const addBreakLines = (text) =>
    text
      .split(" ")
      .map((word, i) => (i > 0 && i % 4 === 0 ? "<br>" : "") + word)
      .join(" ");

  const giftName = item.gift?.title ? addBreakLines(item.gift.title) : item.gift_id;
  const giftImage = item.gift?.image || "";

  return {
    name: giftName,
    image: giftImage,
  };
});
</script>

<template>
  <div>
    <b-card class="!tw-border-0" no-body>
      <b-tabs pills card vertical>
        <b-tab
          :title="$t('edit_campaign.campaign_gifts_internal_tab_title_settings', 'Settings')"
          active
        >
          <Setting title="" name="chinese_auction" :scheme="ChineseAuctionSchema($t)" />
        </b-tab>
        <b-tab :title="$t('edit_campaign.campaign_gifts_internal_tab_title_gifts', 'Gifts')">
          <div class="tw-flex tw-items-center tw-gap-3 tw-mb-5">
            <CampaignGiftModal @submit="onSubmitCampaignGiftModal" v-slot="{ show, loading }">
              <button
                @click="() => show()"
                type="button"
                :disabled="loading"
                :class="{
                  disabled: loading,
                }"
                class="bttn bttn--lg bttn--orange"
              >
                {{ $t("edit_campaign.campaign_gifts_internal_tab_action_create_gift", "Create") }}
              </button>
            </CampaignGiftModal>
          </div>
          <b-table
            class="!tw-mb-5"
            id="campaign-gift-list"
            :busy="loadingCampaignGift"
            :items="campaignGiftList"
            :fields="campaignGiftListFields"
          >
            <template #cell(image)="{ item }">
              <div class="tw-flex tw-items-center tw-gap-3">
                <img
                  v-if="item.image"
                  :src="item.image"
                  :alt="item.title"
                  width="140"
                  height="140"
                  class="tw-w-[140px] tw-h-[140px] tw-rounded-full tw-object-cover tw-mb-3 lg:tw-mb-0"
                />
                <div
                  v-else
                  aria-roledescription="image placeholder"
                  class="tw-w-[140px] tw-h-[140px] tw-rounded-full tw-bg-gray-300 tw-mb-3 lg:tw-mb-0"
                />
              </div>
            </template>
            <template #cell(actions)="{ item }">
              <div class="tw-flex tw-items-center tw-gap-3">
                <CampaignGiftModal @submit="onSubmitCampaignGiftModal" v-slot="{ show, loading }">
                  <button
                    :disabled="loading"
                    :aria-label="
                      $t('edit_campaign.campaign_gifts_internal_tab_action_edit_gift', 'Edit')
                    "
                    class="bttn bttn-round"
                    @click="show(item.ID)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                      />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </button>
                </CampaignGiftModal>
                <MsgBoxConfirm
                  as="button"
                  :disabled="loadingCampaignGift"
                  :aria-label="
                    $t('edit_campaign.campaign_gifts_internal_tab_action_delete_gift', 'Delete')
                  "
                  :title="$t('edit_campaign.confirm_delete_campaign_gift', 'Delete campaign gift?')"
                  class="bttn bttn-round"
                  @confirm="onDeleteGift(item.ID)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                    />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </MsgBoxConfirm>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="campaignGiftListPage"
            :total-rows="campaignGiftListTotal"
            :per-page="10"
            aria-controls="campaign-gift-list"
          ></b-pagination>
        </b-tab>
        <b-tab
          :title="$t('edit_campaign.campaign_gifts_internal_tab_title_gift_claims', 'Gift claims')"
        >
          <b-table
            id="campaign-gift-claims-table"
            class="!tw-mb-5"
            :busy="loadingCampaignGiftsClaim"
            :items="campaignGiftsClaimList"
            :fields="campaignGiftsClaimListFields"
          >
            <template #cell(gift)="{ item }">
              <div class="tw-flex tw-flex-col tw-items-center tw-gap-3">
                <img
                  v-if="giftDetail(item).image"
                  :src="giftDetail(item).image"
                  :alt="giftDetail(item).name"
                  width="140"
                  height="140"
                  class="tw-w-[140px] tw-h-[140px] tw-rounded-full tw-object-cover tw-mb-3 lg:tw-mb-0"
                />
                <p class="tw-text-lg tw-font-bold" v-html="giftDetail(item).name"></p>
              </div>
            </template>
            <template #cell(status)="{ item }">
              <b-alert show :variant="statusVariant(item.status)">
                <span v-if="item.status === CharidyCampaignGiftsClaimStatusSentToCarrier">
                  {{
                    $t(
                      "edit_campaign.campaign_gifts_claims_modal_status_sent_to_carrier",
                      CharidyCampaignGiftsClaimStatusSentToCarrier
                    )
                  }}
                </span>
                <span v-else-if="item.status === CharidyCampaignGiftsClaimStatusCurrentlyShipping">
                  {{
                    $t(
                      "edit_campaign.campaign_gifts_claims_modal_status_currently_shipping",
                      CharidyCampaignGiftsClaimStatusCurrentlyShipping
                    )
                  }}
                </span>
                <span v-else-if="item.status === CharidyCampaignGiftsClaimStatusDelivered">
                  {{
                    $t(
                      "edit_campaign.campaign_gifts_claims_modal_status_delivered",
                      CharidyCampaignGiftsClaimStatusDelivered
                    )
                  }}
                </span>
                <span v-else-if="item.status === CharidyCampaignGiftsClaimStatusOther">
                  {{
                    $t(
                      "edit_campaign.campaign_gifts_claims_modal_status_other",
                      CharidyCampaignGiftsClaimStatusOther
                    )
                  }}
                </span>
                <span v-else-if="item.status === CharidyCampaignGiftsClaimStatusProcessed">
                  {{
                    $t(
                      "edit_campaign.campaign_gifts_claims_modal_status_processed",
                      CharidyCampaignGiftsClaimStatusProcessed
                    )
                  }}
                </span>
                <span v-else-if="item.status === CharidyCampaignGiftsClaimStatusCanceled">
                  {{
                    $t(
                      "edit_campaign.campaign_gifts_claims_modal_status_canceled",
                      CharidyCampaignGiftsClaimStatusCanceled
                    )
                  }}
                </span>
                <span v-else>
                  {{ $t("edit_campaign.campaign_gifts_claims_modal_status_undefined", "None") }}
                </span>
              </b-alert>
            </template>
            <template #cell(team)="{ item }">
              <a :href="getTeamLink(item)" v-if="item.team_id">{{ teamName(item) }}</a>
              <span v-else>{{
                $t("edit_campaign.campaign_gifts_claims_modal_team_id_none", "None")
              }}</span>
            </template>
            <template #cell(actions)="{ item }">
              <div class="tw-flex tw-items-center tw-gap-3">
                <CampaignGiftsClaimModal
                  @submit="onSubmitCampaignGiftClaimsModal"
                  v-slot="{ show, loading }"
                >
                  <button
                    :disabled="loading"
                    :aria-label="
                      $t('edit_campaign.campaign_gifts_internal_tab_action_edit_gift', 'Edit')
                    "
                    class="bttn bttn-round"
                    @click="show(item.ID)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                      />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </button>
                </CampaignGiftsClaimModal>
                <MsgBoxConfirm
                  as="button"
                  :disabled="loadingCampaignGiftsClaim"
                  :aria-label="
                    $t('edit_campaign.campaign_gifts_internal_tab_action_delete_gift', 'Delete')
                  "
                  :title="
                    $t(
                      'edit_campaign.confirm_delete_campaign_gifts_claim',
                      'Delete campaign gifts claim?'
                    )
                  "
                  class="bttn bttn-round"
                  @confirm="onDeleteGiftClaim(item.ID)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                    />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </MsgBoxConfirm>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="campaignGiftsClaimListPage"
            :total-rows="campaignGiftsClaimListTotal"
            :per-page="10"
            aria-controls="campaign-gift-claims-table"
          ></b-pagination>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
