import { dashboardApiHost } from "@/constants/urls";
import axios from "axios";
import { ref } from "vue";

export async function getProtectedCampaignGiftList(campaignId, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/campaign/${campaignId}/campaign-gift/list`;
  const { data } = await axios.get(url, { params });

  return {
    data,
  };
}

export async function getProtectedTeamUserStatList(teamId, params) {
  const url = `${dashboardApiHost}/orgarea/api/v1/chinese-auction/team/${teamId}/stat_list`;
  const { data } = await axios.get(url, { params });

  return {
    data,
  };
}

export function useProtectedCampaignGift() {
  const items = ref([]);
  const loading = ref(false);
  const teamTotal = ref(0);
  const subTeamsTotal = ref(0);
  const calculatedPointsTotal = ref(0);
  const teamGiftPointsPurchased = ref(0);

  async function fetchProtectedCampaignGiftList(campaignId, params) {
    loading.value = true;

    try {
      const { data } = await getProtectedCampaignGiftList(campaignId, params);

      data.sort((a, b) => {
        return b.order - a.order;
      });

      items.value = data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function fetchProtectedTeamUserStatList(teamId, params) {
    loading.value = true;

    try {
      const { data } = await getProtectedTeamUserStatList(teamId, params);
      teamTotal.value = data.team_total;
      subTeamsTotal.value = data.sub_teams_total;
      calculatedPointsTotal.value = data.calculated_points_total;
      teamGiftPointsPurchased.value = data.team_gift_points_purchased;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    items,
    teamTotal,
    subTeamsTotal,
    calculatedPointsTotal,
    teamGiftPointsPurchased,
    loading,
    fetchProtectedCampaignGiftList,
    fetchProtectedTeamUserStatList,
  };
}
