<template>
  <div>
    <b-modal
      id="media-storage-form-madal"
      size="xl"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
    >
      <div slot="modal-header">
        <h2 class="text-capitalize">
          {{ $t(`dashboard.media_storage_${type}_modal_title`, `${type} media`) }}
        </h2>
      </div>

      <div>
        <b-row>
          <b-col>
            <b-form-group>
              <template #label>
                {{ $t("dashboard.media_storage_campaign", "Campaign:") }}
              </template>
              <multiselect
                class="cms"
                v-model="selectedCmp"
                :options="campaigns"
                :custom-label="customLabel"
                :allow-empty="false"
                :show-labels="false"
                :loading="selectCmpLoading"
                label="id"
                track-by="id"
                placeholder="Select campaign"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <div class="d-table h-100">
              <span class="d-table-cell align-middle" v-html="camapignTmplInfo"> </span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <template #label>
                {{ $t("dashboard.media_storage_team", "Team:") }}
              </template>

              <multiselect-search
                v-model="selectedTeam"
                :open-prefetch="true"
                :search-url="getSearchUrl"
                :search-options="searchOptions"
                :multiselectClass="{ cms: true }"
              >
                <template #no-options>
                  {{ $t("dashboard.team_search_no_options", "Please enter 1 or more characters") }}
                </template>
                <template #no-more-infinite>
                  {{ $t("dashboard.team_search_no_more", "No more teams") }}
                </template>
              </multiselect-search>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <template #label>
                {{ $t("dashboard.media_storage_order", "Order:") }}
              </template>
              <b-form-input v-model="form.order"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <template #label>
                {{ $t("dashboard.media_storage_tag", "Tag:") }}
              </template>
              <b-form-select v-model="form.tag" :options="optionsTags"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <template #label>
                {{ $t("dashboard.media_storage_lang", "Lang:") }}
              </template>
              <b-form-select
                v-model="form.lang"
                value-field="short"
                text-field="long"
                :options="langs"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="form.tag.includes('video')">
          <b-col>
            <b-form-group>
              <template #label>
                {{ $t("dashboard.media_storage_video_src", "Video link:") }}
              </template>
              <b-form-input v-model="form.src"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="!form.tag.includes('video')">
          <b-col>
            <b-form-group>
              <template #label>
                {{
                  type === "add"
                    ? $t("dashboard.media_storage_upload_img", "Upload image:")
                    : $t("dashboard.media_storage_reupload_img", "Reupload image:")
                }}
              </template>
              <b-input-group>
                <template #append>
                  <div v-if="file" class="btn h-100" @click="resetFile">
                    <b-icon-arrow-clockwise rotate="45" scale="1.5" />
                  </div>
                </template>
                <b-form-file
                  v-model="file"
                  accept="image/*"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-alert :show="src && aspectRatio !== 1">
          <span v-html="aspectRatioInfo"></span>
        </b-alert>

        <b-row v-if="src && !form.tag.includes('video')">
          <b-col cols="6">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("dashboard.media_storage_original_img", "Original image:") }}
            </legend>
            <MediaStorageCropper
              ref="cropper"
              :src="src"
              :stencil-props="{ aspectRatio }"
              :tag="form.tag"
              @change="onChange"
            />
          </b-col>
          <b-col v-show="previewImageSrc" cols="6">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("dashboard.media_storage_final_img", "Final image:") }}
            </legend>
            <MediaStorageCropperPreview :image="previewImageSrc" :tag="form.tag" />
          </b-col>
        </b-row>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>
        <button
          class="bttn bttn--lg bttn--orange text-capitalize"
          form="utm-form"
          :disabled="loading"
          @click="submit"
        >
          <b-spinner v-if="loading" small class="mr-1"></b-spinner>
          {{ $t(`dashboard.media_storage_${type}_modal_btn`, `${type} media`) }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import urls from "@/constants/urls";
import { mediaTagAspectRatio, mediaTags } from "@/constants/media-tags";
import multiselectSearch from "@/components/multiselect-search.vue";
import { cloneDeep } from "@/helpers/cloneDeep";
import { blobToFile } from "@/helpers/processImageFile";
import { notificationsMixin } from "@/mixins";
import MediaStorageCropper from "./MediaStorageCropper.vue";
import MediaStorageCropperPreview from "./MediaStorageCropperPreview.vue";
import { mapMetasToObject } from "@/store/modules/campaign";

export default {
  components: {
    Multiselect,
    multiselectSearch,
    MediaStorageCropper,
    MediaStorageCropperPreview,
  },

  mixins: [notificationsMixin],

  data() {
    return {
      type: "add",
      id: 0,
      campaign: null,
      metas: {},
      team: null,
      file: null,
      form: {
        campaign_id: 0,
        lang: "",
        order: 1,
        src: "",
        tag: "slider",
        team_id: 0,
      },
      previewImageSrc: "",
      cropperCanvas: null,
      loading: false,
      error: null,
      selectCmpLoading: false,
    };
  },

  computed: {
    ...mapState({
      campaigns: (state) => state.mediaStorage.campaigns,
    }),
    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),

    optionsTags() {
      return mediaTags({});
    },

    optionsAspectRatio() {
      return mediaTagAspectRatio({});
    },

    aspectRatio() {
      const mtar = this.optionsAspectRatio[this.form.tag];
      if (mtar && (mtar.value?.height || mtar.value?.width)) {
        return mtar.value.height / mtar.value.width;
      }
      return (mtar && mtar.value) || 1;
    },

    aspectRatioText() {
      const mtar = this.optionsAspectRatio[this.form.tag];
      return (mtar && mtar.text) || "";
    },

    fileUrl() {
      return this.file && URL.createObjectURL(this.file);
    },

    src() {
      return this.fileUrl || this.form.src;
    },

    langs() {
      return [
        {
          short: "",
          long: "Select language or leave empty",
        },
        ...this.getOldLangsVariant,
      ];
    },

    selectedTeam: {
      get() {
        return this.team;
      },
      set(val) {
        this.form.team_id = Number(val?.id);
        this.team = val;
      },
    },

    selectedCmp: {
      get() {
        if (this.campaign) {
          return this.campaign;
        }

        if (this.$store.state.mediaStorage.campaign) {
          return this.$store.state.mediaStorage.campaign;
        }

        return null;
      },
      set(val) {
        this.selectCmpLoading = true;
        this.getCampaignData(val.id)
          .then((campaign) => {
            this.form.campaign_id = campaign.id;
            this.campaign = campaign.data;
          })
          .finally(() => {
            this.selectCmpLoading = false;
          });
      },
    },

    getSearchUrl() {
      const cID = this.selectedCmp.id;
      const orgID = localStorage.getItem("orgId");

      if (Number(cID) === 0) {
        throw new Error("getSearchUrl media storage campaign id is 0");
      }

      return urls.apiTeams.replace(":orgId", orgID).replace(":campaignId", cID);
    },

    searchOptions() {
      return {
        placeholder: this.$t(
          "dashboard.team_search_label_placeholder",
          "Select team or leave empty"
        ),
        changeParams: ({ query, page, per_page: pp }) => ({
          q: query,
          page: page,
          limit: pp,
        }),
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
          })),
      };
    },

    camapignTmplInfo() {
      const fallback = `This campaign uses <b>"{tmpl}"</b> template`;

      const tplFallback = this.selectedCmp.attributes.template.replace(/\b[a-z]/g, (x) =>
        x.toUpperCase()
      );

      return this.$t("dashboard.media_storage_cmp_tmpl", fallback, {
        tmpl: this.$t(`dashboard.tempalte_${this.selectedCmp.attributes.template}`, tplFallback),
      });
    },

    aspectRatioInfo() {
      const tag = this.optionsTags.find((el) => el.value === this.form.tag)?.text || "";
      const fallback = `For "{tmpl}" template, "{tag}" image, the recomended aspect ratio is <b>{aspectRatio}</b>, use the croping tool bolow to crop the original image according to the guideline`;

      const tplFallback = this.selectedCmp.attributes.template.replace(/\b[a-z]/g, (x) =>
        x.toUpperCase()
      );

      return this.$t("dashboard.media_storage_aspect_ratio_info", fallback, {
        tmpl: this.$t(`dashboard.tempalte_${this.selectedCmp.attributes.template}`, tplFallback),
        tag,
        aspectRatio: this.aspectRatioText,
      });
    },
  },

  methods: {
    ...mapActions({
      fetchCreateFileUrl: "mediaStorage/fetchCreateFileUrl",
      fetchCreateEditMedia: "mediaStorage/fetchCreateEditMedia",
    }),

    async getCampaignData(cid) {
      let props = `?${[
        "extend=media",
        "extend=meta",
        "extend=donation_levels",
        "extend=donation_streams",
      ].join("&")}`;

      const campaign = await this.$store.dispatch("getCampaign", {
        cid,
        props,
      });

      this.metas = mapMetasToObject(campaign.included);

      return campaign;
    },

    open(type, item) {
      if (type === "edit") {
        this.type = type;
        this.id = item.id;
        this.form = item._attributes;

        if (item._attributes.team_id) {
          Axios.get(this.getSearchUrl, {
            params: {
              team_id: item._attributes.team_id,
            },
          }).then(({ data }) => {
            if (data.data[0]) {
              this.team = {
                id: data.data[0].id,
                ...data.data[0].attributes,
              };
            }
          });
        }
      }
      this.$bvModal.show("media-storage-form-madal");
    },

    close() {
      this.$bvModal.hide("media-storage-form-madal");
    },

    customLabel({ id, attributes }) {
      return `[${id}] ${attributes.title}`;
    },

    onChange({ canvas }) {
      this.previewImageSrc = canvas?.toDataURL() || "";
      this.cropperCanvas = canvas;
    },

    resetFile() {
      this.fileUrl && URL.revokeObjectURL(this.fileUrl);
      this.file = null;
    },

    getCroppedImgURL() {
      return new Promise((resolve) => {
        if (this.cropperCanvas) {
          const type = { shared_image: this.file?.type }[this.form.tag] || "image/webp";

          this.cropperCanvas.toBlob(
            (blob) => {
              const ext = type.replace("image/", "");
              const file = blobToFile(blob, `${Date.now()}.${ext}`, type);
              resolve(this.fetchCreateFileUrl({ file }));
            },
            type,
            0.6
          );
        } else {
          resolve(this.form.src);
        }
      });
    },

    submit() {
      this.loading = true;
      this.getCroppedImgURL().then((imgURL) => {
        const form = cloneDeep(this.form);
        form.src = imgURL;
        form.campaign_id = Number(this.selectedCmp.id);
        form.order = Number(form.order);

        this.fetchCreateEditMedia({
          mID: this.id,
          form,
        })
          .then(() => {
            this.$_notificationsMixin_makeToast(
              "Success!",
              this.$t("dashboard.media_storage_success_save", "Saved successfully"),
              "success"
            );
            this.loading = false;
            this.close();
          })
          .catch((err) => {
            this.loading = false;
            this.$_notificationsMixin_handleCatch(err);
          });
      });
    },

    clearData() {
      this.fileUrl && URL.revokeObjectURL(this.fileUrl);
      this.id = 0;
      this.error = null;
      this.type = "add";
      this.previewImageSrc = "";
      this.file = null;
      this.team = null;
      this.loading = false;
      this.form = {
        campaign_id: 0,
        lang: "",
        order: 1,
        src: "",
        tag: "slider",
        team_id: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .multiselect.cms {
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    .multiselect__select {
      height: 50px;
      &:before {
        top: 60%;
      }
    }
    .multiselect__tags {
      min-height: 50px;
      padding: 0px 40px 0 8px;
    }
    .multiselect__single,
    .multiselect__placeholder {
      font-size: 16px;
      font-weight: 600;
      min-height: 50px;
      line-height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    .multiselect__input {
      min-height: 50px;
      font-size: 16px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 0;
    }
  }
}
</style>
