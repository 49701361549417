<template>
  <HeadlessMultipleSetting
    :settingScheme="{ brand_slider: brandSliderScheme }"
    v-slot="{ formData, save, loading, isActive }"
  >
    <SettingModal
      :isActive="isActive"
      :save="save"
      :loading="loading"
      :baseSettingsItem="baseSettingsItem"
    >
      <template #content="{ hide }">
        <form id="BrandSliderForm" novalidate @submit.prevent="onSubmit($event, save, hide)">
          <FormGeneretor
            :scheme="brandSliderScheme"
            schemeName="brand_slider"
            :value="formData('brand_slider')"
            key="brand_slider"
            form="BrandSlider"
            as="div"
            class="my-4"
            :components="$options.formGeneretorComponents"
          />
        </form>
        <div class="d-flex justify-content-end tw-items-end">
          <button
            type="button"
            class="bttn bttn--sm bttn--blue tw-me-auto tw-mt-7"
            @click="hide"
            name="button"
          >
            {{ $t("edit_campaign.setting_close", "Close") }}
          </button>
          <b-btn
            class="bttn bttn--sm bttn--orange"
            form="BrandSliderForm"
            type="submit"
            :disabled="loading"
            :style="{ gap: '10px' }"
          >
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t("edit_campaign.setting_save", "Save") }}
          </b-btn>
        </div>
      </template>
    </SettingModal>
  </HeadlessMultipleSetting>
</template>

<script>
import FormGeneretor from "@/common-components/form-generetor/form-generetor";
import HeadlessMultipleSetting from "@/views/campaigns/campaign-settings/headless-multiple-setting.vue";
import SettingModal from "@/views/campaigns/campaign-settings/settingModal.vue";

export default {
  formGeneretorComponents: {},
  components: {
    FormGeneretor,
    HeadlessMultipleSetting,
    SettingModal,
  },
  props: {
    baseSettingsItem: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    async onSubmit(form, save, hide) {
      await save(null, hide);
    },
  },
  computed: {
    brandSliderScheme() {
      return {
        title: "BrandSlider",
        type: "object",
        noValidateChild: true,
        properties: {
          value: {
            title: "Active",
            type: "boolean",
            default: false,
            expanded: true,
          },
          unify_desktop_and_mobile: {
            title: "Unify desktop and mobile images",
            type: "boolean",
            default: false,
            expanded: true,
          },
          desktop_unidy_on_top: {
            title: this.$t(
              "edit_campaign.brand_slider_setting_title_desktop_unidy_on_top",
              "desktop_unidy_on_top"
            ),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_desktop_unidy_on_top",
              "desktop_unidy_on_top are unidy template on top images used for desktop and tablet devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          mobile_unidy_on_top: {
            title: this.$t(
              "edit_campaign.brand_slider_setting_title_mobile_unidy_on_top",
              "mobile_unidy_on_top"
            ),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_mobile_unidy_on_top",
              "mobile_unidy_on_top are unidy template on top images used for mobile devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          desktop: {
            title: this.$t("edit_campaign.brand_slider_setting_title_desktop", "Desktop Images"),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_desktop",
              "Desktop images are used for desktop and tablet devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          mobile: {
            title: this.$t("edit_campaign.brand_slider_setting_title_mobile", "Mobile Images"),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_mobile",
              "Mobile images are used for mobile devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          desktop2: {
            title: this.$t("edit_campaign.brand_slider_setting_title_desktop2", "Desktop2 Images"),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_desktop2",
              "Desktop2 are alternative images used for desktop and tablet devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          mobile2: {
            title: this.$t("edit_campaign.brand_slider_setting_title_mobile2", "Mobile2 Images"),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_mobile2",
              "Mobile2 are alternative images used for mobile devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          desktop_onboarding: {
            title: this.$t(
              "edit_campaign.brand_slider_setting_title_desktop_onboarding",
              "desktop_onboarding"
            ),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_desktop_onboarding",
              "desktop_onboarding are campaign onboarding images used for desktop and tablet devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          mobile_onboarding: {
            title: this.$t(
              "edit_campaign.brand_slider_setting_title_mobile_onboarding",
              "mobile_onboarding"
            ),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_mobile_onboarding",
              "mobile_onboarding are campaign onboarding images used for mobile devices"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          desktop_coundown: {
            title: this.$t(
              "edit_campaign.brand_slider_setting_title_desktop_coundown",
              "desktop_coundown"
            ),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_desktop_coundown",
              "desktop_coundown are campaign coundown images"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
          desktop_coundown_on_top: {
            title: this.$t(
              "edit_campaign.brand_slider_setting_title_desktop_coundown_on_top",
              "desktop_coundown_on_top"
            ),
            tooltipText: this.$t(
              "edit_campaign.brand_slider_setting_tooltip_desktop_coundown_on_top",
              "desktop_coundown_on_top are campaign coundown images displayed on the top of the campaign page"
            ),
            type: "list-card",
            default: [],
            items: {
              type: "object",
              properties: {
                image_src: {
                  title: "Image File",
                  type: "image",
                  required: true,
                  preview: true,
                  allowAnyAspectRatio: true,
                },
                title: {
                  title: "Title",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
                url: {
                  title: "URL",
                  type: "string",
                  default: "",
                  maxLength: 256,
                },
              },
            },
          },
        },
      };
    },
  },
};
</script>
