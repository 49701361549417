<template>
  <div>
    <div class="disabled-area" :class="{ disabled: selectAllTeams }">
      <div class="disabled-overlay"></div>

      <ValidationObserver ref="observer">
        <b-table
          class="p-0"
          :items="campaignTeamDonationsLocal"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          stacked="lg"
        >
          <template v-slot:head(team_name)>
            <div>{{ $t("dashboard.table_team_name") }}</div>
          </template>
          <template v-slot:head(dedication)>
            <div>{{ $t("dashboard.table_dedication") }}</div>
          </template>
          <template v-slot:head(total)>
            <div v-if="isEditing">
              {{ $t("dashboard.campaign_team_donation_editing", "Effective amount") }}
            </div>
            <div v-else>{{ $t("dashboard.campaign_team_donation_adding", "Donation amount") }}</div>
          </template>
          <template v-slot:head(actions)>
            <div>{{ $t("dashboard.table_actions") }}</div>
          </template>
          <template v-slot:cell(team_name)="{ item }">
            <ValidationProvider name="team" rules="required" v-slot="{ errors }">
              <team-multiselect v-model="item.team_id" :data="campaignTeamDonationsLocal" />
              <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                class="invalid-feedback d-block is-danger"
              >
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </template>
          <template v-slot:cell(dedication)="{ item }">
            <ValidationProvider name="dedication" v-slot="{ errors }">
              <b-form-input v-model="item.dedication" type="text"></b-form-input>

              <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                class="invalid-feedback d-block is-danger"
              >
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </template>
          <template v-slot:cell(total)="{ item }">
            <ValidationProvider
              name="amount"
              :rules="`required|min_value:${allowedMinAmount}|max_value:${getMaxAmount(
                item.total
              )}`"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model.number="item.total"
                :max="getMaxAmount(item.total)"
                type="text"
                class="mw-100px"
                :state="!errors[0]"
                v-b-tooltip.hover
                :title="
                  errors[0]
                    ? $t(
                        'dashboard.table_team_amount_error',
                        'Total amount should not exceed the donation amount'
                      )
                    : ''
                "
              ></b-form-input>
            </ValidationProvider>
          </template>
          <template v-slot:cell(actions)="{ index }">
            <div>
              <button
                type="button"
                class="bttn bttn--sm bttn--bordered-red"
                @click="deleteLocal(index)"
              >
                {{ $t("dashboard.table_action_delete") }}
              </button>
            </div>
          </template>
        </b-table>
      </ValidationObserver>

      <b-pagination
        v-if="campaignTeamDonationsLocal.length > perPage"
        v-model="currentPage"
        :total-rows="campaignTeamDonationsLocal.length"
        :per-page="perPage"
        @change="validate"
      ></b-pagination>

      <div v-if="campaignTeamDonationsLocal.length !== totalTeams">
        <button type="button" class="bttn bttn--sm bttn--orange" @click="addLocal">
          {{ $t("dashboard.action_add_team") }}
        </button>
      </div>
    </div>

    <div v-if="campaignTeamDonationsLocal.length !== totalTeams">
      <div class="d-flex align-items-center my-2">
        <hr class="border flex-grow-1" />
        <span class="px-2">OR</span>
        <hr class="border flex-grow-1" />
      </div>

      <div>
        <b-form-checkbox
          v-model="selectAllTeams"
          :disabled="totalTeams > dChargedAmount"
          v-b-tooltip.hover
          :title="
            totalTeams > dChargedAmount
              ? $t(
                  'dashboard.donation_action_select_all_teams_disabled_msg',
                  'The donation amount {amount} is not enough to donate to all teams',
                  { amount: dChargedAmountFormated }
                )
              : ''
          "
        >
          {{
            $t(
              "dashboard.donation_action_select_all_teams",
              "Selects all teams (total {team_total} teams, minimal amount {min_amount})",
              {
                min_amount: minAmount,
                team_total: totalTeams,
              }
            )
          }}
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import { getFormatedAmountString } from "@/constants/currencies";
import { notificationsMixin } from "@/mixins";

import TeamMultiselect from "./teamMultiselect.vue";

export default {
  mixins: [notificationsMixin],
  components: {
    TeamMultiselect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    cId: {
      type: String,
      required: true,
    },
    dId: {
      type: [String, Object],
      default: null,
    },
    dAttrs: {
      type: Object,
      default: () => {},
    },
    dAmount: {
      type: Number,
      default: 0,
    },
    dChargedAmount: {
      type: Number,
      default: 0,
    },
    dCurrencyCode: {
      type: String,
      default: "",
    },
    hide: {
      type: Function,
      default: () => {},
    },
    isEditing: Boolean,
  },
  data() {
    return {
      fields: ["team_name", "dedication", "total", "actions"],
      campaignTeamDonationsLocal: [],
      perPage: 5,
      currentPage: 1,
      selectAllTeams: false,
    };
  },
  computed: {
    ...mapState({
      campaignTeamDonations: (state) => state.campaignTeamDonation.campaignTeamDonations,
      totalTeams: (state) => state.teams.totalTeams,
    }),
    campaignTeamDonationsChanged() {
      return !(
        JSON.stringify(this.campaignTeamDonations) ===
        JSON.stringify(this.campaignTeamDonationsLocal)
      );
    },
    allowedMinAmount() {
      if (this.dChargedAmount === 0 || this.dAmount === 0) {
        return 0;
      }
      return 1;
    },
    minAmount() {
      return getFormatedAmountString(String(this.totalTeams), this.dCurrencyCode);
    },
    dChargedAmountFormated() {
      return getFormatedAmountString(String(this.dChargedAmount), this.dCurrencyCode);
    },
  },
  watch: {
    campaignTeamDonationsLocal: {
      handler(v) {
        this.validate();
        this.$emit("localDonationsChanged", v);
      },
      deep: true,
    },
    dAmount: {
      handler() {
        this.validate();
        this.reCalculateAmount();
      },
    },
    dChargedAmount: {
      handler() {
        this.validate();
      },
    },
    selectAllTeams: {
      handler(v) {
        if (
          this.campaignTeamDonationsLocal.length !== this.totalTeams &&
          this.totalTeams < this.dChargedAmount
        ) {
          this.$emit("update:isValid", v);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchCampaignTeamDonations: "campaignTeamDonation/fetchCampaignTeamDonations",
      createCampaignTeamDonations: "campaignTeamDonation/createCampaignTeamDonations",
    }),
    changeTeam({ i, team_id }) {
      if (this.campaignTeamDonationsLocal[i]) {
        this.$set(this.campaignTeamDonationsLocal[i], "team_id", team_id);
      }
    },
    getLocalState() {
      return this.campaignTeamDonationsLocal;
    },
    invalidateState() {
      if (!this.dId) {
        return Promise.resolve(null);
      }
      return this.fetchCampaignTeamDonations([this.cId, this.dId])
        .then((data) => {
          this.campaignTeamDonationsLocal = JSON.parse(JSON.stringify(data));
          this.validate();
          return data;
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
    getMaxAmount(total) {
      const sum = this.campaignTeamDonationsLocal.reduce((res, el) => {
        res += el.total;
        return res;
      }, 0);

      const freeAmt = this.dAmount - sum;

      const res = total + freeAmt;

      return res;
    },
    reCalculateAmount(customAmount) {
      const tl = this.campaignTeamDonationsLocal.length;
      let amt = Math.floor(this.dAmount / (tl || 1));

      this.campaignTeamDonationsLocal = this.campaignTeamDonationsLocal.map((el) => {
        if (customAmount) {
          const p = el.total / this.dAmount;
          amt = p * customAmount;
        }

        return {
          ...el,
          total: amt || this.allowedMinAmount,
        };
      });
    },
    async validate() {
      this.$nextTick(() => {
        this.$refs.observer?.validate();
      });

      const validateItem = async (item) => {
        const maxAmt = this.getMaxAmount(item.total);

        if (item.total > maxAmt) {
          return false;
        }
        if (item.total < this.allowedMinAmount) {
          return false;
        }
        if (!item.team_id) {
          return false;
        }
        return true;
      };

      const valRes = [];
      this.campaignTeamDonationsLocal.forEach((el) => {
        valRes.push(validateItem(el));
      });
      Promise.all(valRes).then((v) => {
        this.$emit("update:isValid", !v.includes(false));
      });
    },
    addLocal() {
      const defaultItem = {
        campaign_id: Number(this.cId),
        donation_id: this.dId ? Number(this.dId) : null,
        team_id: null,
        dedication: this.dAttrs.dedication,
        total: this.dAmount || this.allowedMinAmount,
        real_payment: 0,
        amount: 0,
      };
      this.campaignTeamDonationsLocal.push(defaultItem);
      this.reCalculateAmount();
    },
    deleteLocal(rowIndex) {
      const index = (this.currentPage - 1) * this.perPage + rowIndex;
      this.campaignTeamDonationsLocal.splice(index, 1);
      this.reCalculateAmount();
    },
    save({ dId = null, customAmount = null } = {}) {
      if (!this.dId) {
        this.campaignTeamDonationsLocal = this.campaignTeamDonationsLocal.map((i) => ({
          ...i,
          donation_id: Number(dId),
        }));
      }
      if (customAmount) {
        this.reCalculateAmount(customAmount);
      }
      return this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          if (this.campaignTeamDonationsChanged || this.selectAllTeams) {
            this.createCampaignTeamDonations([
              this.cId,
              dId || this.dId,
              this.campaignTeamDonationsLocal,
              { all_teams: Number(this.selectAllTeams) },
            ])
              .then(() => {
                this.invalidateState().then(this.hide);
              })
              .catch(this.$_notificationsMixin_handleCatch);
          }
        } else {
          this.$_notificationsMixin_makeToast(
            "Donations to the teams error",
            "invalid form data",
            "danger"
          );
        }
        return isValid;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  $this: &;
  &-area {
    position: relative;
  }
  &#{$this}-area {
    #{$this}-overlay {
      display: unset;
    }
  }
  &-overlay {
    display: none;
    position: absolute;
    cursor: not-allowed;
    z-index: 5;
    background: rgba(0, 0, 0, 0.15);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.mw-100px {
  min-width: 100px;
}
</style>
