<template>
  <div class="ab action-block">
    <button class="bttn bttn--sm bttn--orange" @click="openModal">
      {{ $t("integration.add_integration", `Add ${gatewayStep}`) }}
    </button>
    <!-- Modal Component -->
    <b-modal
      id="integrationadd"
      size="lg"
      :no-close-on-backdrop="true"
      :hide-footer="true"
      @hidden="clearData"
    >
      <div slot="modal-header">
        <h2>
          <span v-if="gatewayStep" @click="backToList">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              enable-background="new 0 0 24 24"
              xml:space="preserve"
            >
              <path d="M15.41,16.59L10.83,12l4.58-4.59L14,6l-6,6l6,6L15.41,16.59z" />
              <path fill="none" d="M0,0h24v24H0V0z" />
            </svg>
          </span>
          {{ formTitle }}
        </h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div v-if="serviceDataView">
        <div v-if="serviceDataView.attributes.service === 'salesforce'">
          <salesforce-view
            ref="modalViewData"
            :service="serviceDataView"
            @update-step="modalClose"
          ></salesforce-view>
        </div>
        <div v-else-if="serviceDataView.attributes.service === 'comeunity'">
          <comeunity-view
            ref="modalViewData"
            :service="serviceDataView"
            @update-step="modalClose"
          ></comeunity-view>
        </div>
        <div v-else>
          <integration-view
            ref="modalViewData"
            :service="serviceDataView"
            @update-step="modalClose"
          ></integration-view>
        </div>
      </div>

      <div v-else>
        <div v-if="!gatewayStep" class="gateways-add-select">
          <a
            v-if="showSalesforce"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('salesforce')"
          >
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/salesforce-logo.png"
              alt=""
            />
          </a>
          <a
            v-if="showSalesforce && showChabadOneSalesforce"
            href="#"
            class="gateways-add-select__item flex-column"
            @click.prevent="gatewaysHandle('salesforce-chabadone')"
          >
            <div>
              <b-img
                src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/salesforce-logo.png"
              ></b-img>
            </div>
            {{ $t("integration.with_chabad_one", "ChabadOne") }}
          </a>
          <a href="#" class="gateways-add-select__item" @click.prevent="gatewaysHandle('zapier')">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/zapier_logo_1657439051.png"
              alt=""
            />
          </a>
          <a
            v-if="showTaxGift"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('taxgift')"
          >
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/7943/taxgift_1660867465.png"
              alt=""
            />
          </a>

          <!-- hide mailchimp integration, task https://app.asana.com/0/1192055722440618/1200062103595266 -->
          <!-- <a
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('mailchimp')"
          >
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/mailchimp-logo.png"
              alt=""
            >
          </a> -->

          <a
            v-if="false"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('overpass')"
          >
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/overpass.svg"
              alt=""
            />
          </a>
          <a
            v-if="showComeunity"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('comeunity')"
          >
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/file_1662029944.png"
              alt="comeunity logo"
            />
          </a>
          <a
            v-if="showWhatsApp"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('whatsapp')"
          >
            WhatsApp
          </a>

          <a
            v-if="showJL"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('jl')"
          >
            JL
          </a>

          <a
            v-if="showDonorFuse"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('donorfuse')"
          >
            Donor Fuse
          </a>

          <a
            v-if="showDonorFuse"
            href="#"
            class="gateways-add-select__item"
            @click.prevent="gatewaysHandle('twilio')"
          >
            Twilio
          </a>
        </div>

        <salesforce
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'salesforce'"
          @create-entity="createEntity"
          @close-modal="modalClose"
        ></salesforce>
        <salesforce
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'salesforce-chabadone'"
          chabadone
          @create-entity="createEntity"
          @close-modal="modalClose"
        ></salesforce>
        <mailchimp
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'mailchimp'"
          @create-entity="createEntity"
          @close-modal="modalClose"
        ></mailchimp>
        <overpass
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'overpass'"
          @create-entity="createEntity"
          @close-modal="modalClose"
        ></overpass>
        <zapier v-if="gatewayStep === 'zapier'" @close-modal="modalClose"></zapier>
        <taxgift
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'taxgift'"
          @create-entity="createEntity"
          @close-modal="modalClose"
        ></taxgift>
        <comeunity
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'comeunity'"
          @create-entity="createEntity"
          @close-modal="modalClose"
        ></comeunity>
        <jl
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'jl'"
          @close-modal="modalClose"
        ></jl>
        <donorfuse
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'donorfuse'"
          @close-modal="modalClose"
        ></donorfuse>
        <twilio
          v-on:close-modal="modalClose()"
          v-if="gatewayStep === 'twilio'"
          @close-modal="modalClose"
        ></twilio>

        <GenerateToken v-if="isToken" :integration-name="gatewayStep" @close-modal="modalClose" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import salesforce from "./salesforce.vue";
import mailchimp from "./mailchimp.vue";
import overpass from "./overpass.vue";
import integrationView from "./integrationView.vue";
import salesforceView from "./salesforse-view.vue";
import zapier from "./zapier.vue";
import taxgift from "./taxgift.vue";
import comeunity from "./comeunity.vue";
import comeunityView from "./comeunity-view.vue";
import GenerateToken from "./generateToken.vue";
import Jl from "./jl.vue";
import Donorfuse from "./donorfuse.vue";
import Twilio from "./twilio.vue";

export default {
  components: {
    salesforce,
    mailchimp,
    integrationView,
    overpass,
    salesforceView,
    zapier,
    taxgift,
    comeunity,
    comeunityView,
    GenerateToken,
    Jl,
    Donorfuse,
    Twilio,
  },

  data() {
    return {
      gatewayStep: "",
      serviceDataView: false,
      formTitle: "Add Integration",
      editGatewaData: null,
    };
  },

  computed: {
    ...mapState({
      showSalesforce: (state) => state.featureHide.showSalesforce,
      showTaxGift: (state) => state.featureHide.showTaxGift,
      showChabadOneSalesforce: (state) => state.featureHide.showChabadOneSalesforce,
      showComeunity: (state) => state.featureHide.showComeunity,
      showWhatsApp: (state) => state.featureHide.showWhatsApp,
      showJL: (state) => state.featureHide.showJL,
      showDonorFuse: (state) => state.featureHide.showDonorFuse,
    }),

    isToken() {
      switch (this.gatewayStep) {
        case "whatsapp":
          return true;
      }
      return false;
    },
  },

  methods: {
    backToList: function () {
      this.gatewayStep = "";
      this.formTitle = this.$t("integration.add_integration");
    },

    openModal() {
      this.gatewayStep = "";
      this.formTitle = this.$t("integration.add_integration");
      this.$root.$emit("bv::show::modal", "integrationadd");
    },

    openOverpassLogin() {
      window.open(`https://app.overpass.com/auth/login`, "_blank");
      this.$root.$emit("bv::hide::modal", "integrationadd");
    },

    modalClose() {
      this.$root.$emit("bv::hide::modal", "integrationadd");
    },

    createEntity: function () {
      this.modalClose();
      this.$emit("create-entity");
    },

    clearData: function () {
      this.formTitle = "";

      if (this.serviceDataView) {
        this.serviceDataView = false;
        this.$refs.modalViewData && this.$refs.modalViewData.clearDataModa();
      }

      this.gatewayStep = "";
    },

    openModalView(integrationInfo) {
      this.formTitle = this.$t("integration.add_integration_" + integrationInfo.attributes.service);
      this.serviceDataView = integrationInfo;
      this.$root.$emit("bv::show::modal", "integrationadd");
    },

    gatewaysHandle(props) {
      this.gatewayStep = props;
      this.formTitle = this.$t("integration.add_integration_" + props, props);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.gateways-add {
  margin-left: auto;
  .modal-header {
    padding: 40px;
    position: relative;
    h2 {
      padding: 0;
      display: flex;
      align-items: center;
      position: relative;
      span {
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        position: absolute;
        top: calc(50% - 12px);
        left: -30px;
        svg {
          fill: gray;
        }
      }
    }
    &__hide {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      svg {
        fill: gray;
      }
    }
  }
  .modal-body {
    padding: 20px 10px !important;
    background-color: #f8f8f8;
    @media only screen and (min-width: 460px) {
      padding: 20px 40px !important;
    }
  }
  .modal-footer {
    display: none !important;
  }
}

.gateways-add-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__item {
    margin: 10px;
    flex-grow: 1;
    min-width: 120px;
    max-width: 170px;
    background: #fff;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(32, 67, 78, 0.15);
    @media only screen and (min-width: 460px) {
      min-width: 170px;
    }

    img {
      max-width: 80px;
      height: auto;
    }
  }
}
.ab {
  .gateway-form {
    .form-block {
      margin-bottom: 20px;
      h3 {
        //font-family: "ProximaNova-Bold", sans-serif;
        //font-weight: 600;
        font-size: 24px;
        color: #414359;
        padding: 10px 0;
      }
      label {
        margin-bottom: 10px;
        padding: 0;
        //font-family: "ProximaNova-Bold", sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        color: #414359;
        position: initial;
        visibility: visible;
        opacity: initial;
      }
      input,
      select {
        height: 50px !important;
        width: 100%;
      }
    }
    &__action {
      margin-bottom: 20px;
    }
    &__notice {
      margin-bottom: 20px;
      font-size: 16px;
      //font-family: "ProximaNova-Regular", sans-serif;
      color: #20434e;
      a {
        font-size: 16px;
        //font-family: "ProximaNova-Semibold", sans-serif;
        font-weight: 500;
        color: #cc6d4c;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
.data-view {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
  }
  &__title {
    min-width: 100%;
    padding: 10px 0;
    //font-family: "ProximaNova-Bold", sans-serif;
    font-size: 16px;
    @media only screen and (min-width: 992px) {
      min-width: 30%;
      max-width: 30%;
      margin-right: 20px;
    }
  }
  &__data {
    flex-grow: 1;
    padding: 10px 0;
    //font-family: "ProximaNova", sans-serif;
    font-size: 16px;
    word-wrap: break-word;
    max-width: 65%;
  }
}

@media (max-width: 768px) {
  .data-view__data {
    max-width: 100%;
  }
}
</style>
