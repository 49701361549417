<template>
  <div>
    <div>
      <div class="tw-min-h-[38px] tw-flex tw-justify-between tw-items-center tw-mb-[40px]">
        <h2
          class="tw-m-0 tw-font-vito-light tw-text-[20px] tw-mb-[15px] md:tw-text-[26px] md:tw-mb-0"
        >
          {{ $t("edit_campaign.generalinfo_title") }}
        </h2>
        <CampaignSetCampaignMode
          :externalLoading="load"
          :campaignMode="campaignMode"
          @update:campaignMode="reloadCampaignData"
        />
      </div>

      <div
        class="row mb-4"
        v-if="campaignRoleManagerUser.length > 0 || campaignRoleSaleUser.length > 0"
      >
        <div class="col-12 col-sm-6" v-if="campaignRoleManagerUser.length > 0">
          <div class="form-group form-unit form-unit--addon d-flex flex-column">
            <label class="form-unit__label form-unit__label--bold">
              {{
                $t("edit_campaign.generalinfo_campaign_role_manager", "Charidy campaign manager:")
              }}
            </label>
            <span v-for="(r, i) in campaignRoleManagerUser" :key="i">
              {{ r.first_name }}
              {{ r.last_name }}
              &lt;{{ r.email }}&gt;
            </span>
          </div>
        </div>
        <div class="col-12 col-sm-6" v-if="campaignRoleSaleUser.length > 0 && showSalesManager">
          <div class="form-group form-unit form-unit--addon d-flex flex-column">
            <label class="form-unit__label form-unit__label--bold">
              {{ $t("edit_campaign.generalinfo_campaign_role_sale", "Charidy sales manager:") }}
            </label>
            <span v-for="(r, i) in campaignRoleSaleUser" :key="i">
              {{ r.first_name }}
              {{ r.last_name }}
              &lt;{{ r.email }}&gt;
            </span>
          </div>
        </div>
      </div>

      <support-message-demo v-show="campaignMode === 5" />
      <intity-info-missing v-if="!noEntitiesWarning" />
      <contact-info-missing />

      <campaign-live-request />
      <ValidationObserver ref="observer" v-slot="{ invalid, validate, errors: observerErrors }">
        <form
          class="campaign-edit"
          @submit.prevent="handleSubmit(validate)"
          v-if="generalInfo.data.attributes"
        >
          <div class="error-wrapp">
            <ErrorsView
              v-if="$options.filterErrors(errors, observerErrors)"
              :errors="$options.filterErrors(errors, observerErrors)"
            />
          </div>

          <ViewByMeta
            metaFeatureName="allow_change_readonly_campaign_fields"
            :metas="metas"
            v-slot="{ meta: allowChangeReadonlyCampaignFields }"
          >
            <ViewByOrgSetting
              orgSetting="allowMatcherCoefChange"
              v-slot="{ meta: allowMatcherCoefChange }"
            >
              <CampaignGeneralFields
                :campaignTitle.sync="generalInfo.data.attributes.title"
                :campaignCurrency.sync="generalInfo.data.attributes.currency"
                :campaignPrimaryGoal.sync="generalInfo.data.attributes.primary_goal"
                :campaignPrimaryGoalMultiplier.sync="
                  generalInfo.data.attributes.primary_goal_multiplier
                "
                :campaignStartDate.sync="generalInfo.data.attributes.start_date"
                :campaignStartDateString.sync="generalInfo.data.attributes.start_date_string"
                :campaignEndDate.sync="generalInfo.data.attributes.end_date"
                :campaignEndDateString.sync="generalInfo.data.attributes.end_date_string"
                :campaignDisplayEndDate.sync="generalInfo.data.attributes.display_end_date"
                :campaignDisplayEndDateString.sync="
                  generalInfo.data.attributes.display_end_date_string
                "
                :campaignShortLink.sync="generalInfo.data.attributes.short_link"
                :readonly="readOnlyData"
                :readonlyLink="readOnlyLink"
                :readonlyField="{
                  campaignPrimaryGoal: wrapACRCF(allowChangeReadonlyCampaignFields, 'goal'),
                  campaignPrimaryGoalMultiplier: wrapAMCC(allowMatcherCoefChange),
                  campaignStartDateMemo: wrapACRCF(allowChangeReadonlyCampaignFields, 'start_date'),
                  campaignEndDateMemo: wrapACRCF(allowChangeReadonlyCampaignFields, 'end_date'),
                  campaignDisplayEndDateMemo: wrapACRCF(
                    allowChangeReadonlyCampaignFields,
                    'display_end_date'
                  ),
                }"
                :disabled="!hasAccess"
                :linkValidation="{ type: 'campaign', orgId, campaignId: campaign.id }"
                :containerClass="null"
                shortLinkClass="col-12"
              />
            </ViewByOrgSetting>
          </ViewByMeta>

          <div class="row">
            <div class="col-12 col-sm-6">
              <!-- Bonus goal -->
              <div class="form-group form-unit form-unit--addon">
                <label class="form-unit__label form-unit__label--upper-sm">{{
                  $t("edit_campaign.generalinfo_bonus_goal")
                }}</label>

                <div class="form-unit__addon-wrap">
                  <div
                    :class="
                      readOnlyData || !hasAccess
                        ? 'form-unit__addon-icon disabled'
                        : 'form-unit__addon-icon'
                    "
                  >
                    {{ generalInfo.data.attributes.currency }}
                  </div>

                  <input
                    class="form-control form-unit__input"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    :disabled="!hasAccess"
                    :min="
                      generalInfo.data.attributes.bonus_goal === 0
                        ? 0
                        : generalInfo.data.attributes.primary_goal
                    "
                    :placeholder="$t('edit_campaign.generalinfo_bonus_goal')"
                    v-model.number="generalInfo.data.attributes.bonus_goal"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6">
              <!-- Bonus goal matching, times -->
              <div class="form-group form-unit">
                <label class="form-unit__label form-unit__label--upper-sm">{{
                  $t("edit_campaign.generalinfo_bonus_round_matching")
                }}</label>

                <div
                  :class="!hasAccess ? 'matching-input matching-input--disabled' : 'matching-input'"
                >
                  <div class="matching-input__unit">
                    <input
                      :disabled="!hasAccess"
                      class="matching-input__input"
                      type="radio"
                      name=""
                      id="b1"
                      :value="1"
                      v-model.number="generalInfo.data.attributes.bonus_goal_multiplier"
                    />
                    <label class="matching-input__label" for="b1">{{
                      $t("edit_campaign.generalinfo_x1", "x1")
                    }}</label>
                  </div>

                  <div class="matching-input__unit">
                    <input
                      :disabled="!hasAccess"
                      class="matching-input__input"
                      type="radio"
                      name=""
                      id="b2"
                      :value="2"
                      v-model.number="generalInfo.data.attributes.bonus_goal_multiplier"
                    />
                    <label class="matching-input__label" for="b2">{{
                      $t("edit_campaign.generalinfo_x2", "x2")
                    }}</label>
                  </div>

                  <div class="matching-input__unit">
                    <input
                      :disabled="!hasAccess"
                      class="matching-input__input"
                      type="radio"
                      name=""
                      id="b3"
                      :value="3"
                      v-model.number="generalInfo.data.attributes.bonus_goal_multiplier"
                    />
                    <label class="matching-input__label" for="b3">{{
                      $t("edit_campaign.generalinfo_x3", "x3")
                    }}</label>
                  </div>

                  <div class="matching-input__unit">
                    <input
                      :disabled="!hasAccess"
                      class="matching-input__input"
                      type="radio"
                      name=""
                      id="b4"
                      :value="4"
                      v-model.number="generalInfo.data.attributes.bonus_goal_multiplier"
                    />
                    <label class="matching-input__label" for="b4">{{
                      $t("edit_campaign.generalinfo_x4", "x4")
                    }}</label>
                  </div>

                  <div class="matching-input__unit">
                    <input
                      :disabled="!hasAccess"
                      class="matching-input__input"
                      type="radio"
                      name=""
                      id="b5"
                      :value="5"
                      v-model.number="generalInfo.data.attributes.bonus_goal_multiplier"
                    />
                    <label class="matching-input__label" for="b5">{{
                      $t("edit_campaign.generalinfo_x5", "x5")
                    }}</label>
                  </div>

                  <div class="matching-input__unit">
                    <input
                      :disabled="!hasAccess"
                      class="matching-input__input"
                      type="radio"
                      name=""
                      id="b6"
                      :value="6"
                      v-model.number="generalInfo.data.attributes.bonus_goal_multiplier"
                    />
                    <label class="matching-input__label" for="b6">{{
                      $t("edit_campaign.generalinfo_x6", "x6")
                    }}</label>
                  </div>

                  <div class="matching-input__unit">
                    <input
                      :disabled="!hasAccess"
                      class="matching-input__input"
                      type="radio"
                      name=""
                      id="b7"
                      :value="7"
                      v-model.number="generalInfo.data.attributes.bonus_goal_multiplier"
                    />
                    <label class="matching-input__label" for="b7">{{
                      $t("edit_campaign.generalinfo_x7", "x7")
                    }}</label>
                  </div>
                </div>
              </div>
            </div>

            <CampaignMarketing
              :googleAnalyticsNumber.sync="generalInfo.data.attributes.google_analytics_number"
              :facebookPixelId.sync="generalInfo.data.attributes.facebook_pixel_id"
              :googleConversionId.sync="generalInfo.data.attributes.google_conversion_id"
              :googleConversionLabel.sync="generalInfo.data.attributes.google_conversion_label"
              :googleTagManager.sync="googleTagManager"
              :disabled="!hasAccess"
            />
          </div>
          <div class="wrapper-options" v-if="showGeneralAdvanced">
            <button
              type="button"
              @click="showOptionaly = !showOptionaly"
              class="show-hide-bttn"
              name="button"
            >
              {{
                !showOptionaly
                  ? $t("dashboard.add_donation_show_optionaly")
                  : $t("dashboard.add_donation_hide_optionaly")
              }}
              <i
                :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                aria-hidden="true"
              ></i>
            </button>
          </div>
          <div class="optionally-params" v-if="showOptionaly">
            <div class="row d-flex flex-column">
              <div class="col-6 switch-public">
                <div class="switch-public--switch">
                  <input
                    type="checkbox"
                    v-model="generalInfo.data.attributes.show_on_org_page"
                    id="show_on_org"
                  />
                  <label for="show_on_org"></label>
                </div>
                <label class="form-unit__label--upper-sm">
                  {{ $t("edit_campaign.show_on_org") }}
                </label>
              </div>

              <!-- hide the feature from the dashboard -->
              <div class="col-12 general-info-giving-day">
                <a :href="hasAccess ? '#' : ''" v-if="!joinGdModalStatus" @click="openjoinGdModal">
                  {{ $t("edit_campaign.open_join_gd_modal") }}
                </a>

                <div v-else>
                  <p v-if="selectedGd" class="form-unit__label form-unit__label--upper-sm">
                    {{
                      $t(`edit_campaign.join_gd_label_${joinGdModalStatus}`, {
                        campaign: selectedGd.name,
                      })
                    }}
                  </p>
                  <a href="#" @click="deleteParentCampaign">{{
                    $t("edit_campaign.delete_parent_campaign")
                  }}</a>
                </div>

                <join-gd-modal
                  v-if="hasAccess"
                  ref="modalJoinGd"
                  :multiselectdata="selectedGd"
                  @join-gd="getParentGd"
                  :gdarr="gdArray"
                  :campaignid="campaign.id"
                  :selectedid="gdSelectedId"
                >
                </join-gd-modal>
              </div>

              <CustomProgressBarMessage
                :campaign-id="campaign.id"
                :org-id="orgId"
                :has-access="hasAccess"
                class="col-12"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right submit-button">
              <b-overlay
                :show="load"
                rounded="pill"
                opacity="0.6"
                spinner-small
                spinner-variant="secondary"
                class="d-inline-block"
              >
                <button
                  type="submit"
                  class="bttn bttn--lg bttn--orange bttn--mw255"
                  :disabled="load || !hasAccess || invalid"
                >
                  {{ $t("edit_campaign.generalinfo_save") }}
                </button>
              </b-overlay>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { ValidationObserver } from "vee-validate";
import urls, { appHost } from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";
import joinGdModal from "./joinGdModal";
import contactInfoMissing from "@/components/contact-info-missing";
import intityInfoMissing from "@/components/intity-info-missing";
import supportMessageDemo from "@/components/support-message-demo.vue";
import campaignLiveRequest from "./campaignLiveRequest.vue";
import CampaignMarketing from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignMarketing.vue";
import CampaignGeneralFields from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignGeneralFields.vue";
import ViewByOrgSetting from "@/views/campaigns/campaign-wizards/viewByOrgSetting.vue";
import ViewByMeta from "@/views/campaigns/campaign-wizards/viewByMeta.vue";
import ErrorsView from "@/components/errors.vue";
import { filterErrors } from "@/helpers/filters";
import { getGoogleTagManager, updateGoogleTagManager } from "@/helpers/google-tag-manager-meta";
import CustomProgressBarMessage from "./customProgressBarMessage.vue";
import CampaignSetCampaignMode from "./campaignSetCampaignMode.vue";

export default {
  filterErrors,

  data() {
    return {
      appHost,
      generalInfo: {
        data: {
          attributes: null,
        },
      },
      googleTagManager: {
        id: "",
        inject_as_text: false,
        __data: null,
      },
      showOptionaly: false,
      errors: [],
      load: false,
      gdSelectedId: 0,
      joinGdModalStatus: false,
      gdArray: [],
      selectedGd: [],
    };
  },
  mixins: [notificationsMixin],
  components: {
    ErrorsView,
    joinGdModal,
    contactInfoMissing,
    intityInfoMissing,
    campaignLiveRequest,
    ValidationObserver,
    supportMessageDemo,
    CampaignMarketing,
    CampaignGeneralFields,
    ViewByOrgSetting,
    ViewByMeta,
    CustomProgressBarMessage,
    CampaignSetCampaignMode,
  },

  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditCampaignContent;
      },

      campaign: (state) => {
        return state.campaign.campaign.data;
      },

      orgId: function () {
        return localStorage.getItem("orgId");
      },

      campaignMode: (state) => {
        return state.campaign.campaign.data.attributes.mode;
      },

      readOnlyData() {
        if (this.campaignMode >= 2 && this.campaignMode <= 4) {
          return true;
        }

        return false;
      },

      readOnlyLink() {
        return this.campaignMode === 2 || this.campaignMode === 5;
      },

      timeZone: (state) => {
        return state.account.account.data.attributes.timezone;
      },

      hasEntities: (state) => state.account.entities,
      currencies: (state) => state.account.currencyList,
      locale: (state) => state.i18n.locale,
      roles: (state) => state.campaign.roles,
      showSalesManager: (state) => state.featureHide.showSalesManager,
      metas: (state) => state.campaign.metas,
      showGeneralAdvanced: (state) => state.featureHide.showGeneralAdvanced,
    }),

    noEntitiesWarning() {
      return this.hasEntities.length > 0;
    },

    campaignRoleManagerUser() {
      return this.roles
        .filter((r) => r.role === "manager")
        .map((r) => r?.user)
        .filter((r) => Boolean(r));
    },

    campaignRoleSaleUser() {
      return this.roles
        .filter((r) => r.role === "sale")
        .map((r) => r?.user)
        .filter((r) => Boolean(r));
    },
  },

  watch: {
    campaign: function () {
      this.setCampaignData();
    },

    gdArray: function () {
      this.selectdetGd(this.gdSelectedId, this.gdArray);
    },

    gdSelectedId: function () {
      this.selectdetGd(this.gdSelectedId, this.gdArray);
    },
  },

  methods: {
    ...mapActions({
      getEntities: "getEntities",
    }),
    openjoinGdModal: function (e) {
      e.preventDefault();

      if (this.hasAccess) {
        this.$refs.modalJoinGd.openModal();
      }
    },

    async wrapGetGoogleTagManager() {
      return getGoogleTagManager({
        cid: this.campaign.id,
        oid: this.orgId,
      })
        .then((gtm) => (this.googleTagManager = gtm))
        .catch(this.$_notificationsMixin_handleCatch);
    },
    async wrapUpdateGoogleTagManager() {
      return updateGoogleTagManager(
        {
          cid: this.campaign.id,
          oid: this.orgId,
        },
        this.googleTagManager
      )
        .then((gtm) => (this.googleTagManager = gtm))
        .catch(this.$_notificationsMixin_handleCatch);
    },

    getGivenDays: function () {
      if (!this.hasAccess) {
        return 0;
      }

      axios
        .get(urls.getGd + `?category=givingday&start_date=future`)
        .then((response) => {
          for (var i = 0; i < response.data.data.length; i++) {
            let data = {
              name: response.data.data[i].attributes.name,
              id: response.data.data[i].id,
            };
            this.gdArray.push(data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    selectdetGd: function (gdId, gdArr) {
      if (gdId && gdArr.length > 0) {
        let data = gdArr.filter((el) => {
          return el.id == gdId;
        })[0];

        this.selectedGd = data;
      } else {
        this.selectedGd = null;
      }
    },

    deleteParentCampaign: function (e) {
      e.preventDefault();

      let message = this.$t("edit_campaign.confirm_delete_parent_campaign");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        axios
          .delete(
            urls.joinGd
              .replace(":orgId", localStorage.getItem("orgId"))
              .replace(":campaignId", this.campaign.id)
          )
          .then(() => {
            this.$_notificationsMixin_makeToast(
              "Success",
              this.$t("edit_campaign.success_delete_parent_campaign")
            );

            this.getParentGd();
          })
          .catch(() => {
            this.getParentGd();
          });
      });
    },

    getParentGd: function () {
      if (!this.hasAccess) {
        return 0;
      }

      axios
        .get(
          urls.joinGd
            .replace(":orgId", localStorage.getItem("orgId"))
            .replace(":campaignId", this.campaign.id)
        )
        .then((response) => {
          this.joinGdModalStatus = response.data.data.attributes.status;
          this.gdSelectedId = parseInt(response.data.data.attributes.givingday_campaign_id);
        })
        .catch(() => {
          this.joinGdModalStatus = false;
        });
    },

    updateCurrency(val) {
      this.generalInfo.data.attributes.currency = val.toLowerCase();
    },

    setCampaignData: function () {
      let campaignData = JSON.parse(JSON.stringify(this.campaign));

      if (campaignData.attributes.rounds.length < 2) {
        let bonusRoundObj = {
          goal: 0,
          multiplier: 0,
          round: 1,
        };

        campaignData.attributes.rounds.push(bonusRoundObj);
      }

      if (typeof campaignData.attributes.end_date == "number") {
        campaignData.attributes.end_date_string = new Date(
          campaignData.attributes.end_date * 1000
        ).toISOString();
      }

      if (typeof campaignData.attributes.display_end_date == "number") {
        campaignData.attributes.display_end_date_string = new Date(
          campaignData.attributes.display_end_date * 1000
        ).toISOString();
      }

      if (typeof campaignData.attributes.start_date == "number") {
        campaignData.attributes.start_date_string = new Date(
          campaignData.attributes.start_date * 1000
        ).toISOString();
      }

      this.generalInfo.data.attributes = {
        title: campaignData.attributes.title,
        currency: campaignData.attributes.currency,
        start_date_string: campaignData.attributes.start_date_string,
        end_date_string: campaignData.attributes.end_date_string,
        display_end_date_string: campaignData.attributes.display_end_date_string,
        primary_goal: campaignData.attributes.rounds[0].goal,
        primary_goal_multiplier: campaignData.attributes.rounds[0].multiplier,
        bonus_goal: campaignData.attributes.rounds[1].goal,
        bonus_goal_multiplier: campaignData.attributes.rounds[1].multiplier,
        short_link: campaignData.attributes.short_link,
        facebook_pixel_id: campaignData.attributes.facebook_pixel_id,
        google_analytics_number: campaignData.attributes.google_analytics_number,
        google_conversion_id: campaignData.attributes.google_conversion_id,
        google_conversion_label: campaignData.attributes.google_conversion_label,
        show_on_org_page: campaignData.attributes.show_on_org_page,
      };

      this.load = false;
    },

    handleSubmit(validate) {
      validate().then(() => this.submitGeneralInfo());
    },

    reloadCampaignData() {
      this.$store.dispatch(
        "updateCampaign",
        "?extend=campaign_stats&extend=content&extend=media&extend=matchers"
      );
      this.setCampaignData();
      this.wrapUpdateGoogleTagManager();
    },

    submitGeneralInfo() {
      const attrs = this.generalInfo.data.attributes;
      attrs.bonus_goal = Number(attrs.bonus_goal) || 0;
      attrs.end_date = new Date(attrs.end_date_string).getTime() / 1000;
      attrs.display_end_date = new Date(attrs.display_end_date_string).getTime() / 1000;
      attrs.start_date = new Date(attrs.start_date_string).getTime() / 1000;

      this.load = true;
      this.errors = [];

      this.generalInfo.data.attributes = { ...attrs };
      return axios
        .put(
          urls.getCampaignData
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaign.id),
          this.generalInfo
        )
        .then(() => {
          this.reloadCampaignData();
          this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        })
        .finally(() => {
          this.load = false;
        });
    },

    wrapACRCF(meta, field) {
      if (!this.readOnlyData) {
        return true;
      }

      return meta.value && meta.fields[field];
    },

    wrapAMCC(meta) {
      if (!this.readOnlyData) {
        return true;
      }

      return this.campaignMode === 2 && meta.value;
    },
  },

  created: function () {
    this.errors = [];
    this.setCampaignData();
    this.getParentGd();
    this.getGivenDays();
    this.getEntities();
    this.wrapGetGoogleTagManager();
  },
};
</script>

<style lang="scss">
@import "../../../../styles/_vars.scss";
.goal-input {
  .dropdown {
    display: flex;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #e6eaed !important;
      border-bottom: 1px solid #e6eaed !important;
      border-left: 1px solid #e6eaed !important;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      min-width: 65px;
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      text-transform: uppercase;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
}

.date-time-select {
  .form-unit__addon-icon {
    display: none;
    @media only screen and (min-width: 992px) {
      display: flex;
    }
  }
  input.form-unit__input {
    @media only screen and (max-width: 991px) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
.form-unit--addon {
  .help-tooltip {
    margin-bottom: -3px;
  }
}
.form-unit__addon-wrap {
  .link-error,
  .link-success {
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .link-error {
    color: #f26e66;
  }
  .link-success {
    color: #3cc7a0;
  }
  .link-validate {
    color: $fd;
    display: inline-flex;
    align-items: center;
    height: 44px;
    position: absolute;
    top: 3px;
    right: 5px;
    > img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}

.time-zone-info {
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 16px;
  color: $fd;
  .change-time-zone-button {
    color: $bb-100;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $bb-80;
      //text-decoration: none;
    }
  }
}
.load {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background-color: white;
  img {
    width: 200px;
    margin: 0 auto;
  }
}

.submit-button {
  padding-top: 20px;
}

.switch-public {
  display: flex;
  gap: 1rem;
  align-items: center;
  > label {
    margin: 0;
  }
  &--switch {
    input {
      display: block;
      opacity: 0;
    }
    label {
      width: 60px;
      height: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: rgb(189, 189, 189);
      border-radius: 30px;
      transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
    }
    label:after {
      left: 0;
      width: 20px;
      height: 20px;
      margin: 5px;
      content: "";
      position: absolute;
      background: #fff;
      border-radius: 10px;
    }
    input:checked + label {
      background: #3cc7a0;
    }
    input:checked + label:after {
      left: auto;
      right: 0;
    }
  }
}

.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eaf4ff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .show-hide-bttn {
    width: auto;
    position: relative;
    &:focus {
      outline: 0;
    }
  }
}

.general-info-giving-day {
  margin-bottom: 20px;
}
</style>

<style lang="scss" scoped>
.vdatetime {
  flex-grow: 1;
}
::v-deep .ql-snow .ql-tooltip {
  left: 0 !important;
}
</style>
