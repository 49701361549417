<template>
  <div class="stripe-gateways" :class="{}">
    <div class="notice notice--loading" v-if="loadingStripeOnBoarding">
      <div class="notice__warning d-flex align-items-center justify-content-center">
        <b-spinner />
      </div>
      <div class="notice__content w-100">
        <p class="notice__title tw-mb-1.5">
          {{
            $t(
              "gateways.stripe_gateways_links_title_v2_loading",
              "Stripe verification status loading..."
            )
          }}
        </p>
        <p class="notice__text tw-mb-1.5">
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </p>
      </div>
      <b-skeleton type="button" />
    </div>
    <template v-else-if="!loadingStripeOnBoarding && stripeGatewaysPresent">
      <div class="notice notice--verified" v-if="this.everyGatewayVerifiedAndNoErrors">
        <div class="notice__warning d-flex align-items-center justify-content-center">
          <b-icon-check class="tw-w-[63px] tw-h-[63px]" />
        </div>
        <div class="notice__content">
          <p class="notice__title tw-mb-1.5">
            {{
              $t(
                "gateways.stripe_gateways_links_title_v2_verified",
                "Your Stripe accounts have been verified"
              )
            }}
          </p>
        </div>
      </div>
      <div class="notice notice--verified" v-else-if="this.someGatewayVerifiedAndNoErrors">
        <div class="notice__warning d-flex align-items-center justify-content-center">
          <b-icon-check class="tw-w-[63px] tw-h-[63px]" />
        </div>
        <div class="notice__content">
          <p class="notice__title tw-mb-1.5">
            {{
              $t(
                "gateways.stripe_gateways_links_title_v2_some_verified",
                "One Stripe account has been verified ::: Multiple Stripe accounts ({count}) have been verified",
                {
                  count: partiallyVerified.length,
                },
                partiallyVerified.length
              )
            }}
          </p>
        </div>
      </div>
      <div
        class="notice"
        v-for="(gl, noticeIndex) in gatewayLinksFiltered"
        :key="`notice-warning-${noticeIndex}`"
      >
        <div class="notice__warning d-flex align-items-center justify-content-center">
          <component :is="noticeIconComponent(gl)" class="tw-w-[63px] tw-h-[63px]" />
        </div>
        <div class="notice__content">
          <p class="notice__title tw-mb-1.5">
            {{ noticeTitle(gl) }}
          </p>

          <p class="tw-mb-1.5" v-html="noticeSubTitle(gl)" />

          <p
            v-if="getSanitizedNoticeTkey(gl)"
            class="notice__text tw-mb-1.5"
            v-html="getSanitizedNoticeTkey(gl)"
          />

          <p class="notice__text tw-mb-1.5" v-html="getPaymentStatus(gl)"></p>

          <div class="tw-my-1.5">
            <b-card
              v-if="getFutureRequirementFields(gl).length > 0"
              no-body
              class="!tw-rounded-b-none"
            >
              <b-card-header role="tab" header-class="!tw-border-none !tw-p-0.5">
                <b-button
                  variant="link"
                  @click="
                    () =>
                      $root.$emit(
                        'bv::toggle::collapse',
                        `notice-warning-${noticeIndex}-future-requirements-fields`
                      )
                  "
                >
                  {{
                    $t(
                      "gateways.stripe_gateways_links_view_future_requirements",
                      "View future requirements"
                    )
                  }}
                </b-button>
              </b-card-header>
              <b-collapse
                :id="`notice-warning-${noticeIndex}-future-requirements-fields`"
                :accordion="`notice-warning-${noticeIndex}-future-requirements-fields-accordion`"
                role="tabpanel"
              >
                <b-list-group flush>
                  <b-list-group-item
                    v-for="(f, i) in getFutureRequirementFields(gl)"
                    :key="`future-requirement-field-${i}`"
                    class="!tw-flex !tw-justify-between !tw-items-center"
                  >
                    <div class="tw-flex tw-flex-col">
                      {{ $t(`gateways.stripe_future_requirement_field_${f.field}`, f.field) }}
                      <small v-if="f.code">
                        {{ f.code }}
                      </small>
                    </div>
                    <b-badge :variant="f.code ? 'danger' : 'warning'" pill>
                      {{ $t(`gateways.stripe_future_requirement_reason_${f.reason}`, f.reason) }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-collapse>
            </b-card>
            <b-card
              v-if="getRequirementFields(gl).length > 0"
              no-body
              class="!tw-rounded-t-none !tw-border-t-0"
            >
              <b-card-header role="tab" header-class="!tw-border-none !tw-p-0.5">
                <b-button
                  variant="link"
                  @click="
                    () =>
                      $root.$emit(
                        'bv::toggle::collapse',
                        `notice-warning-${noticeIndex}-requirements-fields`
                      )
                  "
                >
                  {{ $t("gateways.stripe_gateways_links_view_requirements", "View requirements") }}
                </b-button>
              </b-card-header>
              <b-collapse
                :id="`notice-warning-${noticeIndex}-requirements-fields`"
                :accordion="`notice-warning-${noticeIndex}-requirements-fields-accordion`"
                role="tabpanel"
              >
                <b-list-group flush>
                  <b-list-group-item
                    v-for="(f, i) in getRequirementFields(gl)"
                    :key="`requirement-field-${i}`"
                    class="!tw-flex !tw-justify-between !tw-items-center"
                  >
                    <div class="tw-flex tw-flex-col">
                      {{ $t(`gateways.stripe_requirement_field_${f.field}`, f.field) }}
                      <small v-if="f.code">
                        {{ f.code }}
                      </small>
                    </div>
                    <b-badge :variant="f.code ? 'danger' : 'warning'" pill>
                      {{ $t(`gateways.stripe_requirement_reason_${f.reason}`, f.reason) }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-collapse>
            </b-card>
          </div>
        </div>
        <a v-if="!buttonVerified(gl)" class="bttn-bordered bttn-bordered--sm" :href="gl.link">
          {{ $t("gateways.stripe_gateways_linkbtn_v2", "Verify Stripe Account") }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * @typedef {Object} RequirementField
 * @property {"past_due" | "currently_due" | "eventually_due"} reason - The reason for the error.
 * @property {string} code - The error code.
 * @property {string} field - The field associated with the error.
 */

import axios from "axios";
import urls from "@/constants/urls.js";
import { mapActions } from "vuex";
import r from "editorjs-html";

export default {
  data() {
    return {
      gatewayLinks: [],
      stripeGatewaysPresent: false,
      loadingStripeOnBoarding: false,
    };
  },
  mounted() {
    this.loadingStripeOnBoarding = true;
    this.getGatewaysAndReturn()
      .then(async (gateways) => {
        const gatewayLinks = await this.loadGatewayLinks(gateways);
        return { gatewayLinks, gateways };
      })
      .then(({ gatewayLinks, gateways }) => {
        this.gatewayLinks = gatewayLinks;
        this.stripeGatewaysPresent =
          gateways.some((gateway) => gateway.attributes.gateway === "stripe") &&
          gatewayLinks.length > 0;
      })
      .finally(() => {
        this.loadingStripeOnBoarding = false;
      });
  },
  computed: {
    everyGatewayVerifiedAndNoErrors() {
      return this.gatewayLinks.every(
        (v) => !v.required && !v.error && !v.pending && !v.futureRequirementsDedline
      );
    },
    someGatewayVerifiedAndNoErrors() {
      return this.gatewayLinks.some(
        (v) => !v.required && !v.error && !v.pending && !v.futureRequirementsDedline
      );
    },
    partiallyVerified() {
      return this.gatewayLinks.filter(
        (v) => !v.required && !v.error && !v.pending && !v.futureRequirementsDedline
      );
    },
    gatewayLinksFiltered() {
      return this.gatewayLinks.filter(
        (gl) => gl.required || gl.error || gl.futureRequirementsDedline || gl.pending
      );
    },
  },
  watch: {
    gateways() {
      this.loadGatewayLinks();
    },
  },
  methods: {
    ...mapActions({
      getGatewaysAndReturn: "getGatewaysAndReturn",
    }),
    async loadGatewayLinks(gateways) {
      let gatewayLinks = [];

      const promiseResults = await Promise.allSettled(
        gateways
          .filter((g) => g.attributes.active && g.attributes.gateway === "stripe")
          .map((g) => this.getStripeOnBoarding(g))
      );
      gatewayLinks = promiseResults.map((r) => {
        const { data, gatewayData, error } = r.value;
        const {
          required,
          pending,
          link,
          future_requirements_dedline,
          requirement_fields,
          future_requirement_fields,
          payouts_enabled,
          charges_enabled,
          future_requirements_errors,
          requirement_errors,
        } = data?.data?.attributes || {};

        return {
          futureRequirementsDedline: future_requirements_dedline,
          required,
          pending,
          link,
          gatewayData,
          error,
          requirementFields: requirement_fields,
          futureRequirementFields: future_requirement_fields,
          payoutsEnabled: payouts_enabled,
          chargesEnabled: charges_enabled,
          futureRequirementsErrors: future_requirements_errors || [],
          requirementErrors: requirement_errors || [],
        };
      });

      return gatewayLinks;
    },
    buttonVerified(gl) {
      return gl.chargesEnabled && gl.payoutsEnabled && !gl.pending && !gl.required && !gl.error;
    },
    async getStripeOnBoarding(gateway) {
      const orgId = localStorage.getItem("orgId");
      try {
        const { data } = await axios.get(
          urls.stripeOnBoarding.replace(":oid", orgId).replace(":idGateway", gateway.id)
        );

        return { data, gatewayData: gateway.attributes.gateway_data };
      } catch (e) {
        return {
          data: {},
          gatewayData: gateway.attributes.gateway_data,
          error: e?.response?.data?.error || e?.message || e,
        };
      }
    },
    noticeTitle(gl) {
      if (gl?.error) {
        return this.$t(
          "gateways.stripe_gateways_links_title_v2_error",
          "We encountered some service error"
        );
      }

      if (gl.required) {
        return this.$t("gateways.stripe_gateways_links_title_v2", "Stripe verification required");
      }

      if (gl.pending) {
        return this.$t(
          "gateways.stripe_gateways_links_title_v3_pending",
          "Stripe verification is in review"
        );
      }

      return this.$t(
        "gateways.stripe_gateways_links_title_v2_one_verified",
        "Your Stripe account ({accountNumber}) has been verified",
        {
          accountNumber: gl.gatewayData.account_number,
        }
      );
    },
    noticeSubTitle(gl) {
      const data = gl.gatewayData;
      return `<strong class='notice__title'>${data.legal_name}</strong> (${data.country} - ${data.account_number})`;
    },
    /**
     * @param {Array<RequirementField>} list - The combined list of requirement fields and requirement errors.
     */
    filterRequirementFields(list) {
      // Filter out duplicates and prioritize past_due > currently_due > eventually_due
      let uniqueFields = {};
      list.forEach((field) => {
        let priority = field.reason === "past_due" ? 1 : field.reason === "currently_due" ? 2 : 3;
        if (!uniqueFields[field.field] || uniqueFields[field.field].priority > priority) {
          uniqueFields[field.field] = {
            ...field,
            priority,
          };
        }
      });

      list = Object.values(uniqueFields);
      return list;
    },
    getRequirementFields(gl) {
      let list = [...(gl.requirementFields || []), ...(gl.requirementErrors || [])];
      return this.filterRequirementFields(list);
    },
    getFutureRequirementFields(gl) {
      let list = [...(gl.futureRequirementFields || []), ...(gl.futureRequirementsErrors || [])];
      return this.filterRequirementFields(list);
    },
    getPaymentStatus(gl) {
      const enabledText = this.$t("gateways.stripe_payment_status_on", "Enabled");
      const disabledText = `<span style="color: red;">${this.$t(
        "gateways.stripe_payment_status_off",
        "Disabled"
      )}</span>`;

      return this.$t(
        "gateways.stripe_gateways_links_payment_status",
        "Current Status: Charges <strong class='notice__title'>{chargesSataus}</strong>, Payouts <strong class='notice__title'>{payoutsSataus}</strong>",
        {
          chargesSataus: gl.chargesEnabled ? enabledText : disabledText,
          payoutsSataus: gl.payoutsEnabled ? enabledText : disabledText,
        }
      );
    },
    noticeIconComponent(gl) {
      if (gl?.error) {
        return "b-icon-x";
      }

      if (!gl.required) {
        return "b-icon-check";
      }

      return "b-icon-exclamation-triangle";
    },
    getSanitizedNoticeTkey(gl) {
      if (gl?.error) {
        return this.$t(
          "gateways.stripe_gateways_links_note_v2_error",
          "The Stripe account for <strong class='notice__title'>{legalName} ({country} {accountNumber})</strong> had error: {error}",
          {
            legalName: gl.gatewayData.legal_name,
            country: gl.gatewayData.country,
            accountNumber: gl.gatewayData.account_number,
            error: gl.error,
          }
        );
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.stripe-gateways {
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fef5f5;
  border: 1px solid #e4e8ec;
  border-radius: 8px;
}
.notice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  @media only screen and (min-width: 468px) {
    flex-direction: row;
    align-items: center;
    gap: initial;
  }
  &--loading {
    background-color: #f9f0ce;
  }

  &--verified {
    background-color: #f5fef8;
  }
  &__warning {
    margin-bottom: 15px;
    width: 63px;
    min-width: 63px;
    height: auto;
    flex-shrink: 0;
    align-self: center;
    @media only screen and (min-width: 468px) {
      margin-right: 30px;
      margin-bottom: initial;
    }
  }
  &__content {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
  }
  ::v-deep &__title {
    font-family: $vito-bold;
    font-size: 16px;
    color: $fd;
    line-height: 1.125;
  }
  &__text {
    font-family: $vito-light;
    font-size: 14px;
    line-height: 1.125;
    color: $fd;
  }
  .entity-modal {
    margin-left: auto;
  }

  ::v-deep .bttn-bordered {
    border-color: $lr-100;
    color: #ffffff;
    background-color: $lr-100;
    font-family: $vito-bold;
    &--sm {
      min-width: 180px;
    }
  }
}
</style>
